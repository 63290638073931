import PanelPresentacionPagina from '../site/PanelPresentacionPagina';

/**
 * AdminHome Component
 */
function AdminHome() {
  return <PanelPresentacionPagina muestra={{ titulo: '' }} />;
}

export default AdminHome;
