import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useIdle } from '@uidotdev/usehooks';

/**
 * UsuarioSesion Component
 */
function UsuarioSesion() {
  const location = useLocation();
  const backend = useSelector((state) => state.env.backend);
  const idle = useIdle(30000); // idle true luego de 30s de inactividad
  const urlApiIp = 'https://api.ipify.org?format=json';

  const getNavegador = () => {
    let userAgent = navigator.userAgent;
    let browserName;

    if (userAgent.match(/edg/i)) {
      browserName = 'E';
    } else if (userAgent.match(/chrome|chromium|crios/i)) {
      browserName = 'C';
    } else if (userAgent.match(/firefox|fxios/i)) {
      browserName = 'F';
    } else if (userAgent.match(/safari/i)) {
      browserName = 'S';
    } else {
      browserName = 'D';
    }
    return browserName;
  };

  const getTipoDispositivo = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'T';
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'M';
    }
    return 'D';
  };

  const enviarDatosSesion = async (data) => {
    try {
      let response = await axios.post(`${backend}/api/sesiones`, data);
      let res = response.data;
      if (res.ok) {
        if (!localStorage.getItem('idUsuarioSesion'))
          localStorage.setItem('idUsuarioSesion', res.idUsuarioSesion);
      } else {
        console.error(res.error || 'Error al Error al procesar datos.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // solo query param page
  const getUrlSecundaria = () => {
    let usp = new URLSearchParams(location.search);
    if (usp.get('page')) {
      return '?page=' + usp.get('page');
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (
      document.visibilityState == 'visible' &&
      !idle &&
      location.pathname?.substring(0, 5) == '/site'
    ) {
      fetch(urlApiIp)
        .then((response) => response.json())
        .then((data) => {
          enviarDatosSesion({
            ip: data?.ip,
            navegador: getNavegador(),
            dispositivo: getTipoDispositivo(),
            urlPrincipal: location.pathname,
            urlSecundaria: getUrlSecundaria(),
            idUsuarioSesion: localStorage.getItem('idUsuarioSesion'),
          });
        })
        .catch((error) => console.error(error));
    }

    const intervalId = setInterval(() => {
      if (
        document.visibilityState == 'visible' &&
        !idle &&
        location.pathname?.substring(0, 5) == '/site'
      ) {
        fetch(urlApiIp)
          .then((response) => response.json())
          .then((data) => {
            enviarDatosSesion({
              ip: data?.ip,
              navegador: getNavegador(),
              dispositivo: getTipoDispositivo(),
              urlPrincipal: location.pathname,
              urlSecundaria: getUrlSecundaria(),
              idUsuarioSesion: localStorage.getItem('idUsuarioSesion'),
            });
          })
          .catch((error) => console.error(error));
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [location.pathname + location.search]);

  // useEffect(() => {
  //   if (idle) {
  //     console.log('usuario inactivo');
  //   } else {
  //     console.log('usuario activo');
  //   }
  // }, [idle]);

  return <></>;
}

export default UsuarioSesion;
