import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { toast } from 'react-toastify';

/**
 * FormReclamos Component
 */
function FormReclamos() {
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const dispatch = useDispatch();

  const formInitialState = {
    urlCV: '',
    file: '',
  };

  const [form, setForm] = useState(formInitialState);
  const [file, setFile] = useState('');
  const [errores, setErrores] = useState({});
  const [extensionTerminosCondiciones, setExtensionTerminosCondiciones] = useState({});

  const validarTerminosCondiciones = () => {
    let enviar = true;
    setErrores({});

    if (!file) {
      setErrores((e) => ({ ...e, file: 'Adjunte un archivo pdf.' }));
      enviar = false;
    } else if (extensionTerminosCondiciones != 'pdf') {
      setErrores((e) => ({
        ...e,
        file: 'El formato del archivo debe ser "pdf".',
      }));
      enviar = false;
    }

    if (enviar) guardarTerminosCondiciones();
  };

  const guardarTerminosCondiciones = () => {
    dispatch(iniLoading());

    let formData = new FormData();
    formData.append('archivo', file);

    axios
      .post(`${backend}/api/terminos_condiciones`, formData)
      .then((response) => {
        let res = response.data;
        if (res.ok) {
          toast.success('Guardado!');
          setForm({ ...formInitialState, url: res.url });
          setFile('');
        } else {
          toast.error(res.error || 'Error al guardar.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  const getTerminosCondiciones = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/terminos_condiciones`);
      let res = response.data;
      setForm({ ...form, url: res.contenido });
    } catch (error) {
      console.error(error);
      toast.error('Error al cargar terminos y condiciones.');
    }
    dispatch(finLoading());
  };

  // use effect get formulario terminos condiciones
  useEffect(() => {
    if (authData && authData.user.rol == 'admin') getTerminosCondiciones();
  }, []);

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="modal">
        <div className="modal-header">
          <h1>Términos y Condiciones</h1>
        </div>

        <div className="modal-body">
          <div className="form-group">
            <label htmlFor="cv">Subir términos y condiciones</label>
            <input
              id="cv"
              type="file"
              className={`form-control form-control-100 ${errores.file ? 'is-invalid' : ''}`}
              value={form.file}
              onChange={(e) => {
                let auxExtension = e.target.files[0].name.split('.');
                setExtensionTerminosCondiciones(auxExtension[auxExtension.length - 1]);
                setFile(e.target.files[0]);
                setForm({ ...form, file: e.target.value });
              }}
              autoComplete="off"
            />
            <span className="text-danger">{errores.file}</span>
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => validarTerminosCondiciones()}
          >
            Guardar
          </button>
          <a
            className="btn btn-primary ml-3"
            href={`${backend}/api/archivo?url=${form.url}`}
            target="_blank"
          >
            Ver Términos y condiciones
          </a>
        </div>
      </div>
    )
  );
}

export default FormReclamos;
