import React, { useEffect, useState } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

import DataTable, { createTheme } from 'react-data-table-component';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { finLoading, iniLoading } from '../store/loadingSlice';
import { inMemoryFileDownload } from './Util';

/**
 * Componente data table reusable para el sistema
 * Utilizamos el paquete react-data-table-component (pasar prop columns)
 * Acepta un prop objeto filtros opcional
 *  ejemplo {search: 'asdf', idTipoEmpresa: '4'}
 *  setear/cambiar el objeto/prop <filtros> recarga datatable a la pagina uno ok
 * Si este componente tiene algun bug avise al autor
 * @author Jose Palacios
 */
function DataTableBase({
  url,
  filtros,
  columns, // props extra: soloExport, textRight, exportColumnWidth
  exportar, // export
  chunkLen, // export
  horizontal, // export
  exportTableLayoutFixed, // export
  fnFiltrosPrint, // export
  titulo, // export
  dataSelect, // export, opcional, lista para exportar
  exportarTodasColumnas = true, // para exportar todas las columnas recibidas
  ...rest
}) {
  const dispatch = useDispatch();
  const darkMode = false; // tema
  const backend = useSelector((state) => state.env.backend);
  const loadingCount = useSelector((state) => state.loading.count);

  // createTheme creates a new theme named solarized que se basa en el dark theme nativo
  createTheme(
    'muiDark',
    {
      background: {
        default: '#202940',
      },
      divider: {
        default: '#737a88',
      },
    },
    'dark'
  );

  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const [curPage, setCurPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(null);

  // toggles auxiliares
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [sortToggle, setSortToggle] = useState(false);
  const [perRowsToggle, setPerRowsToggle] = useState(null);

  // calcular fetch url
  const fetchUrl = (fetchPerPage, page, fetchSortField, fetchSortDir) => {
    let usp = new URLSearchParams();
    usp.set('page', page);
    usp.set('length', fetchPerPage);
    if (typeof filtros === 'object') {
      Object.keys(filtros).forEach((key) => {
        const value = filtros[key];
        if (value || value === 0) {
          usp.set(key, value);
        }
      });
    }
    if (fetchSortField) {
      usp.set('column', fetchSortField);
      if (fetchSortDir) {
        usp.set('dir', fetchSortDir);
      }
    }
    let res = `${url}?${usp.toString()}`;
    // console.log("fetchUrl ", res);
    return res;
  };

  // fetch data de una pagina
  const fetchData = async (page) => {
    dispatch(iniLoading());
    try {
      let urlFetch = fetchUrl(perPage, page, sortField, sortDir);
      let res = await axios.get(`${backend}${urlFetch}`);
      setData(res.data.data);
      setTotalRows(res.data.total);
    } catch (error) {
      toast.error('Error obteniendo el listado.');
    }
    dispatch(finLoading());
  };

  // handle page change
  const handlePageChange = (page) => {
    // console.log("handlePageChange");
    fetchData(page);
    setCurPage(page);
  };

  // handle per rows change
  const handlePerRowsChange = async (newPerPage, page) => {
    // console.log("handlePerRowsChange");
    setPerPage(newPerPage);
    setCurPage(page);
    setPerRowsToggle(!perRowsToggle);
  };

  // handle sort
  const handleSort = async (column, sortDirSet) => {
    // console.log("handleSort");
    setSortField(column.sortField);
    setSortDir(sortDirSet);
    setSortToggle(!sortToggle);
  };

  // listar pagina 1 al cargar el componente
  // listar pagina 1 al cambiar los filtros
  // listar pagina 1 al aplicar sort
  useEffect(() => {
    if (curPage == 1) {
      fetchData(1);
    } else {
      // will trigger onChangePage 1
      setResetPaginationToggle(!resetPaginationToggle);
    }
  }, [filtros, sortToggle]);

  // listar pagina actual al cambiar per rows
  useEffect(() => {
    if (perRowsToggle !== null) {
      fetchData(curPage);
    }
  }, [perRowsToggle]);

  /** EXPORT **/

  const getExportData = () => {
    let edata = {
      head: [],
      align: [],
      body: [],
      columnWidth: [],
      chunkLen: chunkLen || null,
      horizontal: horizontal || null,
      filtros: filtros,
      filtrosPrint: fnFiltrosPrint ? fnFiltrosPrint(filtros) : {},
      titulo,
      tableLayoutFixed: exportTableLayoutFixed || false,
    };

    columns
      .filter(c => c.selector !== undefined)
      .filter(c => exportarTodasColumnas || !c.soloExport)
      .forEach(c => {
        edata.head.push(c.name);
        edata.align.push(c.textRight ? 'right' : '');
        edata.columnWidth.push(c.exportColumnWidth || '');
      });

    let dataExport = data; // normalmente
    if (dataSelect) dataExport = dataSelect; // custom data export

    dataExport.forEach(rin => {
      let rout = [];
      columns
        .filter(c => c.selector !== undefined)
        .filter(c => exportarTodasColumnas || !c.soloExport)
        .forEach(c => rout.push(c.selector(rin)));
      edata.body.push(rout);
    });

    return edata;
  }

  const getExportName = async () => {
    return titulo || 'export';
  }

  const exportExcel = async () => {
    dispatch(iniLoading());
    let edata = getExportData();
    try {
      let response = await axios.post(backend + '/api/export_dataTableToExcel',
        { edata }, { responseType: 'blob' });
      inMemoryFileDownload(response.data, await getExportName() + '.xlsx');
    } catch (err) {
      console.error(err);
      toast.error('Ha ocurrido un error al exportar a EXCEL.');
    }
    dispatch(finLoading());
  }

  const exportPdf = async () => {
    dispatch(iniLoading());
    let edata = getExportData();
    try {
      let response = await axios.post(backend + '/api/export_dataTableToPdf',
        { edata }, { responseType: 'blob' });
      inMemoryFileDownload(response.data, await getExportName() + '.pdf');
    } catch (err) {
      console.error(err);
      toast.error('Ha ocurrido un error al exportar a PDF.');
    }
    dispatch(finLoading());
  }

  return <>
    {exportar && <>
      <div className="d-flex content-between items-center pt-2 pb-2">
        <div>
          <span>Listado</span>
        </div>
        <div>
          <button type="button" className="btn btn-outline-secondary"
            onClick={exportExcel} disabled={loadingCount > 0 || data.length == 0}
            title={!dataSelect
              ? "Exportar el listado actual como un documento EXCEL."
              : "Exportar las filas seleccionadas como un documento EXCEL"
            }>
            <i className="fas fa-file-excel" />
          </button>
          <button type="button" className="btn btn-outline-secondary ml-3"
            onClick={exportPdf} disabled={loadingCount > 0 || data.length == 0}
            title={!dataSelect
              ? "Exportar el listado actual como un documento PDF."
              : "Exportar las filas seleccionadas como un documento PDF"
            }>
            <i className="fas fa-file-pdf" />
          </button>
        </div>
      </div>
    </>}

    <DataTable
      data={data}
      columns={columns.filter(col => !col.soloExport)}
      pagination
      paginationServer
      sortServer
      onSort={handleSort}
      paginationTotalRows={totalRows}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      paginationResetDefaultPage={resetPaginationToggle}
      theme={darkMode ? 'muiDark' : 'default'}
      noDataComponent='No hay registros para mostrar'
      {...rest}
    />
  </>;
}

// Setting default values for the props of DataTableBase
DataTableBase.defaultProps = {
  filtros: {},
};

// Typechecking props of the DataTableBase
DataTableBase.propTypes = {
  url: PropTypes.string.isRequired,
  /* eslint-disable */
  filtros: PropTypes.object,
  /* eslint-enable */
};

export default DataTableBase;
