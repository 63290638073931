import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';

import BrandPrimary from '../../assets/img/brand-primary.png';
import FormProducto from '../formularios/FormProducto';
import FormDatosBasicos from '../formularios/FormDatosBasicos';
import FormDatosContacto from '../formularios/FormDatosContacto';
import FormDatosLaborales from '../formularios/FormDatosLaborales';

/**
 * SolicitudProducto Component
 */
function SolicitudProducto() {
  const backend = useSelector((state) => state.env.backend);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const captchaRefPersona = useRef(null);
  const captchaRefEmpresa = useRef(null);

  const idProductoParam = new URLSearchParams(location.search).get('idProducto');
  const nroDocParam = new URLSearchParams(location.search).get('nroDocumento');
  const idMonedaPrestamo = new URLSearchParams(location.search).get('idMonedaP');
  const montoPrestamo = new URLSearchParams(location.search).get('montoP');

  const formInitialState = {
    // formulario producto
    idProducto: idProductoParam ? idProductoParam : '',
    idMoneda: idMonedaPrestamo ? idMonedaPrestamo : '',
    montoPrestamo: montoPrestamo ? montoPrestamo : '',
    lineaCredito: '',
    //  formulario datos personales
    idTipoDocumento: 1,
    numeroDocumento: nroDocParam ? nroDocParam : '',
    nombre: '',
    apellido: '',
    estadoCivil: '',
    // formulario contacto
    direccion: '',
    numeroDomicilio: '',
    referencia: '',
    idDepartamento: '',
    idCiudad: '',
    barrio: '',
    idFormatoTelefono: '',
    telefono: '',
    email: '',
    // formulario datos laborales
    idSucursal: '',
    lugarTrabajo: '',
    salarioActual: '',
    idFormatoTelefonoLaboral: '',
    telefonoLaboral: '',
    documento: '',
    urlDocumento: '',
    comprobanteIngreso: '',
    urlComprobanteIngreso: '',
    aceptacion: false,
    esPersona: true,
  };

  const [form, setForm] = useState(formInitialState);
  // const [sendForm, setSendForm] = useState(false);
  const [fileDocumento, setFileDocumento] = useState('');
  const [fileComprobanteIngreso, setFileComprobanteIngreso] = useState('');
  const [nroForm, setNroForm] = useState(1);
  const [maxForm, setMaxForm] = useState(1); // nivel de formulario mas alto alcanzado

  const [selectProductos, setSelectProductos] = useState([]);
  const [selectMonedas, setSelectMonedas] = useState([]);
  const [selectTiposDocumento, setSelectTiposDocumento] = useState([]);
  const [selectDepartamentos, setSelectDepartamentos] = useState([]);
  const [selectSucursales, setSelectSucursales] = useState([]);
  const [selectFormatosTelefono, setSelectFormatosTelefono] = useState([]);
  const [formatoTelefonoParaguay, setFormatoTelefonoParaguay] = useState(null);
  const [urlTerminosCondiciones, setUrlTerminosCondiciones] = useState(null);

  const updateForm = (name, value) => {
    // if (name == 'changeTipoPersonaChild') setForm({ ...form, apellido: '', estadoCivil: '' });
    if (name == 'idDepartamento') setForm({ ...form, [name]: value, idCiudad: '' });
    if (name == 'idTipoDocumento') setForm({ ...form, [name]: value, numeroDocumento: '' });
    if (name == 'idProducto')
      setForm({ ...form, [name]: value, idMoneda: '', montoPrestamo: '', lineaCredito: '' });
    else setForm({ ...form, [name]: value });
  };

  const updateFileDocumento = (value) => {
    setFileDocumento(value);
  };

  const updateFileComprobanteIngreso = (value) => {
    setFileComprobanteIngreso(value);
  };

  const siguientePagina = () => {
    setMaxForm(maxForm + 1);
    setNroForm(nroForm + 1);
  };

  const saltarPagina = (nroPagina) => {
    setMaxForm(nroPagina);
    setNroForm(nroPagina);
  };

  const enviarFormulario = () => {
    dispatch(iniLoading());

    const token = form.esPersona
      ? captchaRefPersona.current.getValue()
      : captchaRefEmpresa.current.getValue();

    let formData = new FormData();

    formData.append('idProducto', form.idProducto);
    formData.append('idMoneda', form.idMoneda);
    formData.append('montoPrestamo', form.montoPrestamo);
    formData.append('lineaCredito', form.lineaCredito);
    formData.append('idTipoDocumento', form.idTipoDocumento);
    formData.append('numeroDocumento', form.numeroDocumento);
    formData.append('nombre', form.nombre);
    formData.append('apellido', form.apellido);
    formData.append('estadoCivil', form.estadoCivil);
    formData.append('direccion', form.direccion);
    formData.append('numeroDomicilio', form.numeroDomicilio);
    formData.append('referencia', form.referencia);
    formData.append('idDepartamento', form.idDepartamento);
    formData.append('idCiudad', form.idCiudad);
    formData.append('barrio', form.barrio);
    formData.append('idFormatoTelefono', form.idFormatoTelefono);
    formData.append('telefono', form.telefono);
    formData.append('email', form.email);
    formData.append('idSucursal', form.idSucursal);
    formData.append('lugarTrabajo', form.lugarTrabajo);
    formData.append('salarioActual', form.salarioActual);
    formData.append('idFormatoTelefonoLaboral', form.idFormatoTelefonoLaboral);
    formData.append('telefonoLaboral', form.telefonoLaboral);
    formData.append('aceptacion', form.aceptacion);
    formData.append('esPersona', form.esPersona);
    formData.append('token', token);

    if (form.esPersona) {
      formData.append('archivoDoc', fileDocumento);
      formData.append('archivoCompIng', fileComprobanteIngreso);
    }

    axios
      .post(`${backend}/api/formularios_producto`, formData)
      .then((response) => {
        let res = response.data;
        if (res.ok) {
          toast.success('Formulario enviado!');
          setForm(formInitialState);
          navigate('/site/confirmacion');
        } else {
          if (res.error) toast.error(res.error || 'Error al enviar formulario.');
          else toast.error('Verifique los datos cargados.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al enviar el formulario.');
      })
      .finally(() => {
        if (captchaRefPersona.current) captchaRefPersona.current.reset();
        if (captchaRefEmpresa.current) captchaRefEmpresa.current.reset();
        dispatch(finLoading());
      });
  };

  const getProductos = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/productos`);
      let res = response.data;
      if (Array.isArray(res)) {
        setSelectProductos(res);
      } else {
        setSelectProductos([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener productos.');
    }
    dispatch(finLoading());
  };

  const getMonedas = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/monedas`);
      let res = response.data;
      if (Array.isArray(res)) {
        setSelectMonedas(res);
      } else {
        setSelectMonedas([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener monedas.');
    }
    dispatch(finLoading());
  };

  const getTiposDocumento = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/tiposDocumento`);
      let res = response.data;
      if (Array.isArray(res)) {
        setSelectTiposDocumento(res);
        for (let td of res)
          if (td.nombre == 'Cédula de identidad' && nroDocParam)
            setForm({ ...form, idTipoDocumento: td.id });
      } else {
        setSelectTiposDocumento([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener tipos de documento.');
    }
    dispatch(finLoading());
  };

  const getDepartamentos = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/departamentos`);
      let res = response.data;
      if (Array.isArray(res)) {
        setSelectDepartamentos(res);
      } else {
        setSelectDepartamentos([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener departamentos.');
    }
    dispatch(finLoading());
  };

  const getSucursales = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/sucursales?sucursalOrden=true`);
      let res = response.data;
      if (Array.isArray(res)) {
        setSelectSucursales(res);
      } else {
        setSelectSucursales([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener sucursales.');
    }
    dispatch(finLoading());
  };

  const getFormatosTelefono = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/formatosTelefono`);
      let res = response.data;
      setFormatoTelefonoParaguay(
        res.filter((ft) => ft.codigo_pais == 'PY').length > 0
          ? res.filter((ft) => ft.codigo_pais == 'PY')[0]
          : null
      );
      if (Array.isArray(res)) {
        setSelectFormatosTelefono(res);
      } else {
        setSelectFormatosTelefono([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener formatos de telefono.');
    }
    dispatch(finLoading());
  };

  const getTerminosCondiciones = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/terminos_condiciones`);
      let res = response.data;
      if (res.contenido) {
        setUrlTerminosCondiciones(res.contenido);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al cargar terminos y condiciones.');
    }
    dispatch(finLoading());
  };

  const changeTipoPersona = (name) => {
    setForm({
      ...form,
      esPersona: name == 'persona',
      idTipoDocumento: name == 'persona' ? 1 : 3,
      apellido: '',
      estadoCivil: '',
      lugarTrabajo: '',
      salarioActual: '',
      telefonoLaboral: '',
      urlDocumento: '',
      urlComprobanteIngreso: '',
      aceptacion: false,
    });
    if (captchaRefPersona.current) captchaRefPersona.current.reset();
    if (captchaRefEmpresa.current) captchaRefEmpresa.current.reset();
    saltarPagina(1);
  };

  // carga inicial de selects
  useEffect(() => {
    getProductos();
    getMonedas();
    getTiposDocumento();
    getDepartamentos();
    getSucursales();
    getFormatosTelefono();
    getTerminosCondiciones();
  }, [nroDocParam]);

  useEffect(() => {
    if (formatoTelefonoParaguay != null) {
      setForm({
        ...form,
        idFormatoTelefono: formatoTelefonoParaguay.id,
        idFormatoTelefonoLaboral: formatoTelefonoParaguay.id,
      });
    }
  }, [formatoTelefonoParaguay]);

  return (
    <div className="modal">
      <div className="modal-header">
        <a href="/">
          <img src={BrandPrimary} className="modal-logo" />
        </a>

        <h4 className="font-weight-regular mb-2">Solicitud de productos</h4>
        <h1>Tenemos el producto ideal para vos</h1>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <label htmlFor="checkPersona">
          <input
            type="radio"
            id="checkPersona"
            name="tipoPersona"
            checked={form.esPersona}
            onChange={(e) => {
              changeTipoPersona('persona');
            }}
            className="checkbox-accent"
          />
          Persona
        </label>
        <label htmlFor="checkEmpresa">
          <input
            type="radio"
            id="checkEmpresa"
            name="tipoPersona"
            checked={!form.esPersona}
            onChange={(e) => {
              changeTipoPersona('empresa');
            }}
            style={{ marginLeft: '2em' }}
            className="checkbox-accent"
          />
          Empresa
        </label>
      </div>

      <div
        className="steps-indicator"
        style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}
      >
        <div className={nroForm == 1 ? 'step active' : 'step completed'}>
          <span
            className="step__icon"
            style={{ cursor: nroForm != 1 && maxForm >= 1 ? 'pointer' : 'default' }}
            onClick={(e) => {
              if (nroForm != 1 && maxForm >= 1) saltarPagina(1);
            }}
          >
            1
          </span>
          <p>Datos del producto</p>
        </div>
        <div className={nroForm == 2 ? 'step active' : 'step completed'}>
          <span
            className="step__icon"
            style={{ cursor: nroForm != 2 && maxForm >= 2 ? 'pointer' : 'default' }}
            onClick={(e) => {
              if (nroForm != 2 && maxForm >= 2) saltarPagina(2);
            }}
          >
            2
          </span>
          <p>Datos {form.esPersona ? 'personales' : 'de la empresa'}</p>
        </div>
        <div className={nroForm == 3 ? 'step active' : 'step completed'}>
          <span
            className="step__icon"
            style={{ cursor: nroForm != 3 && maxForm >= 3 ? 'pointer' : 'default' }}
            onClick={(e) => {
              if (nroForm != 3 && maxForm >= 3) saltarPagina(3);
            }}
          >
            3
          </span>
          <p>Datos de contacto</p>
        </div>
        {form.esPersona && (
          <div className={nroForm == 4 ? 'step active' : 'step completed'}>
            <span
              className="step__icon"
              style={{ cursor: nroForm != 4 && maxForm >= 4 ? 'pointer' : 'default' }}
              onClick={(e) => {
                if (nroForm != 4 && maxForm >= 4) saltarPagina(4);
              }}
            >
              4
            </span>
            <p>Datos laborales</p>
          </div>
        )}
      </div>

      <div className="modal-body">
        {nroForm == 1 && (
          <FormProducto
            form={form}
            updateForm={updateForm}
            siguientePagina={siguientePagina}
            selectProductos={selectProductos}
            selectMonedas={selectMonedas}
          />
        )}
        {nroForm == 2 && (
          <FormDatosBasicos
            form={form}
            updateForm={updateForm}
            siguientePagina={siguientePagina}
            selectTiposDocumento={selectTiposDocumento}
            selectSucursales={selectSucursales}
          />
        )}
        {nroForm == 3 && (
          <FormDatosContacto
            form={form}
            updateForm={updateForm}
            siguientePagina={siguientePagina}
            selectDepartamentos={selectDepartamentos}
            selectFormatosTelefono={selectFormatosTelefono}
            enviarFormulario={enviarFormulario}
            formatoTelefonoParaguay={formatoTelefonoParaguay}
            captchaRefEmpresa={captchaRefEmpresa}
          />
        )}
        {/* {nroForm == 4 && ( */}
        {/* el formulario tiene inputs tipo file, NO se puede setear el valor de esos inputs programaticamente, entonces se oculta y se muestra dependiendo de la pagina */}
        <div style={{ display: nroForm == 4 ? 'block' : 'none' }}>
          <FormDatosLaborales
            form={form}
            updateForm={updateForm}
            updateFileDocumento={updateFileDocumento}
            updateFileComprobanteIngreso={updateFileComprobanteIngreso}
            selectSucursales={selectSucursales}
            selectFormatosTelefono={selectFormatosTelefono}
            formatoTelefonoParaguay={formatoTelefonoParaguay}
            captchaRefPersona={captchaRefPersona}
            enviarFormulario={enviarFormulario}
            urlTerminosCondiciones={urlTerminosCondiciones}
          />
        </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default SolicitudProducto;
