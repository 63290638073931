import axios from 'axios';
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { toast } from 'react-toastify';
import { isoFechaActual, segundosAPalabras } from '../Util';
import { NumericFormat } from 'react-number-format';

/**
 * Resumen de paginas mas visitadas y duracion de visitas
 * @returns react functional component
 */
function AdminDashResumenPaginas() {
  const backend = useSelector((state) => state.env.backend);
  const iniFiltros = {
    fechaDesde: '',
    fechaHasta: '',
    cmpNroVisitas: 1,
    nroVisitas: '',
    cmpDuracionProm: 1,
    segDuracionProm: '',
  };
  const [filtros, setFiltros] = useState(iniFiltros);
  const [dataBackend, setDataBackend] = useState([]);
  const dispatch = useDispatch();
  const [errores, setErrores] = useState({});
  const [callbackRestablecer, setCallbackRestablecer] = useState(0);

  const getDataBackend = async () => {
    let usp = new URLSearchParams();
    if (filtros.fechaDesde) usp.set('fechaDesde', filtros.fechaDesde);
    if (filtros.fechaHasta) usp.set('fechaHasta', filtros.fechaHasta);
    dispatch(iniLoading());
    try {
      let response = await axios.get(backend + '/api/dashboard/resumenPaginas?' + usp.toString());
      if (Array.isArray(response.data)) {
        setDataBackend(postFiltros(response.data));
      } else {
        toast.error('Error al obtener el informe.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener el informe.');
    }
    dispatch(finLoading());
  }

  // filtros aplicados en el frontend
  const postFiltros = (dataBackend) => {
    if (filtros.nroVisitas) {
      if (filtros.cmpNroVisitas == 1) {
        dataBackend = dataBackend.filter(resPag => resPag.nroVisitas > filtros.nroVisitas);
      } else {
        dataBackend = dataBackend.filter(resPag => resPag.nroVisitas < filtros.nroVisitas);
      }
    }
    if (filtros.segDuracionProm) {
      if (filtros.cmpDuracionProm == 1) {
        dataBackend = dataBackend.filter(resPag => resPag.segDuracionProm > filtros.segDuracionProm);
      } else {
        dataBackend = dataBackend.filter(resPag => resPag.segDuracionProm < filtros.segDuracionProm);
      }
    }
    return dataBackend;
  }

  const validarFiltros = () => {
    let v = true;
    let e = {};
    // console.log('validarFiltros', filtros);

    if (filtros.fechaDesde && filtros.fechaHasta
      && filtros.fechaDesde > filtros.fechaHasta) {
      e.fechaHasta = 'La Fecha Hasta no puede ser menor a la Fecha Desde.';
      v = false;
    }

    if (filtros.nroVisitas < 0) {
      e.nroVisitas = 'El número no puede ser menor a 0.';
      v = false;
    }

    if (filtros.segDuracionProm < 0) {
      e.segDuracionProm = 'El número no puede ser menor a 0.';
      v = false;
    }

    setErrores(e);
    return v;
  }

  const clickListar = async () => {
    if (validarFiltros()) {
      await getDataBackend();
    }
  }

  const clickRestablecer = () => {
    setFiltros(iniFiltros);
    setErrores({});
    setCallbackRestablecer(c => c + 1);
  }

  useEffect(() => {
    if (callbackRestablecer > 0) {
      getDataBackend();
    }
  }, [callbackRestablecer]);

  const handleChange = event => {
    setFiltros({
      ...filtros,
      [event.target.name]: event.target.value,
    });
  }

  const handleChangeNumeric = (values, source) => {
    setFiltros({
      ...filtros,
      [source.event.target.name]: values.value,
    });
  }

  // on mounted
  useEffect(() => {
    getDataBackend();
  }, []);

  return <>
    <div className='mb-3'>
      <div className='mb-4'>
        <h4 className="font-weight-regular">
          Páginas más visitadas
        </h4>
      </div>

      <div className="d-flex items-center--">
        <div className="form-group mb-3 mr-4" style={{ maxWidth: '200px' }}>
          <label htmlFor="fecha-desde">Fecha Desde</label>
          <input
            type="date"
            className={`form-control ${errores.fechaDesde ? 'is-invalid' : ''}`}
            id="fecha-desde"
            name='fechaDesde'
            value={filtros.fechaDesde}
            onChange={handleChange}
            max={isoFechaActual()}
          />
          <span className="text-danger">{errores.fechaDesde}</span>
        </div>

        <div className="form-group mb-3 mr-4" style={{ maxWidth: '200px' }}>
          <label htmlFor="fecha-hasta">Fecha Hasta</label>
          <input
            type="date"
            className={`form-control ${errores.fechaHasta ? 'is-invalid' : ''}`}
            id="fecha-hasta"
            name='fechaHasta'
            value={filtros.fechaHasta}
            onChange={handleChange}
            max={isoFechaActual()}
          />
          <span className="text-danger">{errores.fechaHasta}</span>
        </div>

        <div className="form-group mb-3 mr-4" style={{ maxWidth: '200px' }}>
          <label>Nro. Visitas</label>
          <div className='d-flex'>
            <select
              className='form-control'
              style={{ width: '80px' }}
              name='cmpNroVisitas'
              value={filtros.cmpNroVisitas}
              onChange={handleChange}
            >
              <option value="1">{'>'}</option>
              <option value="2">{'<'}</option>
            </select>
            <NumericFormat
              name='nroVisitas'
              value={filtros.nroVisitas}
              onValueChange={handleChangeNumeric}
              valueIsNumericString={true}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              className={`form-control ${errores.nroVisitas ? 'is-invalid' : ''}`}
              style={{ width: '120px' }}
            />
          </div>
          <span className="text-danger">{errores.cmpNroVisitas}</span>
          <span className="text-danger">{errores.nroVisitas}</span>
        </div>

        <div className="form-group mb-3 mr-4" style={{ maxWidth: '200px' }}>
          <label>Duración Prom.</label>
          <div className='d-flex'>
            <select
              className='form-control'
              style={{ width: '80px' }}
              name='cmpDuracionProm'
              value={filtros.cmpDuracionProm}
              onChange={handleChange}
            >
              <option value="1">{'>'}</option>
              <option value="2">{'<'}</option>
            </select>
            <NumericFormat
              name='segDuracionProm'
              value={filtros.segDuracionProm}
              onValueChange={handleChangeNumeric}
              valueIsNumericString={true}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              className={`form-control ${errores.segDuracionProm ? 'is-invalid' : ''}`}
              style={{ width: '120px' }}
              placeholder='segundos'
              title='Duración promedio en segundos.'
            />
          </div>
          <span className="text-danger">{errores.cmpDuracionProm}</span>
          <span className="text-danger">{errores.segDuracionProm}</span>
        </div>

        <div className='mb-3'>
          <label className='d-block'>&nbsp;</label>
          <button className="btn btn-primary mr-3" onClick={clickListar} title='Aplicar filtros'>
            <i className="fas fa-fw fa-filter"></i> Listar
          </button>
          <button className="btn btn-outline-secondary" onClick={clickRestablecer}>
            Restablecer
          </button>
        </div>
      </div>

      <DataTable
        columns={[
          {
            name: 'Ranking',
            selector: (row) => row.ranking,
            width: '10%',
            sortable: true,
            sortField: 'ranking',
          },
          {
            name: 'Página',
            selector: (row) => row.urlPagina,
            width: '40%',
          },
          {
            name: 'Visitas',
            selector: (row) => row.nroVisitas.toLocaleString('es-PY'),
            sortable: true,
            sortField: 'nroVisitas',
          },
          {
            name: 'Duración Promedio',
            selector: (row) => row.segDuracionProm,
            format: (row) => segundosAPalabras(row.segDuracionProm),
            sortable: true,
            sortField: 'segDuracionProm',
          },
          {
            name: 'Visitantes Únicos',
            selector: (row) => row.nroVisitantesUnicos,
            sortable: true,
            sortField: 'nroVisitantesUnicos',
          },
        ]}
        data={dataBackend}
        pagination={true}
        paginationPerPage={10}
        defaultSortFieldId={1}
        noDataComponent='No hay registros para mostrar'
      />
    </div>
  </>;
}

export default AdminDashResumenPaginas;
