import { useState } from 'react';
import DataTableBase from '../DataTableBase';
import ReclamoAccion from './ReclamoAccion';
import { useSelector } from 'react-redux';
import { dispIsoDateTimeString } from '../Util';

/**
 * ReclamoLista Component
 */
function ReclamoLista() {
  const authData = useSelector((state) => state.auth.value);

  const columns = [
    {
      name: 'FECHA',
      selector: (row) => {
        return dispIsoDateTimeString(row.created_at);
      },
      sortable: true,
      sortField: 'created_at',
    },
    {
      name: 'APELLIDO',
      selector: (row) => row.apellido,
      sortable: true,
      sortField: 'apellido',
    },
    {
      name: 'NOMBRE',
      selector: (row) => row.nombre,
      sortable: true,
      sortField: 'nombre',
    },
    {
      name: 'N° DOCUMENTO',
      selector: (row) => row.numeroDocumento,
      sortable: true,
      sortField: 'numeroDocumento',
    },
    {
      name: 'CLIENTE',
      selector: (row) => (row.esCliente ? 'SI' : 'NO'),
      sortable: true,
      sortField: 'esCliente',
    },
    {
      name: 'PRODUCTO',
      selector: (row) => (row.producto ? row.producto.nombre : '-'),
      sortable: true,
      sortField: 'idProducto',
    },
    {
      name: 'Acciones',
      cell: (row) => <ReclamoAccion reclamo={row} />,
    },
  ];

  // FILTROS
  const [filtroBusNombre, setFiltroBusNombre] = useState('');
  const [filtroBusApellido, setFiltroBusApellido] = useState('');
  const [filtroBusDoc, setFiltroBusDoc] = useState('');
  const [dtFiltros, setDtFiltros] = useState({}); // data table filtros
  const listar = () => {
    setDtFiltros({
      nombre: filtroBusNombre,
      apellido: filtroBusApellido,
      documento: filtroBusDoc,
    });
  };
  const limpiar = () => {
    setFiltroBusNombre('');
    setFiltroBusApellido('');
    setFiltroBusDoc('');
    setDtFiltros({});
  };

  return (
    authData &&
    (authData.user.rol == 'admin' || authData.user.rol == 'calidad') && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-3">
          <h4 className="font-weight-regular">Reclamos</h4>
        </div>

        {/* FILTROS */}
        <div className="d-flex- items-center-">
          {/* filtro apellido */}
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="apellido">Buscar / apellido</label>
            <input
              id="apellido"
              type="text"
              className="form-control"
              value={filtroBusApellido}
              onChange={(e) => setFiltroBusApellido(e.target.value)}
              style={{ width: '12rem' }}
              autoComplete="off"
            />
          </div>

          {/* filtro nombre */}
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="nombre">Buscar / nombre</label>
            <input
              id="nombre"
              type="text"
              className="form-control"
              value={filtroBusNombre}
              onChange={(e) => setFiltroBusNombre(e.target.value)}
              style={{ width: '12rem' }}
              autoComplete="off"
            />
          </div>

          {/* filtro documento */}
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="doc">Buscar / n° documento</label>
            <input
              id="doc"
              type="text"
              className="form-control"
              value={filtroBusDoc}
              onChange={(e) => setFiltroBusDoc(e.target.value)}
              style={{ width: '12rem' }}
              autoComplete="off"
            />
          </div>

          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="posicion">&nbsp;</label>
            <button className="btn btn-outline-secondary" onClick={listar}>
              <i className="fas fa-fw fa-filter"></i> Listar
            </button>
          </div>
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="posicion">&nbsp;</label>
            <button className="btn btn-outline-secondary" onClick={limpiar}>
              <i className="fas fa-fw fa-broom"></i> Limpiar
            </button>
          </div>
          <div className="clear-fix"></div>
        </div>

        <div>
          <DataTableBase url={'/api/reclamos'} columns={columns} filtros={dtFiltros} />
        </div>
      </div>
    )
  );
}

export default ReclamoLista;
