import { useState } from 'react';
import DataTableBase from '../DataTableBase';
import CategoriaAccion from './CategoriaAccion';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function CategoriaLista() {
  const authData = useSelector((state) => state.auth.value);

  const columns = [
    {
      name: 'Nombre',
      selector: (row) => row.nombre,
      sortable: true,
      sortField: 'nombre',
    },
    {
      name: 'Posición',
      selector: (row) => (row.navbar ? 'Navbar' : row.footer ? 'Footer' : 'Social'),
      sortable: true,
      sortField: 'social',
    },
    // {
    //   name: 'PADRE',
    //   selector: (row) => row.idCategoriaPadre && row.categoria_padre != null ? row.categoria_padre.nombre : '-',
    //   sortable: true,
    //   sortField: 'idCategoriaPadre',
    // },
    {
      name: 'Orden',
      selector: (row) => row.orden,
      sortable: true,
      sortField: 'orden',
    },
    {
      name: 'Acciones',
      cell: (row) => <CategoriaAccion categoria={row} />,
    },
  ];

  // FILTROS
  const [filtroBus, setFiltroBus] = useState('');
  const [filtroPos, setFiltroPos] = useState('');
  const [dtFiltros, setDtFiltros] = useState({}); // data table filtros
  const listar = () => {
    setDtFiltros({
      buscar: filtroBus,
      posicion_index: filtroPos,
    });
  };
  const limpiar = () => {
    setFiltroBus('');
    setFiltroPos('');
    setDtFiltros({});
  };

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-3">
          <h4 className="font-weight-regular">Gestión de Categorias</h4>
          <Link to={'/admin/categorias/new'} className="btn btn-primary">
            <i className="fas fa-fw fa-plus"></i> Nueva Categoría
          </Link>
        </div>

        <div className="d-flex- items-center-">
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="nombre">Buscar / nombre</label>
            <input
              id="nombre"
              type="text"
              className="form-control"
              value={filtroBus}
              onChange={(e) => setFiltroBus(e.target.value)}
              style={{ width: '12rem' }}
            />
          </div>
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="posicion">Filtrar / posición</label>
            <select
              id="posicion"
              className="form-control"
              value={filtroPos}
              onChange={(e) => setFiltroPos(e.target.value)}
              style={{ width: '12rem' }}
            >
              <option value="">Seleccione...</option>
              <option value="navbar">Navbar</option>
              <option value="footer">Footer</option>
              <option value="social">Social</option>
            </select>
          </div>
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="posicion">&nbsp;</label>
            <button className="btn btn-outline-secondary" onClick={listar}>
              <i className="fas fa-fw fa-filter"></i> Listar
            </button>
          </div>
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="posicion">&nbsp;</label>
            <button className="btn btn-outline-secondary" onClick={limpiar}>
              <i className="fas fa-fw fa-broom"></i> Limpiar
            </button>
          </div>
          <div className="clear-fix"></div>
        </div>

        <div>
          <DataTableBase url={'/api/categorias'} columns={columns} filtros={dtFiltros} />
        </div>
      </div>
    )
  );
}

export default CategoriaLista;
