import { createSlice } from '@reduxjs/toolkit';

/**
 * Window title
 */
export const titleSlice = createSlice({
  name: 'title',
  initialState: {
    value: '', // la app (App.jsx) concatena este valor al titulo de la ventana
  },
  reducers: {
    setTitle: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setTitle } = titleSlice.actions;

export default titleSlice.reducer;
