import { createSlice } from '@reduxjs/toolkit';

/**
 * variables globales de entorno
 */
export const envSlice = createSlice({
  name: 'env',
  initialState: {
    backend: process.env.REACT_APP_BACKEND, // from env file
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // from env file
    captchaSiteKey: process.env.REACT_APP_CAPTCHA_SITE_KEY, // from env file
  },
  reducers: {
    setBackend: (state, action) => {
      state.backend = action.payload;
    },
    setGoogleMapsApiKey: (state, action) => {
      state.googleMapsApiKey = action.payload;
    },
    setCaptchaSiteKey: (state, action) => {
      state.captchaSiteKey = action.payload;
    },
  },
});

export const { setBackend } = envSlice.actions;

export default envSlice.reducer;
