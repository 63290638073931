import PropTypes from 'prop-types';
import { getImageUrl } from '../Util';

/**
 * Website component: PanelTituloTextoHorizontal
 * codigo = cmp_tit_txt_hrz
 */
function PanelTituloTextoHorizontal({ muestra }) {
  return (
    <section className="container">
      <div className="section-photo-text">
        <div className="section-w-text">
          <h1 className="mb-2" style={{ textAlign: muestra.titulo_justify ? 'justify' : 'left' }}>
            {muestra.titulo}
          </h1>
        </div>
        <div className="section-w-text">
          <p className="mb-3" style={{ textAlign: muestra.texto_justify ? 'justify' : 'left' }}>
            {muestra.texto}
          </p>
          {muestra.enlace && (
            <a href={muestra.enlace} className="btn btn-primary">
              {muestra.label || 'Ir'}
            </a>
          )}
        </div>
      </div>
    </section>
  );
}

// Setting default values for the props of component
PanelTituloTextoHorizontal.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelTituloTextoHorizontal.propTypes = {
  muestra: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    texto: PropTypes.string.isRequired,
    enlace: PropTypes.string, // opcional
    label: PropTypes.string, // opcional
  }).isRequired,
};

export default PanelTituloTextoHorizontal;
