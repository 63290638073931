import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/**
 * Website component: PanelListaColumnas
 * codigo = cmp_list_cols
 */
function PanelListaColumnas({ muestra }) {
  const backend = useSelector((state) => state.env.backend);
  return (
    <section className="container mt-5 mb-5">
      <div>
        <h3 className="mb-3">{muestra.titulo}</h3>
        <ul className="plana-list">
          {muestra.lista.map((it, i) => (
            <li key={i} className="plana-list-item" style={{ display: 'flex' }}>
              {it.srcImgProfile && (
                <img
                  key={`img_${i}`}
                  src={`${backend}${it.srcImgProfile}`}
                  style={{ height: '4em', width: 'auto', marginRight: '1em' }}
                />
              )}
              <div key={`desc_${i}`}>
                <h5 style={{ textAlign: it.subtitulo_justify ? 'justify' : 'left' }}>
                  {it.subtitulo}
                </h5>
                <p style={{ textAlign: it.descripcion_justify ? 'justify' : 'left' }}>
                  {it.descripcion}
                </p>
                {(it.enlace || it.etiqueta) && (
                  <a target="_blank" href={`${it.enlace ? it.enlace : '#'}`}>{`${
                    it.etiqueta ? it.etiqueta : 'Etiqueta'
                  }`}</a>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

// Setting default values for the props of component
PanelListaColumnas.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelListaColumnas.propTypes = {
  muestra: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    lista: PropTypes.arrayOf(
      PropTypes.shape({
        subtitulo: PropTypes.string.isRequired,
        descripcion: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default PanelListaColumnas;
