import { useState } from 'react';
import DataTableBase from '../DataTableBase';
import SuscripcionAccion from './SuscripcionAccion';
import { useSelector } from 'react-redux';

/**
 * SuscripcionLista Component
 */
function SuscripcionLista() {
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);

  const columns = [
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      sortField: 'email',
    },
    {
      name: 'Acciones',
      cell: (row) => <SuscripcionAccion suscripcion={row} listarSuscripciones={listar} />,
    },
  ];

  // FILTROS
  const [filtroBusEmail, setFiltroBusEmail] = useState('');
  const [dtFiltros, setDtFiltros] = useState({}); // data table filtros
  const listar = () => {
    setDtFiltros({
      email: filtroBusEmail,
    });
  };
  const limpiar = () => {
    setFiltroBusEmail('');
    setDtFiltros({});
  };

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-3">
          <h4 className="font-weight-regular">Suscripciones</h4>
        </div>

        {/* FILTROS */}
        <div className="d-flex- items-center-">
          {/* filtro email */}
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="email">Buscar / email</label>
            <input
              id="email"
              type="text"
              className="form-control"
              value={filtroBusEmail}
              onChange={(e) => setFiltroBusEmail(e.target.value)}
              style={{ width: '12rem' }}
            />
          </div>

          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="posicion">&nbsp;</label>
            <button className="btn btn-outline-secondary" onClick={listar}>
              <i className="fas fa-fw fa-filter"></i> Listar
            </button>
          </div>
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="posicion">&nbsp;</label>
            <button className="btn btn-outline-secondary" onClick={limpiar}>
              <i className="fas fa-fw fa-broom"></i> Limpiar
            </button>
          </div>
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="posicion">&nbsp;</label>
            <a
              className="btn btn-outline-secondary"
              href={`${backend}/api/suscripcion/exportar`}
              target="_blank"
            >
              <i className="fas fa-fw fa-file-excel"></i> Exportar
            </a>
          </div>
          <div className="clear-fix"></div>
        </div>

        <div>
          <DataTableBase url={'/api/suscripciones'} columns={columns} filtros={dtFiltros} />
        </div>
      </div>
    )
  );
}

export default SuscripcionLista;
