import axios from 'axios';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { capitalize } from '../Util';
import PrestamoTasasModal from './PrestamoTasasModal';

function Prestamos() {
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tiposPs, setTiposPs] = useState([]);
  const [monedas, setMonedas] = useState([]);
  const [prestamos, setPrestamos] = useState([]);
  const [errores, setErrores] = useState({});
  const [mensajeCalculadora, setMensajeCalculadora] = useState('');

  const getPrestamos = () => {
    dispatch(iniLoading());
    axios
      .get(`${backend}/api/prestamos`)
      .then((response) => {
        let res = response.data;
        if (Array.isArray(res)) {
          setPrestamos(res);
        } else {
          toast.error(res.error || 'Error obteniendo prestamos.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error obteniendo prestamos.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  const getTiposPrestamos = () => {
    dispatch(iniLoading());
    axios
      .get(`${backend}/api/prestamos?tipos_prestamos=1`)
      .then((response) => {
        let res = response.data;
        if (Array.isArray(res)) {
          setTiposPs(res);
        } else {
          toast.error(res.error || 'Error obteniendo prestamos.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error obteniendo prestamos.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  const getMonedas = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/monedas`);
      let res = response.data;
      if (Array.isArray(res)) {
        setMonedas(res);
      } else {
        setMonedas([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener monedas.');
    }
    dispatch(finLoading());
  };

  const getMensajeCalculadora = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(
        `${backend}/api/configuraciones?key=MENSAJE_CALCULADORA_PRESTAMOS`
      );
      let res = response.data;
      if (res.ok) {
        setMensajeCalculadora(res.value);
      } else {
        toast.error('Error al obtener mensaje de calculadora.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener mensaje de calculadora.');
    }
    dispatch(finLoading());
  };

  // ini
  useEffect(() => {
    if (authData && authData.user.rol == 'admin') {
      getPrestamos();
      getTiposPrestamos();
      getMonedas();
      getMensajeCalculadora();
    }
  }, []);

  // handle input prestamos
  const handleChangePs = (name, index, value) => {
    setPrestamos((ps) => {
      ps[index][name] = value;
      ps[index]['rw_prestamo'] = true;
      return [...ps];
    });
  };

  const handleChangeMensajeCalculadora = (value) => {
    setMensajeCalculadora(value);
  };

  const validar = () => {
    let res = true;
    let errs = {};

    prestamos.forEach((p, i) => {
      if (!p.nombre.trim()) {
        res = false;
        errs['nombre' + i] = 'Ingrese el nombre del préstamo.';
      }
      if (!p.id_tipo_prestamo) {
        res = false;
        errs['tipo' + i] = 'Seleccione el sistema del préstamo.';
      }
      if (!p.monto_min || p.monto_min < 0) {
        res = false;
        errs['monto_min' + i] = 'Ingrese el monto mínimo del préstamo.';
      }
      if (!p.monto_max || p.monto_max < 0) {
        res = false;
        errs['monto_max' + i] = 'Ingrese el monto máximo del préstamo.';
      }
    });

    setErrores(errs);
    return res;
  };

  const clickGuardar = () => {
    if (validar()) {
      dispatch(iniLoading());
      guardarMensajeCalculadora();
      axios
        .post(`${backend}/api/prestamos`, { prestamos })
        .then((response) => {
          let res = response.data;
          if (res.ok) {
            toast.success('Préstamos guardados!');
            getPrestamos(); // refresh
          } else {
            toast.error(res.error || 'Error guardando préstamos.');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('Error guardando préstamos.');
        })
        .finally(() => {
          dispatch(finLoading());
        });
    } else {
      //console.log(prestamos);
      toast.error('Por favor, corrija el formulario.');
    }
  };

  const guardarMensajeCalculadora = () => {
    dispatch(iniLoading());
    axios
      .put(`${backend}/api/configuraciones/1`, {
        key: 'MENSAJE_CALCULADORA_PRESTAMOS',
        value: mensajeCalculadora,
      })
      .then((response) => {
        let res = response.data;
        if (res.ok) {
          toast.success('Mensaje guardado!');
        } else {
          toast.error(res.error || 'Error guardando mensaje.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error guardando mensaje.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // agrega nuevo prestamo
  const aggPrestamo = () => {
    let lp = prestamos[prestamos.length - 1];
    if (lp && lp.id == null) {
      toast.error('Por favor, primero guarde el último préstamo agregado!');
    } else {
      setPrestamos((ps) => [
        ...ps,
        {
          id: null,
          nombre: '',
          id_tipo_prestamo: '',
          monto_min: '',
          monto_max: '',
          tasas: [],
        },
      ]);
    }
  };

  const eliminarPrestamo = (i) => {
    let p = prestamos[i];
    if (p.id) {
      if (window.confirm(`¿Deseas eliminar este préstamo: ${p.nombre}?`)) {
        dispatch(iniLoading());
        axios
          .delete(`${backend}/api/prestamos/${p.id}`)
          .then((response) => {
            let res = response.data;
            if (res.ok) {
              toast.success('Préstamo eliminado!');
              setPrestamos((l) => l.filter((p, j) => i != j));
            } else {
              toast.error(res.error || 'Error al eliminar el préstamo.');
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error('Error al eliminar el préstamo.');
          })
          .finally(() => {
            dispatch(finLoading());
          });
      }
    } else {
      setPrestamos((l) => l.filter((p, j) => i != j));
    }
  };

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-5">
          <h4 className="font-weight-regular">Configuración de Préstamos</h4>
          <button className="btn btn-primary" onClick={aggPrestamo}>
            <i className="fas fa-fw fa-plus"></i> Agg. Préstamo
          </button>
        </div>

        {/* tabla de prestamos */}
        <div className="table-responsive mb-5">
          <table className="table-striped">
            <tbody>
              <tr>
                <th className="text-center">Nombre del Préstamo</th>
                <th className="text-center">Sistema del Préstamo</th>
                <th className="text-center">Monto Mín. (Gs)</th>
                <th className="text-center">Monto Máx. (Gs)</th>
                <th className="text-center">Tasas</th>
                <th>Acciones</th>
              </tr>
              {prestamos.map((pre, i) => (
                <tr key={pre.id}>
                  {/* nombre del prestamo */}
                  <td className="text-center">
                    <input
                      type="text"
                      className={`form-control text-center ${
                        errores['nombre' + i] ? 'is-invalid' : ''
                      }`}
                      value={pre.nombre || ''}
                      onChange={(e) => handleChangePs('nombre', i, capitalize(e.target.value))}
                      style={{ width: '16rem' }}
                    />
                    {errores['nombre' + i] && (
                      <span className="d-block text-danger">{errores['nombre' + i]}</span>
                    )}
                  </td>

                  {/* tipo del prestamo */}
                  <td className="text-center">
                    <select
                      className={`form-control text-center ${
                        errores['tipo' + i] ? 'is-invalid' : ''
                      }`}
                      value={pre.id_tipo_prestamo || ''}
                      onChange={(e) => handleChangePs('id_tipo_prestamo', i, e.target.value)}
                      style={{ width: '12rem' }}
                    >
                      <option value="">Seleccione...</option>
                      {tiposPs.map((tp) => (
                        <option key={tp.id} value={tp.id}>
                          {tp.nombre}
                        </option>
                      ))}
                    </select>
                    {errores['tipo' + i] && (
                      <span className="d-block text-danger">{errores['tipo' + i]}</span>
                    )}
                  </td>

                  {/* monto minimo del prestamo */}
                  <td className="text-center">
                    <NumericFormat
                      value={pre.monto_min || ''}
                      onValueChange={(v, s) => handleChangePs('monto_min', i, v.value)}
                      valueIsNumericString={true}
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={0}
                      className={`form-control text-right ${
                        errores['monto_min' + i] ? 'is-invalid' : ''
                      }`}
                      style={{ width: '12rem' }}
                    />
                    {errores['monto_min' + i] && (
                      <span className="d-block text-danger">{errores['monto_min' + i]}</span>
                    )}
                  </td>

                  {/* monto maximo del prestamo */}
                  <td className="text-center">
                    <NumericFormat
                      value={pre.monto_max || ''}
                      onValueChange={(v, s) => handleChangePs('monto_max', i, v.value)}
                      valueIsNumericString={true}
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={0}
                      className={`form-control text-right ${
                        errores['monto_max' + i] ? 'is-invalid' : ''
                      }`}
                      style={{ width: '12rem' }}
                    />
                    {errores['monto_max' + i] && (
                      <span className="d-block text-danger">{errores['monto_max' + i]}</span>
                    )}
                  </td>

                  {/* tasas del prestamo */}
                  <td className="text-center">
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => handleChangePs('open', i, true)}
                    >
                      Ver-Editar Tasas
                    </button>
                    <PrestamoTasasModal
                      open={pre.open || false}
                      tasas={pre.tasas}
                      setOpen={(v) => handleChangePs('open', i, v)}
                      setTasas={(v) => {
                        handleChangePs('tasas', i, v);
                        handleChangePs('rw_tasas', i, true);
                      }}
                      monedas={monedas}
                    />
                  </td>

                  <td className="text-center">
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() => eliminarPrestamo(i)}
                      title="Eliminar préstamo."
                    >
                      <i className="fas fa-fw fa-trash-alt"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* nombre del prestamo */}
        <div className="mb-3 w-100">
          <h5 className="font-weight-regular mb-2">Mensaje Calculadora</h5>
          <div className="form-group">
            <input
              type="text"
              className={`form-control ${errores['mensaje_calculadora'] ? 'is-invalid' : ''}`}
              value={mensajeCalculadora || ''}
              onChange={(e) => handleChangeMensajeCalculadora(e.target.value)}
              style={{ maxWidth: '10000px' }}
            />
          </div>
        </div>

        <div className="mb-5">
          <button className="btn btn-primary" onClick={clickGuardar}>
            <i className="fas fa-save"></i> Guardar
          </button>
          <button className="btn btn-secondary ml-5" onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left"></i> Volver
          </button>
        </div>
      </div>
    )
  );
}

export default Prestamos;
