import { useEffect, useState } from 'react';
import { validateOnInputNumber } from '../Util';
import { PatternFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

/**
 * FormDatosContacto Component
 */
function FormDatosContacto({
  form,
  updateForm,
  siguientePagina,
  selectDepartamentos,
  selectFormatosTelefono,
  enviarFormulario,
  formatoTelefonoParaguay,
  captchaRefEmpresa,
}) {
  const ciudades = [];
  for (let dep of selectDepartamentos) ciudades[dep.id] = dep.ciudades;

  const [campos, setCampos] = useState({
    direccion: form.direccion,
    numeroDomicilio: form.numeroDomicilio,
    referencia: form.referencia,
    idDepartamento: form.idDepartamento,
    idCiudad: form.idCiudad,
    barrio: form.barrio,
    idFormatoTelefono: form.idFormatoTelefono,
    telefono: form.telefono,
    email: form.email,
  });
  const [errores, setErrores] = useState({}); // form errores
  const [selectCiudades, setSelectCiudades] = useState([]);
  const [maxLengthTelefono, setMaxLengthTelefono] = useState(undefined);
  const captchaSiteKey = useSelector((state) => state.env.captchaSiteKey);

  if (maxLengthTelefono == undefined) setMaxLengthTelefono(formatoTelefonoParaguay);

  // recargar select de ciudades al retroceder en el formulario
  if (campos.idDepartamento && selectCiudades.length < 1)
    setSelectCiudades(ciudades[campos.idDepartamento]);

  const validarFormDatosContacto = () => {
    let avanzar = true;
    setErrores({});

    if (campos.direccion == '') {
      setErrores((e) => ({ ...e, direccion: 'Introduzca una direccion.' }));
      avanzar = false;
    }

    if (campos.referencia == '') {
      setErrores((e) => ({ ...e, referencia: 'Introduzca una referencia.' }));
      avanzar = false;
    }

    if (campos.idDepartamento == '') {
      setErrores((e) => ({ ...e, idDepartamento: 'Seleccione un departamento.' }));
      avanzar = false;
    }

    if (campos.idCiudad == '') {
      setErrores((e) => ({ ...e, idCiudad: 'Seleccione una ciudad.' }));
      avanzar = false;
    }

    if (campos.idFormatoTelefono == '') {
      setErrores((e) => ({ ...e, idFormatoTelefono: 'Seleccione un país.' }));
      avanzar = false;
    }

    if (campos.telefono == '') {
      setErrores((e) => ({ ...e, telefono: 'Introduzca un número de teléfono.' }));
      avanzar = false;
    } else if (campos.telefono.length > maxLengthTelefono.max_digitos) {
      setErrores((e) => ({
        ...e,
        telefono: 'El nro de teléfono es muy largo para el país seleccionado.',
      }));
      avanzar = false;
    }

    if (campos.email == '') {
      setErrores((e) => ({ ...e, email: 'Introduzca una dirección de correo electrónico.' }));
      avanzar = false;
    } else if (!campos.email.toString().includes('@')) {
      setErrores((e) => ({
        ...e,
        email: 'La dirección de correo electrónico debe contener el caracter "@".',
      }));
      avanzar = false;
    } else if (
      form.email.toString().match(/.*\@.*\.\w{2,3}/g) === null ||
      form.email.charAt(0) == '@'
    ) {
      setErrores((e) => ({
        ...e,
        email: 'Ingrese una dirección de correo electrónico válida.',
      }));
      avanzar = false;
    }

    if (campos.apellido == '') {
      setErrores((e) => ({ ...e, apellido: 'Introduzca un apellido.' }));
      avanzar = false;
    }

    if (!form.esPersona && !captchaRefEmpresa.current.getValue()) {
      setErrores((e) => ({ ...e, captcha: 'Por favor, complete el captcha.' }));
      return;
    }

    if (avanzar && !form.esPersona) return enviarFormulario();
    if (avanzar) siguientePagina();
  };

  const updateCampo = (name, value) => {
    if (name == 'idDepartamento') setCampos({ ...campos, [name]: value, idCiudad: '' });
    else if (name == 'idFormatoTelefono') setCampos({ ...campos, [name]: value, telefono: '' });
    else setCampos({ ...campos, [name]: value });
    updateForm([name], value);
  };

  return (
    <>
      <div className="form-group">
        <label htmlFor="address">
          Dirección <span className="text-danger">*</span>
        </label>
        <input
          id="address"
          type="text"
          autoComplete="off"
          className={`form-control form-control-100 ${errores.direccion ? 'is-invalid' : ''}`}
          value={campos.direccion}
          onChange={(e) => {
            updateCampo('direccion', e.target.value);
          }}
          maxLength={100}
        />
        <span className="text-danger">{errores.direccion}</span>
      </div>
      <div className="form-group">
        <label htmlFor="homenumber">Numero de domicilio</label>
        <input
          id="homenumber"
          type="text"
          autoComplete="off"
          className={`form-control form-control-100 ${errores.numeroDomicilio ? 'is-invalid' : ''}`}
          value={campos.numeroDomicilio}
          onChange={(e) => {
            updateCampo('numeroDomicilio', validateOnInputNumber(e.target.value));
          }}
          maxLength={10}
        />
        <span className="text-danger">{errores.numeroDomicilio}</span>
      </div>
      <div className="form-group">
        <label htmlFor="reference">
          Referencia <span className="text-danger">*</span>
        </label>
        <input
          id="reference"
          type="text"
          autoComplete="off"
          className={`form-control form-control-100 ${errores.referencia ? 'is-invalid' : ''}`}
          value={campos.referencia}
          onChange={(e) => {
            updateCampo('referencia', e.target.value);
          }}
          maxLength={100}
        />
        <span className="text-danger">{errores.referencia}</span>
      </div>
      <div className="form-group">
        <label htmlFor="department">
          Departamento <span className="text-danger">*</span>
        </label>
        <select
          id="department"
          className={`form-control form-control-100 ${errores.idDepartamento ? 'is-invalid' : ''}`}
          value={campos.idDepartamento}
          onChange={(e) => {
            setSelectCiudades(ciudades[e.target.value] ? ciudades[e.target.value] : []);
            updateCampo('idDepartamento', e.target.value);
          }}
        >
          <option value="">Seleccione...</option>
          {selectDepartamentos.map((departamento) => (
            <option key={departamento.id} value={departamento.id}>
              {departamento.nombre}
            </option>
          ))}
        </select>
        <span className="text-danger">{errores.idDepartamento}</span>
      </div>
      <div className="form-group">
        <label htmlFor="city">
          Ciudad <span className="text-danger">*</span>
        </label>
        <select
          id="city"
          className={`form-control form-control-100 ${errores.idCiudad ? 'is-invalid' : ''}`}
          value={campos.idCiudad}
          onChange={(e) => {
            updateCampo('idCiudad', e.target.value);
          }}
        >
          <option value="">Seleccione...</option>
          {selectCiudades.map((ciudades) => (
            <option key={ciudades.id} value={ciudades.id}>
              {ciudades.nombre}
            </option>
          ))}
        </select>
        <span className="text-danger">{errores.idCiudad}</span>
      </div>
      <div className="form-group">
        <label htmlFor="barrio">Barrio</label>
        <input
          id="barrio"
          type="text"
          autoComplete="off"
          className={`form-control form-control-100 ${errores.barrio ? 'is-invalid' : ''}`}
          value={campos.barrio}
          onChange={(e) => {
            updateCampo('barrio', e.target.value);
          }}
          maxLength={30}
        />
        <span className="text-danger">{errores.barrio}</span>
      </div>
      <div className="form-group">
        <label htmlFor="mobilePhone">
          Teléfono móvil <span className="text-danger">*</span>
        </label>
        <div style={{ display: 'flex' }}>
          <div className="d-flex">
            <select
              id="idFormatoTelefono"
              className={`form-control form-control-100 ${
                errores.idFormatoTelefono ? 'is-invalid' : ''
              }`}
              value={campos.idFormatoTelefono}
              onChange={(e) => {
                setMaxLengthTelefono(selectFormatosTelefono.find((t) => t.id == e.target.value));
                updateCampo('idFormatoTelefono', e.target.value);
              }}
              style={{ width: '10rem' }}
            >
              <option value="">Seleccione...</option>
              {selectFormatosTelefono.map((telefono) => (
                <option key={telefono.id} value={telefono.id}>
                  {`${telefono.pais} ${telefono.prefijo}`}
                </option>
              ))}
            </select>
          </div>
          <PatternFormat
            id="mobilePhone"
            value={campos.telefono}
            format={`${
              maxLengthTelefono !== undefined && maxLengthTelefono //&& form.telefono.length > 0
                ? `${maxLengthTelefono.formato}`
                : ''
            }`}
            className={`form-control form-control-100 ${errores.telefono ? 'is-invalid' : ''}`}
            autoComplete="off"
            style={{ width: '100%' }}
            onValueChange={(v, s) => updateCampo('telefono', validateOnInputNumber(v.value))}
            valueIsNumericString={true}
            placeholder={`${
              maxLengthTelefono !== undefined && maxLengthTelefono
                ? maxLengthTelefono.placeholder
                : ''
            }`}
            mask="_"
          />
        </div>
        <span className="text-danger">{errores.idFormatoTelefono}</span>
        <span className="text-danger">{errores.telefono}</span>
      </div>
      <div className="form-group">
        <label htmlFor="email">
          Correo electrónico <span className="text-danger">*</span>
        </label>
        <input
          id="email"
          type="mail"
          autoComplete="off"
          className={`form-control form-control-100 ${errores.email ? 'is-invalid' : ''}`}
          value={campos.email}
          onChange={(e) => {
            updateCampo('email', e.target.value.toLowerCase());
          }}
          maxLength={50}
        />
        <span className="text-danger">{errores.email}</span>
      </div>

      {!form.esPersona && (
        <>
          {/* captcha */}
          <ReCAPTCHA sitekey={captchaSiteKey} ref={captchaRefEmpresa} />
          <span className="text-danger">{errores.captcha}</span>
        </>
      )}

      <p className={!form.esPersona ? 'mt-5' : ''}>
        <span className="text-danger">*</span> Campos obligatorios
      </p>

      <div className="mt-4 mb-4">
        <button type="button" onClick={validarFormDatosContacto} className="btn btn-primary">
          {form.esPersona ? 'Siguiente' : 'Enviar Solicitud'}{' '}
          <i className="ml-2 fas fa-arrow-right"></i>
        </button>
      </div>
    </>
  );
}

export default FormDatosContacto;
