import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { capitalize, formatCodigo } from '../Util';

/**
 * ConfigEmailServer Component
 */
function ConfigEmailServer() {
  const [readOnly, setReadOnly] = useState(false);
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [emailConfig, setEmailConfig] = useState({}); // email config instance
  const [errores, setErrores] = useState({}); // form errores

  // handle change formulario config email
  const handleChange = (name, value) => {
    if (readOnly) {
      console.log('read only: no change');
    } else {
      setEmailConfig((config) => ({ ...config, [name]: value }));
    }
  };

  // get config email
  const getConfigEmail = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/configuraciones/grupo/email`);
      let res = response.data;
      if (res.MAIL_HOST !== undefined) {
        setEmailConfig(res);
      } else {
        toast.error('Error al obtener la configuración actual.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener la configuración actual.');
    }
    dispatch(finLoading());
  };

  // use effect get config email
  useEffect(() => {
    if (authData && authData.user.rol == 'admin') {
      getConfigEmail();
    }
  }, []);

  // store config
  const storeConfig = () => {
    dispatch(iniLoading());
    axios
      .post(`${backend}/api/configuraciones/grupo/email`, emailConfig)
      .then((response) => {
        let res = response.data;
        if (res.ok) {
          toast.success('Configuración guardada!');
        } else if (res.errores) {
          setErrores(res.errores);
        } else {
          toast.error(res.error || 'Error al guardar la configuración.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar la configuración.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // test config
  const testConfig = () => {
    dispatch(iniLoading());
    axios
      .post(`${backend}/api/configuraciones/grupo/email/test`, emailConfig)
      .then((response) => {
        let res = response.data;
        if (res.ok) {
          toast.success('Configuración correcta!');
        } else {
          toast.error(res.error || 'No se pudo probar la configuración.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('No se pudo probar la configuración.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // validar form config email
  const validarConfigEmail = () => {
    let res = true;
    setErrores({});

    if (!(emailConfig.MAIL_HOST || '').trim()) {
      setErrores((e) => ({ ...e, MAIL_HOST: 'Por favor, complete este campo.' }));
      res = false;
    }

    if (!(emailConfig.MAIL_PORT || '').trim()) {
      setErrores((e) => ({ ...e, MAIL_PORT: 'Por favor, complete este campo.' }));
      res = false;
    } else if (!Number(emailConfig.MAIL_PORT)) {
      setErrores((e) => ({ ...e, MAIL_PORT: 'El puerto debe ser numérico.' }));
      res = false;
    }

    if (!(emailConfig.MAIL_USERNAME || '').trim()) {
      setErrores((e) => ({ ...e, MAIL_USERNAME: 'Por favor, complete este campo.' }));
      res = false;
    }

    if (!(emailConfig.MAIL_PASSWORD || '').trim()) {
      setErrores((e) => ({ ...e, MAIL_PASSWORD: 'Por favor, complete este campo.' }));
      res = false;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!(emailConfig.MAIL_FROM_ADDRESS || '').trim()) {
      setErrores((e) => ({ ...e, MAIL_FROM_ADDRESS: 'Por favor, complete este campo.' }));
      res = false;
    } else if (!emailPattern.test(emailConfig.MAIL_FROM_ADDRESS)) {
      setErrores((e) => ({ ...e, MAIL_FROM_ADDRESS: 'Dirección de correo electrónico no válida.' }));
      res = false;
    }

    if (!(emailConfig.MAIL_FROM_NAME || '').trim()) {
      setErrores((e) => ({ ...e, MAIL_FROM_NAME: 'Por favor, complete este campo.' }));
      res = false;
    }

    return res;
  };

  // click test
  const clickTest = () => {
    if (validarConfigEmail()) {
      testConfig();
    }
  };

  // click guardar
  const clickGuardar = () => {
    if (validarConfigEmail()) {
      storeConfig();
    }
  };

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-5">
          <h4 className="font-weight-regular">Configuración de Servidor de Correo Electrónico</h4>
        </div>

        {/* formulario config email */}
        <div className="mb-5">
          {/* input MAIL_HOST */}
          <div className="form-group">
            <label htmlFor="MAIL_HOST">
              Host del servidor <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errores.MAIL_HOST ? 'is-invalid' : ''}`}
              id="MAIL_HOST"
              value={emailConfig.MAIL_HOST || ''}
              onChange={(e) => handleChange('MAIL_HOST', (e.target.value))}
            />
            <span className="text-danger">{errores.MAIL_HOST}</span>
          </div>

          {/* input MAIL_PORT */}
          <div className="form-group">
            <label htmlFor="MAIL_PORT">
              Puerto del servidor <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errores.MAIL_PORT ? 'is-invalid' : ''}`}
              id="MAIL_PORT"
              value={emailConfig.MAIL_PORT || ''}
              onChange={(e) => handleChange('MAIL_PORT', (e.target.value))}
            />
            <span className="text-danger">{errores.MAIL_PORT}</span>
          </div>

          {/* input MAIL_USERNAME */}
          <div className="form-group">
            <label htmlFor="MAIL_USERNAME">
              Usuario <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errores.MAIL_USERNAME ? 'is-invalid' : ''}`}
              id="MAIL_USERNAME"
              value={emailConfig.MAIL_USERNAME || ''}
              onChange={(e) => handleChange('MAIL_USERNAME', (e.target.value))}
            />
            <span className="text-danger">{errores.MAIL_USERNAME}</span>
          </div>

          {/* input MAIL_PASSWORD */}
          <div className="form-group">
            <label htmlFor="MAIL_PASSWORD">
              Contraseña <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errores.MAIL_PASSWORD ? 'is-invalid' : ''}`}
              id="MAIL_PASSWORD"
              value={emailConfig.MAIL_PASSWORD || ''}
              onChange={(e) => handleChange('MAIL_PASSWORD', (e.target.value))}
            />
            <span className="text-danger">{errores.MAIL_PASSWORD}</span>
          </div>

          {/* input MAIL_ENCRYPTION */}
          <div className="form-group">
            <label htmlFor="MAIL_ENCRYPTION">Encriptación de correo</label>
            <select
              id="MAIL_ENCRYPTION"
              className={`form-control ${errores.MAIL_ENCRYPTION ? 'is-invalid' : ''}`}
              value={emailConfig.MAIL_ENCRYPTION || ''}
              onChange={(e) => handleChange('MAIL_ENCRYPTION', e.target.value)}
            >
              <option value="">Ninguno</option>
              <option value="tls">TLS</option>
              <option value="ssl">SSL</option>
            </select>
            <span className="text-danger">{errores.MAIL_ENCRYPTION}</span>
          </div>

          {/* input MAIL_FROM_ADDRESS */}
          <div className="form-group">
            <label htmlFor="MAIL_FROM_ADDRESS">
              Email de remitente <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errores.MAIL_FROM_ADDRESS ? 'is-invalid' : ''}`}
              id="MAIL_FROM_ADDRESS"
              value={emailConfig.MAIL_FROM_ADDRESS || ''}
              onChange={(e) => handleChange('MAIL_FROM_ADDRESS', (e.target.value))}
            />
            <span className="text-danger">{errores.MAIL_FROM_ADDRESS}</span>
          </div>

          {/* input MAIL_FROM_NAME */}
          <div className="form-group">
            <label htmlFor="MAIL_FROM_NAME">
              Nombre de remitente <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errores.MAIL_FROM_NAME ? 'is-invalid' : ''}`}
              id="MAIL_FROM_NAME"
              value={emailConfig.MAIL_FROM_NAME || ''}
              onChange={(e) => handleChange('MAIL_FROM_NAME', capitalize(e.target.value))}
            />
            <span className="text-danger">{errores.MAIL_FROM_NAME}</span>
          </div>

          {/* botones formulario */}
          <div>
            <button className="btn btn-primary" onClick={clickTest}>
              Probar configuración
            </button>
            <button className="btn btn-primary ml-5" onClick={clickGuardar}>
              <i className="fas fa-save"></i> Guardar configuración
            </button>
            <button className="btn btn-secondary ml-5" onClick={() => navigate('/admin')}>
              <i className="fas fa-arrow-left"></i> Volver
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ConfigEmailServer;
