import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

/**
 * SucursalCorreo Form Component
 */
function SucursalCorreo({ sucursal, setSucursal, errores, readOnly }) {
  const [toggleAgregarCorreo, setToggleAgregarCorreo] = useState(null);

  // handle change formulario sucursal correo
  const handleChangeCorreo = (index, name, value) => {
    if (readOnly) {
      console.log('read only: no change');
    } else {
      setSucursal(s => {
        let sChange = { ...s };
        sChange.correos[index][name] = (value || '').trim();
        return sChange;
      });
    }
  };

  // agregar un correo a la lista
  const agregarCorreo = () => {
    // evitar agregar input vacio repetidamente
    if (sucursal.correos[sucursal.correos.length - 1].email) {
      // agregar correo a la lista
      setSucursal(s => ({
        ...s,
        correos: [
          ...s.correos,
          {
            email: ''
          }
        ]
      }));
    }
    setToggleAgregarCorreo(toggle => !toggle);
  }

  const focusUltimoInputCorreo = () => {
    const el = document.getElementById(`correos.${sucursal.correos.length - 1}.email`);
    if (el) el.focus();
  }

  useEffect(() => {
    if (toggleAgregarCorreo !== null) {
      focusUltimoInputCorreo();
    }
  }, [toggleAgregarCorreo]);

  return (
    <div className="form-group">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <label htmlFor="correos.0.email">
          Email
        </label>
        <label onClick={agregarCorreo} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
          Agregar correo
        </label>
      </div>
      {sucursal.correos.map((correo, index) =>
        <div key={`correos.${index}.email`} className={index > 0 ? 'mt-2' : ''}>
          <input
            type="text"
            name={`correos.${index}.email`}
            className={
              `form-control form-control-100 ${errores[`correos.${index}.email`] ? 'is-invalid' : ''}`
            }
            id={`correos.${index}.email`}
            autoComplete="off"
            value={correo.email}
            onChange={(e) => handleChangeCorreo(index, `email`, e.target.value)}
            style={{ width: '100%' }}
          />
          <span className="text-danger">{errores[`correos.${index}.email`]}</span>
        </div>
      )}
    </div>
  );
}

// Typechecking props of the component
SucursalCorreo.propTypes = {
  sucursal: PropTypes.shape({
    correos: PropTypes.array.isRequired,
  }).isRequired,
  setSucursal: PropTypes.func.isRequired,
  errores: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default SucursalCorreo;
