import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

import { NumericFormat, PatternFormat } from 'react-number-format';
import { validateOnInputNumber } from '../Util';

/**
 * FormRRHH Component
 */
function FormRRHH() {
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const captchaSiteKey = useSelector((state) => state.env.captchaSiteKey);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const captchaRef = useRef(null);

  const { id } = useParams(); // id de la pagina, null al crear

  const formInitialState = {
    numeroDocumento: '',
    nombre: '',
    apellido: '',
    fechaNacimiento: '',
    genero: '',
    estadoCivil: '',
    nacionalidad: '',
    idFormatoTelefono: '',
    telefono: '',
    email: '',
    urlCV: '',
    file: '',
  };

  const [form, setForm] = useState(formInitialState);
  const [file, setFile] = useState('');
  // const [sendForm, setSendForm] = useState(false);
  const [errores, setErrores] = useState({});
  const [maxLengthTelefono, setMaxLengthTelefono] = useState(undefined);
  const [selectFormatosTelefono, setSelectFormatosTelefono] = useState([]);
  const [extensionComprobanteIngreso, setExtensionComprobanteIngreso] = useState({});
  const [formatoTelefonoParaguay, setFormatoTelefonoParaguay] = useState(null);

  if (maxLengthTelefono == undefined && formatoTelefonoParaguay)
    setMaxLengthTelefono(formatoTelefonoParaguay);

  const validarFormRRHH = () => {
    let enviar = true;
    setErrores({});

    if (!form.numeroDocumento.trim()) {
      setErrores((e) => ({ ...e, numeroDocumento: 'Ingrese su número de documento.' }));
      enviar = false;
    }

    if (!form.nombre.trim()) {
      setErrores((e) => ({ ...e, nombre: 'Ingrese su nombre.' }));
      enviar = false;
    }

    if (!form.apellido.trim()) {
      setErrores((e) => ({ ...e, apellido: 'Ingrese su apellido.' }));
      enviar = false;
    }

    if (!form.fechaNacimiento.trim()) {
      setErrores((e) => ({ ...e, fechaNacimiento: 'Ingrese su fecha de nacimiento.' }));
      enviar = false;
    }

    if (!form.genero.trim()) {
      setErrores((e) => ({ ...e, genero: 'Ingrese su género.' }));
      enviar = false;
    }

    if (!form.estadoCivil.trim()) {
      setErrores((e) => ({ ...e, estadoCivil: 'Ingrese su estado civil.' }));
      enviar = false;
    }

    if (!form.nacionalidad.trim()) {
      setErrores((e) => ({ ...e, nacionalidad: 'Ingrese su nacionalidad.' }));
      enviar = false;
    }

    if (form.idFormatoTelefono == '') {
      setErrores((e) => ({ ...e, idFormatoTelefono: 'Seleccione un país.' }));
      enviar = false;
    }

    if (form.telefono == '') {
      setErrores((e) => ({ ...e, telefono: 'Introduzca un número de teléfono.' }));
      enviar = false;
    } else if (form.telefono.length > maxLengthTelefono.max_digitos) {
      setErrores((e) => ({
        ...e,
        telefono: 'El nro de teléfono es muy largo para el país seleccionado.',
      }));
      enviar = false;
    }

    if (!form.email.trim()) {
      setErrores((e) => ({ ...e, email: 'Ingrese una dirección de correo electrónico.' }));
      enviar = false;
    } else if (!form.email.toString().includes('@')) {
      setErrores((e) => ({
        ...e,
        email: 'La dirección de correo electrónico debe contener el caracter "@".',
      }));
      enviar = false;
      // } else if (!form.email.toString().includes('.COM')) {
    } else if (
      form.email.toString().match(/.*\@.*\.\w{2,3}/g) === null ||
      form.email.charAt(0) == '@'
    ) {
      setErrores((e) => ({
        ...e,
        // email: 'La dirección de correo electrónico debe contener ".COM".',
        email: 'Ingrese una dirección de correo electrónico válida.',
      }));
      enviar = false;
    }

    if (!file) {
      setErrores((e) => ({ ...e, file: 'Adjunte su currículum vitae.' }));
      enviar = false;
    } else if (extensionComprobanteIngreso != 'pdf' && extensionComprobanteIngreso != 'jpg') {
      setErrores((e) => ({
        ...e,
        file: 'El formato del archivo debe ser "pdf" o "jpg".',
      }));
      enviar = false;
    }

    if (!captchaRef.current.getValue()) {
      setErrores((e) => ({ ...e, captcha: 'Por favor, complete el captcha.' }));
      return;
    }

    if (enviar) enviarFormulario(); //uploadFile(file, '/cv');
  };

  const enviarFormulario = () => {
    dispatch(iniLoading());

    const token = captchaRef.current.getValue();
    captchaRef.current.reset();

    let formData = new FormData();
    formData.append('numeroDocumento', form.numeroDocumento);
    formData.append('nombre', form.nombre);
    formData.append('apellido', form.apellido);
    formData.append('fechaNacimiento', form.fechaNacimiento);
    formData.append('genero', form.genero);
    formData.append('estadoCivil', form.estadoCivil);
    formData.append('nacionalidad', form.nacionalidad);
    formData.append('idFormatoTelefono', form.idFormatoTelefono);
    formData.append('telefono', form.telefono);
    formData.append('email', form.email);
    formData.append('archivo', file);
    formData.append('token', token);

    axios
      .post(`${backend}/api/formularios_rrhh`, formData)
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Formulario enviado!');
          setForm(formInitialState);
          setMaxLengthTelefono(undefined);
          navigate('/site/rrhh');
        } else {
          toast.error('Error al enviar formulario. Verifique los datos cargados.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al enviar el formulario.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  const onChangeForm = (name, value) => {
    if (!id)
      if (name == 'idFormatoTelefono') setForm({ ...form, [name]: value, telefono: '' });
      else setForm({ ...form, [name]: value });
  };

  // get user by id
  const getFormRRHH = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/formularios_rrhh/${id}`);
      let res = response.data;
      if (res.id) {
        setForm(res);
      } else {
        toast.error('Error al obtener el formulario.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener el formulario.');
    }
    dispatch(finLoading());
  };

  const getFormatosTelefono = async () => {
    // dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/formatosTelefono`);
      let res = response.data;
      setFormatoTelefonoParaguay(
        res.filter((ft) => ft.codigo_pais == 'PY').length > 0
          ? res.filter((ft) => ft.codigo_pais == 'PY')[0]
          : null
      );
      if (Array.isArray(res)) {
        setSelectFormatosTelefono(res);
      } else {
        setSelectFormatosTelefono([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener formatos de telefono.');
    }
    // dispatch(finLoading());
  };

  // use effect get formulario rrhh
  useEffect(() => {
    if (id) {
      if (authData && (authData.user.rol == 'admin' || authData.user.rol == 'rrhh')) getFormRRHH();
    } else {
      getFormatosTelefono();
    }
  }, [id]);

  // enviar formulario cuando se tenga la url
  // useEffect(() => {
  //   if (sendForm) enviarFormulario();
  // }, [sendForm]);

  useEffect(() => {
    if (formatoTelefonoParaguay != null) {
      setForm({
        ...form,
        idFormatoTelefono: formatoTelefonoParaguay.id,
      });
    }
  }, [formatoTelefonoParaguay]);

  return (
    <div className="modal">
      <div className="modal-header">
        <h4 className="font-weight-regular mb-2">Trabajá con nosotros</h4>
        <h1>Contanos sobre vos</h1>
      </div>

      <div className="modal-body">
        <div id="seccion-impresion">
          <div className="form-group">
            <label htmlFor="document">
              Nro. de Documento <span className="text-danger">*</span>
            </label>
            <NumericFormat
              id="document"
              value={form.numeroDocumento || ''}
              onValueChange={(v, s) => onChangeForm('numeroDocumento', v.value)}
              valueIsNumericString={true}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              decimalScale={0}
              className={`form-control form-control-100 text-left ${
                errores.numeroDocumento ? 'is-invalid' : ''
              }`}
              autoComplete="off"
              disabled={id}
              maxLength={10}
            />
            <span className="text-danger">{errores.numeroDocumento}</span>
          </div>
          <div className="form-group">
            <label htmlFor="Nombre">
              Nombre <span className="text-danger">*</span>
            </label>
            <input
              id="Nombre"
              type="text"
              className={`form-control form-control-100 ${errores.nombre ? 'is-invalid' : ''}`}
              value={form.nombre}
              onChange={(e) => onChangeForm('nombre', e.target.value)}
              autoComplete="off"
              maxLength="100"
              disabled={id}
            />
            <span className="text-danger">{errores.nombre}</span>
          </div>
          <div className="form-group">
            <label htmlFor="Apellido">
              Apellido <span className="text-danger">*</span>
            </label>
            <input
              id="Apellido"
              type="text"
              className={`form-control form-control-100 ${errores.apellido ? 'is-invalid' : ''}`}
              value={form.apellido}
              onChange={(e) => onChangeForm('apellido', e.target.value)}
              autoComplete="off"
              maxLength="100"
              disabled={id}
            />
            <span className="text-danger">{errores.apellido}</span>
          </div>
          <div className="form-group">
            <label htmlFor="fecha-nacimiento">
              Fecha de Nacimiento <span className="text-danger">*</span>
            </label>
            <input
              id="fecha-nacimiento"
              type="date"
              className={`form-control form-control-100 ${
                errores.fechaNacimiento ? 'is-invalid' : ''
              }`}
              value={form.fechaNacimiento}
              onChange={(e) => onChangeForm('fechaNacimiento', e.target.value)}
              disabled={id}
            />
            <span className="text-danger">{errores.fechaNacimiento}</span>
          </div>
          <div className="form-group">
            <label htmlFor="Genero">
              Genero <span className="text-danger">*</span>
            </label>
            <select
              id="Genero"
              className={`form-control form-control-100 ${errores.genero ? 'is-invalid' : ''}`}
              value={form.genero}
              onChange={(e) => {
                onChangeForm('genero', e.target.value);
              }}
              disabled={id}
            >
              <option value="">Selecione...</option>
              <option value="MASCULINO">Masculino</option>
              <option value="FEMENINO">Femenino</option>
              <option value="NO ESPECIFICAR">No especificar</option>
            </select>
            <span className="text-danger">{errores.genero}</span>
          </div>
          <div className="form-group">
            <label htmlFor="Estado">
              Estado Civil <span className="text-danger">*</span>
            </label>
            <select
              id="Estado"
              className={`form-control form-control-100 ${errores.estadoCivil ? 'is-invalid' : ''}`}
              value={form.estadoCivil}
              onChange={(e) => {
                onChangeForm('estadoCivil', e.target.value);
              }}
              disabled={id}
            >
              <option value="">Selecione...</option>
              <option value="SOLTERO/A">Soltero/a</option>
              <option value="CASADO/A">Casado/a</option>
              <option value="DIVORCIADO/A">Divorciado/a</option>
              <option value="VIUDO/A">Viudo/a</option>
            </select>
            <span className="text-danger">{errores.estadoCivil}</span>
          </div>
          <div className="form-group">
            <label htmlFor="nacionalidad">
              Nacionalidad <span className="text-danger">*</span>
            </label>
            <input
              id="nacionalidad"
              type="text"
              className={`form-control form-control-100 ${
                errores.nacionalidad ? 'is-invalid' : ''
              }`}
              value={form.nacionalidad}
              onChange={(e) => onChangeForm('nacionalidad', e.target.value)}
              autoComplete="off"
              maxLength="30"
              disabled={id}
            />
            <span className="text-danger">{errores.nacionalidad}</span>
          </div>
          <div className="form-group">
            <label htmlFor="mobilePhone">
              Teléfono móvil <span className="text-danger">*</span>
            </label>
            <div style={{ display: 'flex' }}>
              {id && (
                <>
                  <input
                    type="text"
                    autoComplete="off"
                    className={`form-control form-control-100`}
                    value={form.formato_telefono ? form.formato_telefono.pais : ''}
                    onChange={(e) => {
                      onChangeForm('pais', e.target.value);
                    }}
                    style={{ width: '40%' }}
                    disabled
                  />
                  <input
                    type="text"
                    autoComplete="off"
                    className={`form-control form-control-100`}
                    value={form.formato_telefono ? form.formato_telefono.prefijo : ''}
                    onChange={(e) => {
                      onChangeForm('prefijo', e.target.value);
                    }}
                    style={{ width: '30%' }}
                    disabled
                  />
                  <input
                    id="mobilePhone"
                    type="text"
                    autoComplete="off"
                    className={`form-control form-control-100`}
                    value={form.telefono}
                    onChange={(e) => {
                      onChangeForm('telefono', e.target.value);
                    }}
                    style={{ width: '100%' }}
                    disabled
                  />
                </>
              )}
              {!id && (
                <>
                  <select
                    id="idFormatoTelefono"
                    className={`form-control form-control-100 ${
                      errores.idFormatoTelefono ? 'is-invalid' : ''
                    }`}
                    value={form.idFormatoTelefono}
                    onChange={(e) => {
                      setMaxLengthTelefono(
                        selectFormatosTelefono.find((t) => t.id == e.target.value)
                      );
                      onChangeForm('idFormatoTelefono', e.target.value);
                    }}
                    style={{ width: '10rem' }}
                    disabled={id}
                  >
                    <option value="">Seleccione...</option>
                    {selectFormatosTelefono.map((telefono) => (
                      <option key={telefono.id} value={telefono.id}>
                        {`${telefono.pais} ${telefono.prefijo}`}
                      </option>
                    ))}
                  </select>
                  <PatternFormat
                    id="mobilePhone"
                    value={form.telefono}
                    format={`${
                      maxLengthTelefono !== undefined && maxLengthTelefono //&& form.telefono.length > 0
                        ? `${maxLengthTelefono.formato}`
                        : ''
                    }`}
                    className={`form-control form-control-100 ${
                      errores.telefono ? 'is-invalid' : ''
                    }`}
                    autoComplete="off"
                    style={{ width: '100%' }}
                    onValueChange={(v, s) =>
                      onChangeForm('telefono', validateOnInputNumber(v.value))
                    }
                    valueIsNumericString={true}
                    placeholder={`${
                      maxLengthTelefono !== undefined && maxLengthTelefono
                        ? maxLengthTelefono.placeholder
                        : ''
                    }`}
                    mask="_"
                    disabled={id}
                  />
                </>
              )}
            </div>
            <span className="text-danger">{errores.idFormatoTelefono}</span>
            <span className="text-danger">{errores.telefono}</span>
          </div>
          <div className="form-group">
            <label htmlFor="email">
              Email <span className="text-danger">*</span>
            </label>
            <input
              id="email"
              type="email"
              className={`form-control form-control-100 ${errores.email ? 'is-invalid' : ''}`}
              value={form.email}
              onChange={(e) => onChangeForm('email', e.target.value.toLowerCase())}
              autoComplete="off"
              maxLength="50"
              disabled={id}
            />
            <span className="text-danger">{errores.email}</span>
          </div>
        </div>
        {!id && (
          <div className="form-group">
            <label htmlFor="cv">
              Adjuntar CV <span className="text-danger">*</span>
            </label>
            <input
              id="cv"
              type="file"
              className={`form-control form-control-100 ${errores.file ? 'is-invalid' : ''}`}
              value={form.file}
              onChange={(e) => {
                let auxExtension = e.target.files[0].name.split('.');
                setExtensionComprobanteIngreso(auxExtension[auxExtension.length - 1]);
                setFile(e.target.files[0]);
                onChangeForm('file', e.target.value);
              }}
              autoComplete="off"
            />
            <span className="text-danger">{errores.file}</span>
          </div>
        )}
        {id && (
          <>
            <a
              className="btn btn-primary"
              href={`${backend}/api/archivo?url=${form.urlCV}`}
              target="_blank"
            >
              Ver curriculum
            </a>
            <a
              className="btn btn-primary ml-5"
              href={`${backend}/api/export_formularios_rrhh/${form.id}`}
              target="_blank"
            >
              <i className="fas fa-print"></i> Imprimir
            </a>
            <button className="btn btn-secondary ml-5" onClick={() => navigate('/admin/rrhh')}>
              <i className="fas fa-arrow-left"></i> Volver
            </button>
          </>
        )}
        {!id && (
          <>
            {/* captcha */}
            <ReCAPTCHA sitekey={captchaSiteKey} ref={captchaRef} />
            <span className="text-danger">{errores.captcha}</span>
            <p className="mt-5">
              <span className="text-danger">*</span> Campos obligatorios
            </p>
            <div className="form-group" style={{ marginBottom: '0px', marginTop: '1em' }}>
              <button type="button" className="btn btn-primary" onClick={(e) => validarFormRRHH()}>
                Enviar datos
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default FormRRHH;
