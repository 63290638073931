import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AdminHeader from './AdminHeader';

function AdminLayout() {
  const authData = useSelector((state) => state.auth.value);
  const location = useLocation();
  const navigate = useNavigate();

  // CHEQUEO DE LOGIN para paginas
  useEffect(() => {
    if (authData && authData.token && authData.user) {
      console.log('auth ok');
    } else {
      toast.warning('Debe iniciar sesión!');
      navigate('/login');
    }
  }, [location]);

  return (
    <>
      <AdminHeader />
      <div>
        <Outlet />
      </div>
    </>
  );
}

export default AdminLayout;
