import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import LocationPicker from '../LocationPicker';
import SucursalCorreo from './SucursalCorreo';

function SucursalInstance() {
  const [readOnly, setReadOnly] = useState(false);
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // centro para que al inicio muestre asunción
  let centroInicial = {
    lat: -25.307212,
    lng: -57.521618,
  };

  const { id } = useParams(); // id de la sucursal, null al crear
  const [sucursal, setSucursal] = useState({
    nombre: '',
    lat: centroInicial.lat,
    lng: centroInicial.lng,
    direccion: '',
    telefono: '',
    visible: true,
    nombreFormulario: '',
    urlImagenSucursal: '',
    correos: [{ email: '' }],
  }); // sucursal instance

  // para coordenada inicial del componente mapa
  const [centroSucursal, setCentroSucursal] = useState({
    lat: centroInicial.lat,
    lng: centroInicial.lng,
  });

  const [errores, setErrores] = useState({}); // form errores

  // handle change formulario sucursal
  const handleChange = (name, value) => {
    if (readOnly) {
      console.log('read only: no change');
    } else {
      setSucursal({ ...sucursal, [name]: value });
    }
  };

  // get sucursal by id
  const getSucursal = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/sucursales/${id}`);
      let res = response.data;

      if (!res.id) {
        toast.error('Error al obtener la página.');
        return;
      }

      if (res.correos.length === 0) {
        res.correos.push({ email: '' }); // default
      }

      setSucursal(res);
      setCentroSucursal({ lat: Number(res.lat), lng: Number(res.lng) });
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener la página.');
    } finally {
      dispatch(finLoading());
    }
  };

  // use effect get sucursal
  useEffect(() => {
    if (id && authData && authData.user.rol == 'admin') getSucursal();
  }, [id]);

  // store new sucursal
  const storeSucursal = () => {
    dispatch(iniLoading());
    axios
      .post(`${backend}/api/sucursales`, sucursal)
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Nueva sucursal guardada!');
          navigate('/admin/sucursales/' + res.id);
        } else if (res.errores) {
          setErrores(res.errores);
        } else {
          toast.error(res.error || 'Error al guardar la sucursal.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar la sucursal.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // update current sucursal
  const updateSucursal = () => {
    dispatch(iniLoading());
    axios
      .put(`${backend}/api/sucursales/${sucursal.id}`, sucursal)
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Sucursal guardada!');
          getSucursal(); // refresh
        } else if (res.errores) {
          setErrores(res.errores);
        } else {
          toast.error(res.error || 'Error al guardar la sucursal.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar la sucursal.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // validar form sucursal
  const validarSucursal = () => {
    let res = true;
    setErrores({});

    if (!sucursal.nombre.trim()) {
      setErrores((e) => ({ ...e, nombre: 'Ingrese el nombre de la sucursal.' }));
      res = false;
    }

    if (!sucursal.nombreFormulario.trim()) {
      setErrores((e) => ({
        ...e,
        nombreFormulario:
          'Ingrese el nombre de la sucursal para el formulario de solicitud de producto.',
      }));
      res = false;
    }

    // validar correos
    sucursal.correos.forEach((correo, index) => {
      // no es obligatorio
      if (!correo.email) {
        return;
      }

      // validar direccion valida
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailPattern.test(correo.email)) {
        setErrores((e) => ({
          ...e,
          [`correos.${index}.email`]: 'Dirección de correo electrónico no válida.',
        }));
        res = false;
        return;
      }

      // validar direccion no repetida
      sucursal.correos.forEach((correoJ, indexJ) => {
        if (indexJ < index && correo.email == correoJ.email) {
          setErrores((e) => ({
            ...e,
            [`correos.${index}.email`]: 'Dirección de correo electrónico repetida.',
          }));
          res = false;
          return;
        }
      });
    });

    return res;
  };

  // upload file and set url
  const uploadFile = (name, e, msg) => {
    if (e.target.files.length == 1) {
      let file = e.target.files[0];
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };

      let formData = new FormData();
      formData.append('archivo', file);

      dispatch(iniLoading());
      axios
        .post(`${backend}/api/componentes`, formData, config)
        .then((response) => {
          let res = response.data;
          if (res.ok) {
            toast.success(msg || 'Archivo subido.');
            // handleChangeFdin(name, res.url);
            setSucursal({ ...sucursal, urlImagenSucursal: res.url });
          } else {
            toast.error(res.error || 'No se pudo subir el archivo.');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('No se pudo subir el archivo.');
        })
        .finally(() => dispatch(finLoading()));
    } else {
      setSucursal({ ...sucursal, urlImagenSucursal: '' });
    }
  };

  // click guardar
  const clickGuardar = () => {
    if (validarSucursal()) {
      if (id == null) {
        storeSucursal();
      } else {
        updateSucursal();
      }
    }
  };

  const setCoordenadas = (coordenada) => {
    if (coordenada) {
      let c = coordenada.toJSON();
      setSucursal({ ...sucursal, lat: c.lat, lng: c.lng });
    }
  };

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-5">
          {id == null ? (
            <h4 className="font-weight-regular">Nueva Sucursal</h4>
          ) : (
            <h4 className="font-weight-regular">Editar Sucursal</h4>
          )}
        </div>

        {/* formulario sucursales */}
        <div className="mb-5">
          {/* input nombre */}
          <div className="form-group">
            <label htmlFor="nombre">Nombre</label>
            <input
              type="text"
              className={`form-control form-control-100 ${errores.nombre ? 'is-invalid' : ''}`}
              id="nombre"
              autoComplete="off"
              value={sucursal.nombre}
              onChange={(e) => handleChange('nombre', e.target.value)}
            />
            <span className="text-danger">{errores.nombre}</span>
          </div>

          {/* input nombreFormulario */}
          <div className="form-group">
            <label htmlFor="nombreFormulario">Nombre Formulario</label>
            <input
              type="text"
              className={
                `form-control form-control-100 ${errores.nombreFormulario ? 'is-invalid' : ''}`
              }
              id="nombreFormulario"
              autoComplete="off"
              value={sucursal.nombreFormulario}
              onChange={(e) => handleChange('nombreFormulario', e.target.value)}
            />
            <span className="text-danger">{errores.nombreFormulario}</span>
          </div>

          {/* selector de ubicación */}
          <div className="form-group">
            <label htmlFor="ubicacion">Ubicación</label>
            <LocationPicker
              id="ubicacion"
              coordenadaInicial={centroSucursal}
              setCoordenadas={setCoordenadas}
            />
          </div>

          {/* input direccion */}
          <div className="form-group">
            <label htmlFor="direccion">Dirección</label>
            <input
              type="text"
              className={`form-control form-control-100 ${errores.direccion ? 'is-invalid' : ''}`}
              id="direccion"
              autoComplete="off"
              value={sucursal.direccion}
              onChange={(e) => handleChange('direccion', e.target.value)}
            />
            <span className="text-danger">{errores.direccion}</span>
          </div>
          {/* input telefono */}
          <div className="form-group">
            <label htmlFor="telefono">Teléfono</label>
            <input
              type="text"
              className={`form-control form-control-100 ${errores.telefono ? 'is-invalid' : ''}`}
              id="telefono"
              autoComplete="off"
              value={sucursal.telefono}
              onChange={(e) => handleChange('telefono', e.target.value)}
            />
            <span className="text-danger">{errores.telefono}</span>
          </div>
          {/* lista email */}
          <SucursalCorreo
            sucursal={sucursal}
            setSucursal={setSucursal}
            errores={errores}
            readOnly={readOnly}
          />
          {/* imagen */}
          <div className="form-group">
            <label htmlFor="urlImagenSucursal">Imagen sucursal</label>
            <input
              id="urlImagenSucursal"
              type="file"
              accept="image/png, image/jpeg"
              className={
                `form-control form-control-100 ${errores.urlImagenSucursal ? 'is-invalid' : ''}`
              }
              onChange={(e) => uploadFile('urlImagenSucursal', e, 'Imagen subida.')}
            />
            {sucursal.urlImagenSucursal && (
              <span className="text-muted">
                <a
                  href={`${backend}${sucursal.urlImagenSucursal}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-muted"
                  style={{ fontFamily: 'Arial, sans-serif' }}
                >
                  Actual: {`${backend}${sucursal.urlImagenSucursal}`}
                </a>
              </span>
            )}
          </div>
          {/* checkbox visible */}
          {id != null && (
            <>
              <div className="form-group">
                <label htmlFor="visibleCheckbox">
                  <input
                    type="checkbox"
                    id="visibleCheckbox"
                    checked={sucursal.visible}
                    onChange={(e) => {
                      handleChange('visible', e.target.checked);
                    }}
                  />
                  Visible
                </label>
              </div>
            </>
          )}
          {/* btn guardar */}
          <div>
            <button className="btn btn-primary" onClick={clickGuardar}>
              <i className="fas fa-save"></i> Guardar
            </button>
            <button
              className="btn btn-secondary ml-5"
              onClick={() => navigate('/admin/sucursales')}
            >
              <i className="fas fa-arrow-left"></i> Volver
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default SucursalInstance;
