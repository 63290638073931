import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { formatNumero } from '../Util';
import { NumericFormat } from 'react-number-format';

Modal.setAppElement('#root');
const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '1200px',
    maxHeight: '80%',
  },
};

/**
 * modal: tasas de un prestamo
 */
function PrestamoTasasModal({ open, tasas, setOpen, setTasas, monedas }) {
  const [listaTasas, setListaTasas] = useState([]);
  const [errores, setErrores] = useState([]);

  // handle input tasas
  const handleChangeTs = (name, index, value) => {
    setListaTasas((ps) => {
      ps[index][name] = value;
      return [...ps];
    });
  };

  // inicializador del modal
  useEffect(() => {
    if (open && Array.isArray(tasas)) {
      let aux = JSON.parse(JSON.stringify(tasas)); // clonar para no afectar hasta guardar
      // aux.forEach((pt) => (pt.tasa = parseInt(pt.tasa).toString()));
      setListaTasas(aux);
      setErrores({});
    }
  }, [open, tasas]);

  // agregar tasa
  const aggTasa = (i) => {
    setListaTasas((l) => [
      ...l,
      {
        monto_max: '',
        id_moneda: '',
        tasa: '',
        tasa_impuesto_interes: '',
        tasa_seguro_prestamo: '',
        monto_gastos_administrativos: '',
        porcentaje_gastos_administrativos: '',
      },
    ]);
  };

  // eliminar tasa
  const rmTasa = (i) => {
    setListaTasas((l) => {
      l.splice(i, 1);
      return [...l];
    });
  };

  const validar = () => {
    let res = true;
    let errs = {};

    listaTasas.forEach((t, i) => {
      if (!t.monto_max) {
        res = false;
        errs['monto_max' + i] = 'Ingrese el monto máximo.';
      } else if (t.monto_max <= 0) {
        res = false;
        errs['monto_max' + i] = 'Debe ser mayor a 0';
      }

      if (!t.id_moneda) {
        res = false;
        errs['moneda' + i] = 'Seleccione la moneda.';
      }

      if (!t.tasa) {
        res = false;
        errs['tasa' + i] = 'Ingrese la tasa.';
      } else if (t.tasa < 0 || t.tasa > 100) {
        res = false;
        errs['tasa' + i] = 'Debe estar entre 0 y 100';
      }

      if (!t.tasa_impuesto_interes) {
        res = false;
        errs['tasa_impuesto_interes' + i] = 'Ingrese el porcentaje del impuesto sobre el interés.';
      } else if (t.tasa_impuesto_interes < 0) {
        res = false;
        errs['tasa_impuesto_interes' + i] = 'Debe ser mayor o igual a 0';
      }

      if (!t.tasa_seguro_prestamo) {
        res = false;
        errs['tasa_seguro_prestamo' + i] = 'Ingrese el porcentaje del seguro del prestamo.';
      } else if (t.tasa_seguro_prestamo < 0) {
        res = false;
        errs['tasa_seguro_prestamo' + i] = 'Debe ser mayor o igual a 0';
      }

      if (!t.monto_gastos_administrativos && t.monto_gastos_administrativos !== 0) {
        res = false;
        errs['monto_gastos_administrativos' + i] = 'Ingrese el monto de gastos administrativos.';
      } else if (t.monto_gastos_administrativos < 0) {
        res = false;
        errs['monto_gastos_administrativos' + i] = 'Debe ser mayor o igual a 0';
      }

      if (!t.porcentaje_gastos_administrativos && t.monto_gastos_administrativos !== 0.0) {
        res = false;
        errs['porcentaje_gastos_administrativos' + i] =
          'Ingrese el porcentaje de gastos administrativos.';
      } else if (t.porcentaje_gastos_administrativos < 0) {
        res = false;
        errs['porcentaje_gastos_administrativos' + i] = 'Debe ser mayor o igual a 0.0';
      }
    });

    setErrores(errs);
    return res;
  };

  const clickGuardar = () => {
    if (validar()) {
      setTasas(listaTasas); // parent callback
      setOpen(false);
      toast.success('Listo. Ahora recuerde guardar los préstamos!');
    } else {
      toast.error('Por favor, corrija el formulario.');
    }
  };

  return (
    <div>
      <Modal
        isOpen={open}
        onAfterOpen={() => {}}
        onRequestClose={() => setOpen(false)}
        style={modalStyle}
        contentLabel="Example Modal"
      >
        <div className="d-flex content-between items-center mb-3">
          <h4 className="font-weight-regular">Tasas del Préstamo</h4>
          <div>
            <button className="btn btn-sm btn-primary ml-3" onClick={aggTasa}>
              <i className="fas fa-fw fa-plus"></i> Agg. Tasa
            </button>
            <button
              className="btn btn-sm btn-outline-secondary ml-3"
              onClick={() => setOpen(false)}
            >
              <i className="fas fa-fw fa-times"></i>
            </button>
          </div>
        </div>
        <hr className="mb-5" />

        <div className="table-responsive mb-5">
          <table className="table-striped">
            <tbody>
              <tr>
                <th className="text-center">Monto máximo para tasa</th>
                <th className="text-center">Moneda</th>
                <th className="text-center">Tasa</th>
                <th className="text-center">Tasa Impuesto Interés</th>
                <th className="text-center">Tasa Seguro</th>
                <th className="text-center">Monto Gastos Administ.</th>
                <th className="text-center">Tasa Extra Sobre Monto</th>
                <th></th>
              </tr>
              {listaTasas.map((t, i) => (
                <tr key={i}>
                  {/* monto maximo de la tasa */}
                  <td className="text-center">
                    <NumericFormat
                      value={t.monto_max || ''}
                      onValueChange={(v, s) => handleChangeTs('monto_max', i, v.value)}
                      valueIsNumericString={true}
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={0}
                      className={`form-control text-right ${
                        errores['monto_max' + i] ? 'is-invalid' : ''
                      }`}
                      style={{ width: '10rem' }}
                    />
                    {errores['monto_max' + i] && (
                      <span className="d-block text-danger">{errores['monto_max' + i]}</span>
                    )}
                  </td>

                  {/* moneda de la tasa */}
                  <td className="text-center">
                    <select
                      className={`form-control ${errores['moneda' + i] ? 'is-invalid' : ''}`}
                      value={t.id_moneda || ''}
                      onChange={(e) => handleChangeTs('id_moneda', i, e.target.value)}
                      style={{ width: '10rem' }}
                    >
                      <option value="">Seleccione...</option>
                      {monedas.map((mo) => (
                        <option key={mo.id} value={mo.id}>
                          {mo.nombre}
                        </option>
                      ))}
                    </select>
                    {errores['moneda' + i] && (
                      <span className="d-block text-danger">{errores['moneda' + i]}</span>
                    )}
                  </td>

                  {/* porcentaje de la tasa */}
                  <td className="text-center">
                    <NumericFormat
                      value={t.tasa}
                      onValueChange={(v, s) => handleChangeTs('tasa', i, v.value)}
                      valueIsNumericString={true}
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={2}
                      suffix="%"
                      className={`form-control text-right ${
                        errores['tasa' + i] ? 'is-invalid' : ''
                      }`}
                      style={{ width: '8rem' }}
                    />
                    {errores['tasa' + i] && (
                      <span className="d-block text-danger">{errores['tasa' + i]}</span>
                    )}
                  </td>

                  {/* porcentaje de la tasa del interes  */}
                  <td className="text-center">
                    <NumericFormat
                      value={t.tasa_impuesto_interes}
                      onValueChange={(v, s) => handleChangeTs('tasa_impuesto_interes', i, v.value)}
                      valueIsNumericString={true}
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={2}
                      suffix="%"
                      className={`form-control text-right ${
                        errores['tasa_impuesto_interes' + i] ? 'is-invalid' : ''
                      }`}
                      style={{ width: '8rem' }}
                    />
                    {errores['tasa_impuesto_interes' + i] && (
                      <span className="d-block text-danger">
                        {errores['tasa_impuesto_interes' + i]}
                      </span>
                    )}
                  </td>

                  {/* porcentaje de la tasa del seguro  */}
                  <td className="text-center">
                    <NumericFormat
                      value={t.tasa_seguro_prestamo}
                      onValueChange={(v, s) => handleChangeTs('tasa_seguro_prestamo', i, v.value)}
                      valueIsNumericString={true}
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={2}
                      suffix="%"
                      className={`form-control text-right ${
                        errores['tasa_seguro_prestamo' + i] ? 'is-invalid' : ''
                      }`}
                      style={{ width: '8rem' }}
                    />
                    {errores['tasa_seguro_prestamo' + i] && (
                      <span className="d-block text-danger">
                        {errores['tasa_seguro_prestamo' + i]}
                      </span>
                    )}
                  </td>

                  {/* monto gastos administrativos */}
                  <td className="text-center">
                    <NumericFormat
                      value={
                        t.monto_gastos_administrativos >= 0 ? t.monto_gastos_administrativos : ''
                      }
                      onValueChange={(v, s) =>
                        handleChangeTs('monto_gastos_administrativos', i, v.value)
                      }
                      valueIsNumericString={true}
                      thousandSeparator="."
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={0}
                      className={`form-control text-right ${
                        errores['monto_gastos_administrativos' + i] ? 'is-invalid' : ''
                      }`}
                      style={{ width: '8rem' }}
                    />
                    {errores['monto_gastos_administrativos' + i] && (
                      <span className="d-block text-danger">
                        {errores['monto_gastos_administrativos' + i]}
                      </span>
                    )}
                  </td>

                  {/* porcentaje de gastos administrativos  */}
                  <td className="text-center">
                    <NumericFormat
                      value={t.porcentaje_gastos_administrativos}
                      onValueChange={(v, s) =>
                        handleChangeTs('porcentaje_gastos_administrativos', i, v.value)
                      }
                      valueIsNumericString={true}
                      decimalSeparator=","
                      allowNegative={false}
                      decimalScale={2}
                      suffix="%"
                      className={`form-control text-right ${
                        errores['porcentaje_gastos_administrativos' + i] ? 'is-invalid' : ''
                      }`}
                      style={{ width: '8rem' }}
                    />
                    {errores['porcentaje_gastos_administrativos' + i] && (
                      <span className="d-block text-danger">
                        {errores['porcentaje_gastos_administrativos' + i]}
                      </span>
                    )}
                  </td>

                  {/* borrar */}
                  <td>
                    <button className="btn btn-outline-secondary" onClick={() => rmTasa(i)}>
                      <i className="fas fa-times text-danger"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* btn guardar */}
        <div>
          <button className="btn btn-primary" onClick={clickGuardar}>
            <i className="fas fa-check"></i> Listo
          </button>
          <button className="btn btn-secondary ml-5" onClick={() => setOpen(false)}>
            <i className="fas fa-arrow-left"></i> Cancelar
          </button>
        </div>
      </Modal>
    </div>
  );
}

// Typechecking props of the component
PrestamoTasasModal.propTypes = {
  open: PropTypes.bool.isRequired,
  tasas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  setOpen: PropTypes.func.isRequired,
  setTasas: PropTypes.func.isRequired,
  monedas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
};

export default memo(PrestamoTasasModal);
