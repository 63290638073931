import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { listaCmp } from '../site/ListaCmp';
import PaginaComponenteModal from './PaginaComponenteModal';

/**
 * PaginaComponentes Component
 */
function PaginaComponentes({ idPagina }) {
  const backend = useSelector((state) => state.env.backend);
  const dispatch = useDispatch();
  const [componentes, setComponentes] = useState([]); // lista componentes
  const [isOpenPcm, setIsOpenPcm] = useState(false); // modal agregar/editar componente
  const [idCompModal, setIdCompModal] = useState(null); // id componente modal

  // get componentes
  const getComponentes = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/componentes?id_pagina=${idPagina}`);
      let res = response.data;
      if (Array.isArray(res)) {
        setComponentes(res);
      } else {
        toast.error('Error al obtener componentes.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener componentes.');
    }
    dispatch(finLoading());
  };

  // use effect get pagina
  useEffect(() => {
    if (idPagina) {
      getComponentes();
    }
  }, [idPagina]);

  // guardar orden de los componentes
  const guardarOrden = (items) => {
    items.forEach((c, i) => (c.orden = i + 1));
    let body = { lista: items };
    axios
      .post(`${backend}/api/componentes?save_orden=1`, body)
      .then((response) => {
        let res = response.data;
        if (res.ok) {
          toast.success('Orden guardado!');
        } else {
          toast.error(res.error || 'Error al guardar el orden.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar el orden.');
      });
  };

  /*****************************/
  /***** INI DRAG AND DROP *****/
  /*****************************/

  const grid = 8;

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // on drag end
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(componentes, result.source.index, result.destination.index);
    guardarOrden(items);
    setComponentes(items);
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'var(--gray-2)' : '',
    // paddingTop: grid,
    // paddingBottom: grid,
    // width: 250,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid * 2}px 0`,
    // change background colour if dragging
    background: isDragging ? 'var(--gray-3)' : 'white',
    border: '1px solid var(--gray-3)',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // log lista componentes
  useEffect(() => {
    // console.log(componentes);
  }, [componentes]);

  /*****************************/
  /***** FIN DRAG AND DROP *****/
  /*****************************/

  const getNombrePlantilla = (codigo) => {
    let cmp = listaCmp.filter((cmp) => cmp.codigo == codigo)[0];
    if (cmp) return cmp.nombre;
    else return null;
  };

  // cambia visiblidad de un componente
  const chVisible = (idComp, visible) => {
    dispatch(iniLoading());
    axios
      .put(`${backend}/api/componentes/${idComp}`, { visible })
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success(`Componente ahora ${visible ? 'visible' : 'oculto'}!`);
          getComponentes(); // refresh
        } else {
          toast.error(res.error || 'Error al cambiar visibilidad.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al cambiar visibilidad.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // elimina completamente un componente de una pagina
  const eliminarComponente = (comp) => {
    let conf = window.confirm('Por favor, confirme para eliminar: ' + comp.nombre);
    if (conf) {
      dispatch(iniLoading());
      axios
        .delete(`${backend}/api/componentes/${comp.id}`)
        .then((response) => {
          let res = response.data;
          if (res.ok) {
            toast.success(`Componente eliminado`);
            getComponentes(); // refresh
          } else {
            toast.error(res.error || 'Error al eliminar.');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('Error al eliminar.');
        })
        .finally(() => {
          dispatch(finLoading());
        });
    }
  };

  return (
    <div className="mb-5">
      <div className="d-flex content-between items-center mb-5">
        <h4 className="font-weight-regular">Componentes</h4>
        <button
          className="btn btn-primary"
          onClick={() => {
            setIdCompModal(null);
            setIsOpenPcm(true);
          }}
        >
          <i className="fas fa-fw fa-plus"></i> Agregar Componente
        </button>
      </div>

      {/* lista ordenable de componentes */}
      {componentes.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {componentes.map((item, index) => (
                  <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        className="d-flex content-between items-center"
                      >
                        <div>
                          <span className="mr-3">
                            <i className="fas fa-ellipsis-v"></i>
                            <i className="fas fa-ellipsis-v"></i>
                          </span>
                          <span>
                            {item.nombre} {/* / {getNombrePlantilla(item.codigo)} */}
                          </span>
                        </div>
                        <div>
                          <button
                            className="btn btn-sm btn-outline-secondary"
                            title="Editar"
                            onClick={() => {
                              setIdCompModal(item.id);
                              setIsOpenPcm(true);
                            }}
                          >
                            <i className="fas fa-fw fa-pencil-alt"></i>
                          </button>
                          {item.visible ? (
                            <button
                              className="btn btn-sm btn-outline-secondary ml-3"
                              title="Está visible, presione para ocultar."
                              onClick={() => chVisible(item.id, false)}
                            >
                              <i className="fas fa-fw fa-eye"></i>
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm btn-outline-secondary ml-3"
                              title="Está oculto, presione para mostrar."
                              onClick={() => chVisible(item.id, true)}
                            >
                              <i className="fas fa-fw fa-eye-slash"></i>
                            </button>
                          )}
                          <button
                            className="btn btn-sm btn-outline-secondary ml-3"
                            title="Eliminar"
                            onClick={() => eliminarComponente(item)}
                          >
                            <i className="fas fa-fw fa-trash-alt"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <p>Aún no se agregó ningún componente a esta página.</p>
      )}

      <PaginaComponenteModal
        isOpen={isOpenPcm}
        idPagina={idPagina}
        idComponente={idCompModal}
        onSave={() => {
          setIdCompModal(null);
          setIsOpenPcm(false);
          getComponentes(); // refresh
        }}
        onCancel={() => {
          setIdCompModal(null);
          setIsOpenPcm(false);
        }}
      />
    </div>
  );
}

// Typechecking props of the component
PaginaComponentes.propTypes = {
  idPagina: PropTypes.number.isRequired,
};

export default PaginaComponentes;
