import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * ReclamoAccion Component
 */
function ReclamoAccion({ reclamo }) {
  return (
    <div>
      <Link
        to={`/admin/reclamos/${reclamo.id}`}
        className="btn btn-sm btn-outline-secondary"
        title="Ver"
      >
        <i className="fas fa-fw fa-eye"></i>
      </Link>
    </div>
  );
}

// Typechecking props of the component
ReclamoAccion.propTypes = {
  reclamo: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default ReclamoAccion;
