import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { capitalize, formatCodigo } from '../Util';

/**
 * UserInstance Component
 */
function UserInstance() {
  const [readOnly, setReadOnly] = useState(false);
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams(); // id del user, null al crear
  const [user, setUser] = useState({}); // user instance
  const [errores, setErrores] = useState({}); // form errores

  // handle change formulario user
  const handleChange = (name, value) => {
    if (readOnly) {
      console.log('read only: no change');
    } else {
      setUser((usu) => ({ ...usu, [name]: value }));
    }
  };

  // get user by id
  const getUser = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/users/${id}`);
      let res = response.data;
      if (res.id) {
        setUser(res);
      } else {
        toast.error('Error al obtener el usuario.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener el usuario.');
    }
    dispatch(finLoading());
  };

  // use effect get user
  useEffect(() => {
    if (id && authData && authData.user.rol == 'admin') {
      getUser();
    }
  }, [id]);

  // store new user
  const storeUser = () => {
    dispatch(iniLoading());
    axios
      .post(`${backend}/api/users`, user)
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Nuevo usuario registrado!');
          navigate('/admin/users/' + res.id);
        } else if (res.errores) {
          setErrores(res.errores);
        } else {
          toast.error(res.error || 'Error al guardar el usuario.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar el usuario.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // update current user
  const updateUser = () => {
    dispatch(iniLoading());
    axios
      .put(`${backend}/api/users/${user.id}`, user)
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Usuario guardado!');
        } else if (res.errores) {
          setErrores(res.errores);
        } else {
          toast.error(res.error || 'Error al guardar el usuario.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar el usuario.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // validar form user
  const validarUser = () => {
    let res = true;
    setErrores({});
    if (!(user.user || '').trim()) {
      setErrores((e) => ({ ...e, user: 'Por favor, complete este campo.' }));
      res = false;
    }
    if (!(user.name || '').trim()) {
      setErrores((e) => ({ ...e, name: 'Ingrese el nombre de el usuario.' }));
      res = false;
    }
    if (!(user.rol || '').trim()) {
      setErrores((e) => ({ ...e, rol: 'Seleccione un rol.' }));
      res = false;
    }
    if (!(user.email || '').trim()) {
      setErrores((e) => ({ ...e, email: 'Por favor, complete este campo.' }));
      res = false;
    }
    if (id == null && !(user.password || '').trim()) {
      setErrores((e) => ({ ...e, password: 'Ingrese la contraseña para el nuevo usuario.' }));
      res = false;
    }
    return res;
  };

  // click guardar
  const clickGuardar = () => {
    if (validarUser()) {
      if (id == null) {
        storeUser();
      } else {
        updateUser();
      }
    }
  };

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-5">
          {id == null ? (
            <h4 className="font-weight-regular">Nuevo Usuario</h4>
          ) : (
            <h4 className="font-weight-regular">Editar Usuario</h4>
          )}
        </div>

        {/* formulario user */}
        <div className="mb-5">
          {/* input user */}
          <div className="form-group">
            <label htmlFor="user">
              Usuario <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errores.user ? 'is-invalid' : ''}`}
              id="user"
              value={user.user || ''}
              onChange={(e) => handleChange('user', formatCodigo(e.target.value))}
            />
            <span className="text-danger">{errores.user}</span>
          </div>

          {/* input nombre */}
          <div className="form-group">
            <label htmlFor="name">
              Nombre <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errores.name ? 'is-invalid' : ''}`}
              id="name"
              value={user.name || ''}
              onChange={(e) => handleChange('name', capitalize(e.target.value))}
            />
            <span className="text-danger">{errores.name}</span>
          </div>

          {/* input rol */}
          <div className="form-group">
            <label htmlFor="rol">Rol</label>
            <select
              id="rol"
              className={`form-control ${errores.rol ? 'is-invalid' : ''}`}
              value={user.rol || ''}
              onChange={(e) => handleChange('rol', e.target.value)}
            >
              <option value="">Seleccione...</option>
              <option value="admin">Administrador</option>
              <option value="rrhh">RRHH</option>
              <option value="calidad">Calidad</option>
              <option value="comercial">Comercial</option>
              <option value="finanzas">Finanzas</option>
              <option value="seguros">Seguros</option>
            </select>
            <span className="text-danger">{errores.rol}</span>
          </div>

          {/* input email */}
          <div className="form-group">
            <label htmlFor="email">
              Correo Electrónico <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errores.email ? 'is-invalid' : ''}`}
              id="email"
              value={user.email || ''}
              onChange={(e) => handleChange('email', e.target.value.trim())}
            />
            <span className="text-danger">{errores.email}</span>
          </div>

          {/* input password */}
          <div className="form-group">
            <label htmlFor="password">
              Contraseña {id == null && <span className="text-danger">*</span>}
            </label>
            <input
              type="password"
              className={`form-control ${errores.password ? 'is-invalid' : ''}`}
              id="password"
              value={user.password || ''}
              onChange={(e) => handleChange('password', e.target.value.trim())}
            />
            {id != null && <span className="text-muted">Deje vacío para no modificar</span>}
            <span className="text-danger">{errores.password}</span>
          </div>

          {/* btn guardar */}
          <div>
            <button className="btn btn-primary" onClick={clickGuardar}>
              <i className="fas fa-save"></i> Guardar
            </button>
            <button className="btn btn-secondary ml-5" onClick={() => navigate('/admin/users')}>
              <i className="fas fa-arrow-left"></i> Volver
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default UserInstance;
