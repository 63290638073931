import DataTableBase from '../DataTableBase';
import PaginaAccion from './PaginaAccion';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * PaginaLista Component
 */
function PaginaLista() {
  const authData = useSelector((state) => state.auth.value);

  const columns = [
    {
      name: 'DIRECCIÓN',
      selector: (row) => '/site?page=' + row.codigo,
      sortable: true,
      sortField: 'codigo',
    },
    {
      name: 'NOMBRE PÁGINA',
      selector: (row) => row.nombre,
      sortable: true,
      sortField: 'nombre',
    },
    {
      name: 'Acciones',
      cell: (row) => <PaginaAccion pagina={row} />,
    },
  ];

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-3">
          <h4 className="font-weight-regular">Gestión de Páginas</h4>
          <Link to={'/admin/paginas/new'} className="btn btn-primary">
            <i className="fas fa-fw fa-plus"></i> Nueva Página
          </Link>
        </div>

        <div>
          <DataTableBase url={'/api/paginas'} columns={columns} />
        </div>
      </div>
    )
  );
}

export default PaginaLista;
