import { useState, useEffect } from 'react';
import { validateOnInputNumber } from '../Util';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

/**
 * FormDatosLaborales Component
 */
function FormDatosLaborales({
  form,
  updateForm,
  updateFileDocumento,
  updateFileComprobanteIngreso,
  selectSucursales,
  selectFormatosTelefono,
  formatoTelefonoParaguay,
  captchaRefPersona,
  enviarFormulario,
  urlTerminosCondiciones,
}) {
  const [campos, setCampos] = useState({
    idSucursal: form.idSucursal,
    lugarTrabajo: form.lugarTrabajo,
    salarioActual: form.salarioActual,
    idFormatoTelefonoLaboral: form.idFormatoTelefonoLaboral,
    telefonoLaboral: form.telefonoLaboral,
    documento: form.documento,
    comprobanteIngreso: form.comprobanteIngreso,
    aceptacion: form.aceptacion,
  });
  const [errores, setErrores] = useState({}); // form errores
  const [extensionDocumento, setExtensionDocumento] = useState({});
  const [extensionComprobanteIngreso, setExtensionComprobanteIngreso] = useState({});
  const [maxLengthTelefono, setMaxLengthTelefono] = useState(undefined);
  const [esPersona, setEsPersona] = useState(true);
  const captchaSiteKey = useSelector((state) => state.env.captchaSiteKey);

  const backend = useSelector((state) => state.env.backend);

  if (form.esPersona != esPersona) setEsPersona(form.esPersona);

  if (maxLengthTelefono == undefined && formatoTelefonoParaguay != undefined)
    setMaxLengthTelefono(formatoTelefonoParaguay);

  if (!campos.idFormatoTelefonoLaboral && form.idFormatoTelefonoLaboral)
    setCampos({ ...campos, idFormatoTelefonoLaboral: form.idFormatoTelefonoLaboral });

  useEffect(() => {
    setCampos({ ...campos, lugarTrabajo: '', salarioActual: '', telefonoLaboral: '' });
  }, [esPersona]);

  const validarFormDatosLaborales = () => {
    let enviar = true;
    setErrores({});

    if (campos.idSucursal == '') {
      setErrores((e) => ({ ...e, idSucursal: 'Seleccione una sucursal.' }));
      enviar = false;
    }

    if (campos.lugarTrabajo == '') {
      setErrores((e) => ({ ...e, lugarTrabajo: 'Ingrese un lugar de trabajo.' }));
      enviar = false;
    }

    if (campos.telefonoLaboral) {
      if (campos.idFormatoTelefonoLaboral == '') {
        setErrores((e) => ({ ...e, idFormatoTelefonoLaboral: 'Seleccione un país.' }));
        enviar = false;
      }

      if (campos.telefonoLaboral.length > maxLengthTelefono.max_digitos) {
        setErrores((e) => ({
          ...e,
          telefonoLaboral: 'El nro de teléfono es muy largo para el país seleccionado.',
        }));
        enviar = false;
      }
    }

    if (!campos.documento) {
      setErrores((e) => ({ ...e, documento: 'Seleccione una foto de su documento.' }));
      enviar = false;
    } else if (extensionDocumento != 'pdf' && extensionDocumento != 'jpg') {
      setErrores((e) => ({ ...e, documento: 'El formato del archivo debe ser "pdf" o "jpg"' }));
      enviar = false;
    }

    if (!campos.comprobanteIngreso) {
      setErrores((e) => ({
        ...e,
        comprobanteIngreso: 'Seleccione una foto de su comprobante de ingresos.',
      }));
      enviar = false;
    } else if (extensionComprobanteIngreso != 'pdf' && extensionComprobanteIngreso != 'jpg') {
      setErrores((e) => ({
        ...e,
        comprobanteIngreso: 'El formato del archivo debe ser "pdf" o "jpg".',
      }));
      enviar = false;
    }

    if (!campos.aceptacion) {
      setErrores((e) => ({
        ...e,
        aceptacion: 'Acepte los terminos y condiciones.',
      }));
      enviar = false;
    }

    if (!captchaRefPersona.current.getValue()) {
      setErrores((e) => ({ ...e, captcha: 'Por favor, complete el captcha.' }));
      return;
    }

    if (enviar) enviarFormulario();
  };

  const updateCampo = (name, value) => {
    if (name == 'idFormatoTelefonoLaboral')
      setCampos({ ...campos, [name]: value, telefonoLaboral: '' });
    else setCampos({ ...campos, [name]: value });
    updateForm([name], value);
  };

  return (
    <>
      <div className="form-group">
        <label htmlFor="sucursal">
          ¿Donde desea operar? <span className="text-danger">*</span>
        </label>
        <select
          id="sucursal"
          className={`form-control form-control-100 ${errores.idSucursal ? 'is-invalid' : ''}`}
          value={campos.idSucursal}
          onChange={(e) => {
            updateCampo('idSucursal', e.target.value);
          }}
        >
          <option value="">Seleccione...</option>
          {selectSucursales.map((sucursales) => (
            <option key={sucursales.id} value={sucursales.id}>
              {sucursales.nombreFormulario}
            </option>
          ))}
        </select>
        <span className="text-danger">{errores.idSucursal}</span>
      </div>

      <div className="form-group">
        <label htmlFor="work">
          Lugar de trabajo <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          id="work"
          autoComplete="off"
          className={`form-control form-control-100 ${errores.lugarTrabajo ? 'is-invalid' : ''}`}
          value={campos.lugarTrabajo}
          onChange={(e) => {
            updateCampo('lugarTrabajo', e.target.value);
          }}
          maxLength={50}
        />
        <span className="text-danger">{errores.lugarTrabajo}</span>
      </div>

      <div className="form-group">
        <label htmlFor="salary">Salario actual</label>
        <NumericFormat
          id="salary"
          value={campos.salarioActual || ''}
          onValueChange={(v, s) => updateCampo('salarioActual', v.value)}
          valueIsNumericString={true}
          thousandSeparator="."
          decimalSeparator=","
          allowNegative={false}
          decimalScale={0}
          className={`form-control form-control-100 text-left ${
            errores.salarioActual ? 'is-invalid' : ''
          }`}
          autoComplete="off"
          maxLength={12}
        />
        <span className="text-danger">{errores.salarioActual}</span>
      </div>
      <div className="form-group">
        <label htmlFor="mobilePhone">Teléfono laboral</label>
        <div style={{ display: 'flex' }}>
          <div className="d-flex">
            <select
              id="idFormatoTelefonoLaboral"
              className={`form-control form-control-100 ${
                errores.idFormatoTelefonoLaboral ? 'is-invalid' : ''
              }`}
              value={campos.idFormatoTelefonoLaboral}
              onChange={(e) => {
                setMaxLengthTelefono(selectFormatosTelefono.find((t) => t.id == e.target.value));
                updateCampo('idFormatoTelefonoLaboral', e.target.value);
              }}
              style={{ width: '10rem' }}
            >
              <option value="">Seleccione...</option>
              {selectFormatosTelefono.map((telefono) => (
                <option key={telefono.id} value={telefono.id}>
                  {`${telefono.pais} ${telefono.prefijo}`}
                </option>
              ))}
            </select>
          </div>
          <PatternFormat
            id="mobilePhone"
            value={campos.telefonoLaboral}
            format={`${
              maxLengthTelefono !== undefined && maxLengthTelefono
                ? `${maxLengthTelefono.formato}`
                : ''
            }`}
            className={`form-control form-control-100 ${
              errores.telefonoLaboral ? 'is-invalid' : ''
            }`}
            autoComplete="off"
            style={{ width: '100%' }}
            onValueChange={(v, s) => updateCampo('telefonoLaboral', validateOnInputNumber(v.value))}
            valueIsNumericString={true}
            placeholder={`${
              maxLengthTelefono !== undefined && maxLengthTelefono
                ? maxLengthTelefono.placeholder
                : ''
            }`}
            mask="_"
          />
        </div>
        <span className="text-danger">{errores.idFormatoTelefonoLaboral}</span>
        <span className="text-danger">{errores.telefonoLaboral}</span>
      </div>
      <div className="form-group">
        <label htmlFor="idphoto">
          Documento de identidad <span className="text-danger">*</span>
        </label>
        <input
          type="file"
          id="idphoto"
          className={`form-control form-control-100 ${errores.documento ? 'is-invalid' : ''}`}
          value={campos.documento}
          onChange={(e) => {
            updateFileDocumento(e.target.files[0]);
            let auxExtension = e.target.files[0].name.split('.');
            setExtensionDocumento(auxExtension[auxExtension.length - 1]);
            updateCampo('documento', e.target.value);
          }}
        />
        <span className="text-danger">{errores.documento}</span>
      </div>
      <div className="form-group">
        <label htmlFor="salaryphoto">
          Comprobante de ingresos <span className="text-danger">*</span>
        </label>
        <input
          type="file"
          id="salaryphoto"
          className={`form-control form-control-100 ${
            errores.comprobanteIngreso ? 'is-invalid' : ''
          }`}
          value={campos.comprobanteIngreso}
          onChange={(e) => {
            updateFileComprobanteIngreso(e.target.files[0]);
            let auxExtension = e.target.files[0].name.split('.');
            setExtensionComprobanteIngreso(auxExtension[auxExtension.length - 1]);
            updateCampo('comprobanteIngreso', e.target.value);
          }}
        />
        <span className="text-danger">{errores.comprobanteIngreso}</span>
      </div>
      <div className="mt-3 mb-4">
        <input
          type="checkbox"
          id="terms"
          className={`checkbox ${errores.aceptacion ? 'is-invalid' : ''}`}
          checked={campos.aceptacion}
          onChange={(e) => {
            updateCampo('aceptacion', e.target.checked);
          }}
        />
        <label htmlFor="terms" className="ml-2">
          Acepto los{' '}
          <a href={`${backend}/api/archivo?url=${urlTerminosCondiciones}`} target={'_blank'}>
            Términos y Condiciones
          </a>{' '}
          de esta solicitud <span className="text-danger">*</span>
        </label>
        <br />
        <span className="text-danger">{errores.aceptacion}</span>
      </div>

      {/* captcha */}
      <ReCAPTCHA sitekey={captchaSiteKey} ref={captchaRefPersona} />
      <span className="text-danger">{errores.captcha}</span>

      <p className="mt-5">
        <span className="text-danger">*</span> Campos obligatorios
      </p>
      <div className="mt-4 mb-4">
        <button type="button" onClick={validarFormDatosLaborales} className="btn btn-primary">
          Enviar Solicitud
        </button>
      </div>
    </>
  );
}

export default FormDatosLaborales;
