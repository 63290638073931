/**
 * Lista de los componentes/plantillas dinamicos del sitio web
 * Los campos aca deben estar en concocardancia con los prop types de los componentes
 * Types: text, image, doc, select, array
 * El valor de los campos siempre string! (debido a algunas limitaciones en el form dinamico)
 * Ojo: no repetir el mismo valor en "prop" en un componente
 * El type "select" tiene ademas "options"
 * El type "array" tiene ademas "itemName" y "campos"
 */
export const listaCmp = [
  {
    codigo: 'cmp_pres_sit',
    nombre: 'Panel de presentación de sitio web',
    campos: [
      {
        prop: 'srcImgBg',
        name: 'Imagen de fondo',
        type: 'image',
        required: true,
      },
      {
        prop: 'titulo1',
        name: 'Título Primario',
        type: 'text',
        required: true,
      },
      {
        prop: 'titulo1_justify',
        name: 'Justificar',
        type: 'checkbox',
        required: false,
      },
      {
        prop: 'label',
        name: 'Etiqueta',
        type: 'text',
        required: false,
      },
      {
        prop: 'label_justify',
        name: 'Justificar',
        type: 'checkbox',
        required: false,
      },
      {
        prop: 'titulo2',
        name: 'Título Secundario',
        type: 'text',
        required: true,
      },
      {
        prop: 'titulo3',
        name: 'Título Terciario',
        type: 'text',
        required: false,
      },
      {
        prop: 'placeholder',
        name: 'Placeholder',
        type: 'text',
        required: true,
      },
    ],
  },
  {
    codigo: 'cmp_pres_pag',
    nombre: 'Panel de presentación de página',
    campos: [
      {
        prop: 'srcImgBg',
        name: 'Imagen',
        type: 'image',
        required: false,
      },
      {
        prop: 'label',
        name: 'Etiqueta',
        type: 'text',
        required: false,
      },
      {
        prop: 'titulo',
        name: 'Título',
        type: 'text',
        required: false,
      },
    ],
  },
  {
    codigo: 'cmp_pres_serv',
    nombre: 'Panel de presentación de servicio',
    campos: [
      {
        prop: 'srcImgBg',
        name: 'Imagen de fondo',
        type: 'image',
        required: true,
      },
      {
        prop: 'label',
        name: 'Etiqueta',
        type: 'text',
        required: false,
      },
      {
        prop: 'label_justify',
        name: 'Justificar',
        type: 'checkbox',
        required: false,
      },
      {
        prop: 'titulo',
        name: 'Título',
        type: 'text',
        required: true,
      },
      {
        prop: 'titulo_justify',
        name: 'Justificar',
        type: 'checkbox',
        required: false,
      },
      {
        prop: 'enlace',
        name: 'Enlace',
        type: 'text',
        required: false,
      },
      {
        prop: 'direccion',
        name: 'Dirección',
        type: 'select',
        required: false,
        options: [
          {
            value: 'left',
            label: 'Izquierda',
          },
          {
            value: 'right',
            label: 'Derecha',
          },
        ],
      },
      {
        prop: 'colorBg',
        name: 'Color de fondo',
        type: 'select',
        required: false,
        options: [
          {
            value: 'white',
            label: 'Blanco',
          },
          {
            value: 'var(--gray-1)',
            label: 'Gris claro',
          },
        ],
      },
      {
        prop: 'fullImgBg',
        name: 'Imagen Full',
        type: 'select',
        required: false,
        options: [
          {
            value: '1',
            label: 'Sí',
          },
          {
            value: '0',
            label: 'No',
          },
        ],
      },
    ],
  },
  {
    codigo: 'cmp_img_txt_hrz',
    nombre: 'Panel de imagen y texto horizontal',
    campos: [
      {
        prop: 'srcImgBg',
        name: 'Imagen de fondo',
        type: 'image',
        required: true,
      },
      {
        prop: 'titulo',
        name: 'Título',
        type: 'text',
        required: false,
      },
      {
        prop: 'titulo_justify',
        name: 'Justificar',
        type: 'checkbox',
        required: false,
      },
      {
        prop: 'parrafos',
        name: 'Párrafos',
        type: 'array',
        itemName: 'Párrafo',
        required: true,
        campos: [
          {
            prop: 'subtitulo',
            name: 'Subtítulo de párrafo',
            type: 'text',
            required: true,
          },
          {
            prop: 'subtitulo_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
          {
            prop: 'texto',
            name: 'Texto',
            type: 'text',
            required: false,
          },
          {
            prop: 'texto_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
        ],
      },
      {
        prop: 'enlace',
        name: 'Enlace',
        type: 'text',
        required: false,
      },
      {
        prop: 'label',
        name: 'Etiqueta',
        type: 'text',
        required: false,
      },
      {
        prop: 'direccion',
        name: 'Dirección',
        type: 'select',
        required: false,
        options: [
          {
            value: 'left',
            label: 'Izquierda',
          },
          {
            value: 'right',
            label: 'Derecha',
          },
        ],
      },
    ],
  },
  {
    codigo: 'cmp_fil_cards',
    nombre: 'Panel de fila de cards',
    campos: [
      {
        prop: 'titulo',
        name: 'Título',
        type: 'text',
        required: false,
      },
      {
        prop: 'cards',
        name: 'Tarjetas',
        type: 'array',
        itemName: 'Tarjeta',
        required: true,
        campos: [
          {
            prop: 'srcImgBg',
            name: 'Imagen de tarjeta',
            type: 'image',
            required: true,
          },
          {
            prop: 'subtitulo',
            name: 'Subtítulo',
            type: 'text',
            required: true,
          },
          {
            prop: 'label',
            name: 'Etiqueta',
            type: 'text',
            required: false,
          },
          {
            prop: 'texto',
            name: 'Texto',
            type: 'text',
            required: true,
          },
          {
            prop: 'texto_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
          {
            prop: 'enlace',
            name: 'Enlace',
            type: 'text',
            required: false,
          },
        ],
      },
      {
        prop: 'estilo',
        name: 'Estilo',
        type: 'select',
        required: false,
        options: [
          {
            value: 'square',
            label: 'Cuadrado',
          },
          {
            value: 'circle',
            label: 'Círculo',
          },
        ],
      },
    ],
  },
  {
    codigo: 'cmp_tit_txt_hrz',
    nombre: 'Panel de título y texto horizontal',
    campos: [
      {
        prop: 'titulo',
        name: 'Título',
        type: 'text',
        required: true,
      },
      {
        prop: 'titulo_justify',
        name: 'Justificar',
        type: 'checkbox',
        required: false,
      },
      {
        prop: 'texto',
        name: 'Texto',
        type: 'text',
        required: true,
      },
      {
        prop: 'texto_justify',
        name: 'Justificar',
        type: 'checkbox',
        required: false,
      },
      {
        prop: 'enlace',
        name: 'Enlace',
        type: 'text',
        required: false,
      },
      {
        prop: 'label',
        name: 'Etiqueta',
        type: 'text',
        required: false,
      },
    ],
  },
  {
    codigo: 'cmp_crd_txt_els',
    nombre: 'Panel tipo tarjeta de texto con enlaces',
    campos: [
      {
        prop: 'titulo',
        name: 'Título',
        type: 'text',
        required: true,
      },
      {
        prop: 'titulo_justify',
        name: 'Justificar',
        type: 'checkbox',
        required: false,
      },
      {
        prop: 'texto',
        name: 'Texto',
        type: 'text',
        required: true,
      },
      {
        prop: 'texto_justify',
        name: 'Justificar',
        type: 'checkbox',
        required: false,
      },
      {
        prop: 'enlaces',
        name: 'Enlaces',
        type: 'array',
        itemName: 'Enlace',
        required: true,
        campos: [
          {
            prop: 'enlace',
            name: 'Enlace',
            type: 'text',
            required: true,
          },
          {
            prop: 'label',
            name: 'Etiqueta',
            type: 'text',
            required: true,
          },
        ],
      },
      {
        prop: 'srcImgBg',
        name: 'Imagen de fondo',
        type: 'image',
        required: true,
      },
    ],
  },
  {
    codigo: 'cmp_fil_ress',
    nombre: 'Panel de fila de resúmenes',
    campos: [
      {
        prop: 'titulo',
        name: 'Título',
        type: 'text',
        required: true,
      },
      {
        prop: 'resumenes',
        name: 'Resúmenes',
        type: 'array',
        itemName: 'Resumen',
        required: true,
        campos: [
          {
            prop: 'subtitulo',
            name: 'Subtítulo',
            type: 'text',
            required: true,
          },
          {
            prop: 'subtitulo_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
          {
            prop: 'texto',
            name: 'Texto',
            type: 'text',
            required: true,
          },
          {
            prop: 'texto_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
        ],
      },
      {
        prop: 'estilo',
        name: 'Estilo',
        type: 'select',
        required: false,
        options: [
          {
            value: 'normal',
            label: 'Normal',
          },
          {
            value: 'descriptivo',
            label: 'Descriptivo',
          },
        ],
      },
    ],
  },
  {
    codigo: 'cmp_list_pregs',
    nombre: 'Panel de lista de preguntas',
    campos: [
      {
        prop: 'titulo',
        name: 'Título',
        type: 'text',
        required: true,
      },
      {
        prop: 'preguntas',
        name: 'Preguntas',
        type: 'array',
        itemName: 'Pregunta',
        required: true,
        campos: [
          {
            prop: 'pregunta',
            name: 'Pregunta',
            type: 'text',
            required: true,
          },
          {
            prop: 'respuesta',
            name: 'Respuesta',
            type: 'text',
            required: true,
          },
          {
            prop: 'respuesta_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
        ],
      },
    ],
  },
  {
    codigo: 'cmp_pila_txts',
    nombre: 'Panel de pila de textos',
    campos: [
      {
        prop: 'titulo',
        name: 'Título',
        type: 'text',
        required: false,
      },
      {
        prop: 'parrafos',
        name: 'Párrafos',
        type: 'array',
        itemName: 'Párrafo',
        required: true,
        campos: [
          {
            prop: 'subtitulo',
            name: 'Subtítulo',
            type: 'text',
            required: false,
          },
          {
            prop: 'subtitulo_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
          {
            prop: 'texto',
            name: 'Texto',
            type: 'text',
            required: true,
          },
          {
            prop: 'texto_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
        ],
      },
    ],
  },
  {
    codigo: 'cmp_list_cols',
    nombre: 'Panel de lista en columnas',
    campos: [
      {
        prop: 'titulo',
        name: 'Título',
        type: 'text',
        required: true,
      },
      {
        prop: 'lista',
        name: 'Lista',
        type: 'array',
        itemName: 'Ítem',
        required: true,
        campos: [
          {
            prop: 'subtitulo',
            name: 'Subtítulo',
            type: 'text',
            required: true,
          },
          {
            prop: 'subtitulo_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
          {
            prop: 'descripcion',
            name: 'Descripción',
            type: 'text',
            required: true,
          },
          {
            prop: 'descripcion_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
          {
            prop: 'srcImgProfile',
            name: 'Imagen perfil',
            type: 'image',
            required: false,
          },
          {
            prop: 'etiqueta',
            name: 'Etiqueta',
            type: 'text',
            required: false,
          },
          {
            prop: 'enlace',
            name: 'Enlace',
            type: 'text',
            required: false,
          },
        ],
      },
    ],
  },
  {
    codigo: 'cmp_list_docs',
    nombre: 'Panel de lista de documentos',
    campos: [
      {
        prop: 'titulo',
        name: 'Título',
        type: 'text',
        required: true,
      },
      {
        prop: 'documentos',
        name: 'Documentos',
        type: 'array',
        itemName: 'Documento',
        required: true,
        campos: [
          {
            prop: 'enlaceDoc',
            name: 'Documento',
            type: 'doc',
            required: true,
          },
          {
            prop: 'nombreDoc',
            name: 'Nombre del documento',
            type: 'text',
            required: true,
          },
          {
            prop: 'nombreDoc_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
          {
            prop: 'descripcion',
            name: 'Descripción',
            type: 'text',
            required: false,
          },
          {
            prop: 'descripcion_justify',
            name: 'Justificar',
            type: 'checkbox',
            required: false,
          },
        ],
      },
    ],
  },
  {
    codigo: 'cmp_tabs_els',
    nombre: 'Panel de pestañas de enlaces',
    campos: [
      {
        prop: 'tabs',
        name: 'Pestañas',
        type: 'array',
        itemName: 'Pestaña',
        required: true,
        campos: [
          {
            prop: 'enlace',
            name: 'Enlace',
            type: 'text',
            required: true,
          },
          {
            prop: 'label',
            name: 'Etiqueta',
            type: 'text',
            required: true,
          },
        ],
      },
    ],
  },
  {
    codigo: 'cmp_cots',
    nombre: 'Panel de cotizaciones',
    campos: [],
  },
];
