import PropTypes from 'prop-types';
import { getImageUrl } from '../Util';

/**
 * Website component: PanelPresentacionServicio
 * codigo = cmp_pres_serv
 */
function PanelPresentacionServicio({ muestra }) {
  let estiloBgFull = `linear-gradient(130deg, rgba(0, 0, 0, 0.57), rgba(0, 0, 0, 0)), url(${getImageUrl(
    muestra.srcImgBg
  )})`;

  return (
    <>
      {!muestra.fullImgBg || muestra.fullImgBg == '0' ? (
        <div style={{ background: muestra.colorBg || 'none' }}>
          <section className="container">
            <div className="section-photo-text">
              {(muestra.direccion === 'left' || !muestra.direccion) && (
                <div className="section-w-photo">
                  <img
                    src={getImageUrl(muestra.srcImgBg)}
                    className="img-fluid"
                    alt="imagen del servicio"
                  />
                </div>
              )}
              <div className="section-w-text">
                {muestra.label && (
                  <p
                    className="mb-2"
                    style={{ textAlign: muestra.label_justify ? 'justify' : 'left' }}
                  >
                    {muestra.label}
                  </p>
                )}
                {/* si no hay enlace muestra mas grande */}
                {muestra.enlace ? (
                  <>
                    <h3
                      className="mb-3"
                      style={{ textAlign: muestra.titulo_justify ? 'justify' : 'left' }}
                    >
                      {muestra.titulo}
                    </h3>
                    <a href={muestra.enlace}>¡Conocé más!</a>
                  </>
                ) : (
                  <h1
                    className="mb-3"
                    style={{ textAlign: muestra.titulo_justify ? 'justify' : 'left' }}
                  >
                    {muestra.titulo}
                  </h1>
                )}
              </div>
              {muestra.direccion === 'right' && (
                <div className="section-w-photo">
                  <img
                    src={getImageUrl(muestra.srcImgBg)}
                    className="img-fluid"
                    alt="imagen del servicio"
                  />
                </div>
              )}
            </div>
          </section>
        </div>
      ) : (
        <section
          id="cuentas"
          style={{ backgroundImage: estiloBgFull, backgroundPosition: 'center' }}
        >
          <div className="container">
            <div className="cuentas-info">
              <p className="mb-2" style={{ textAlign: muestra.label_justify ? 'justify' : 'left' }}>
                {muestra.label}
              </p>
              <h3
                className="mb-3"
                style={{ textAlign: muestra.titulo_justify ? 'justify' : 'left' }}
              >
                {muestra.titulo}
              </h3>
              <a href={muestra.enlace} className="btn-ghost">
                ¡Conocé más!
              </a>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

// Setting default values for the props of component
PanelPresentacionServicio.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelPresentacionServicio.propTypes = {
  muestra: PropTypes.shape({
    srcImgBg: PropTypes.string.isRequired,
    label: PropTypes.string, // opcional
    titulo: PropTypes.string.isRequired,
    enlace: PropTypes.string, // opcional
    direccion: PropTypes.string, // opcional, in ['left', 'right']
    colorBg: PropTypes.string, // opcional
    fullImgBg: PropTypes.string, // opcional, in ['0', '1']
  }).isRequired,
};

export default PanelPresentacionServicio;
