import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * ProductoAccion Component
 */
function ProductoAccion({ producto }) {
  return (
    <div>
      <Link
        to={`/admin/productos/${producto.id}`}
        className="btn btn-sm btn-outline-secondary"
        title="Ver"
      >
        <i className="fas fa-fw fa-eye"></i>
      </Link>
    </div>
  );
}

// Typechecking props of the component
ProductoAccion.propTypes = {
  producto: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default ProductoAccion;
