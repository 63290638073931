import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { dispIsoDateString, formatNumero, meses } from '../Util';
import ArFlag from '../../assets/img/flags/ar-flag.png';
import BrFlag from '../../assets/img/flags/br-flag.png';
import UeFlag from '../../assets/img/flags/ue-flag.png';
import UsFlag from '../../assets/img/flags/us-flag.png';
import UyFlag from '../../assets/img/flags/uy-flag.png';

function Cotizaciones() {
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currYear = new Date().getFullYear();
  const [monedas, setMonedas] = useState([]);
  const [selMoneda, setSelMoneda] = useState('');
  const [selFlag, setSelFlag] = useState(null);
  const [selMes, setSelMes] = useState(meses[new Date().getMonth()].val);
  const [selAnho, setSelAnho] = useState(currYear);
  const [cotizaciones, setCotizaciones] = useState([]);

  // get monedas
  useEffect(() => {
    dispatch(iniLoading());
    axios
      .get(`${backend}/api/cotizaciones?get_monedas=1`)
      .then((response) => {
        let res = response.data;
        if (Array.isArray(res)) {
          setMonedas(res.filter((m) => m.codigo != 'GS'));
        } else {
          toast.error(res.error || 'Error obteniendo monedas.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error obteniendo monedas.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  }, []);

  // set flag moneda
  useEffect(() => {
    let f = null;
    if (selMoneda) {
      let m = monedas.filter((m) => m.id == selMoneda)[0];
      if (m.codigo == 'USD') {
        f = UsFlag;
      } else if (m.codigo == 'RS') {
        f = BrFlag;
      } else if (m.codigo == 'EUR') {
        f = UeFlag;
      } else if (m.codigo == 'ARS') {
        f = ArFlag;
      } else if (m.codigo == 'UYU') {
        f = UyFlag;
      }
    }
    setSelFlag(f);
  }, [selMoneda]);

  // setea cotizaciones actuales guardados en la BD
  const mergeCotizaciones = (cots1, cots2) => {
    cots1.forEach((cot1) => {
      let cot2 = cots2.filter(
        (cot2) => cot2.id_moneda == cot1.id_moneda && cot2.fecha == cot1.fecha
      )[0];
      if (cot2) {
        cot1.id = cot2.id;
        cot1.compra = parseInt(cot2.compra) || '';
        cot1.venta = parseInt(cot2.venta) || '';
      }
    });
    setCotizaciones([...cots1]);
  };

  // get cotizaciones
  const getCotizaciones = (cots1) => {
    let idMoneda = cots1[0].id_moneda,
      fechaIni = cots1[0].fecha,
      fechaFin = cots1[cots1.length - 1].fecha;
    dispatch(iniLoading());
    axios
      .get(
        `${backend}/api/cotizaciones?id_moneda=${idMoneda}&fecha_ini=${fechaIni}&fecha_fin=${fechaFin}`
      )
      .then((response) => {
        let res = response.data;
        if (Array.isArray(res)) {
          mergeCotizaciones(cots1, res);
        } else {
          toast.error(res.error || 'Error obteniendo cotizaciones.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error obteniendo cotizaciones.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // fill cotizaciones
  useEffect(() => {
    if (authData && (authData.user.rol == 'admin' || authData.user.rol == 'finanzas'))
      if (selMoneda && selMes && selAnho) {
        let a = 1;
        let b = new Date(selAnho, selMes, 0).getDate();
        let cots1 = [];
        for (let i = a; i <= b; i++) {
          let f = `${selAnho}-${selMes}-${`${i}`.padStart(2, '0')}`;
          cots1.push({
            fecha: f,
            id_moneda: selMoneda,
            compra: '',
            venta: '',
          });
        }
        getCotizaciones(cots1);
      } else {
        setCotizaciones([]);
      }
  }, [selMoneda, selMes, selAnho]);

  // handle input cotizaciones
  const handleChangeCots = (name, index, value) => {
    setCotizaciones((cots) => {
      cots[index][name] = value;
      return [...cots];
    });
  };

  const clickGuardar = () => {
    dispatch(iniLoading());
    axios
      .post(`${backend}/api/cotizaciones`, { cotizaciones })
      .then((response) => {
        let res = response.data;
        if (res.ok) {
          toast.success('Cotizaciones guardadas!');
        } else {
          toast.error(res.error || 'Error guardando cotizaciones.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error guardando cotizaciones.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  return (
    authData &&
    (authData.user.rol == 'admin' || authData.user.rol == 'finanzas') && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-5">
          <h4 className="font-weight-regular">Cotizaciones</h4>
        </div>

        {/* cabecera */}
        <div className="">
          {/* select moneda */}
          <div className="float-left mb-5">
            <div className="d-flex items-center">
              <div className="d-inline-block mr-5">
                <label htmlFor="moneda" className="d-block">
                  Moneda
                </label>
                <select
                  className={`form-control`}
                  id="moneda"
                  value={selMoneda}
                  onChange={(e) => {
                    setSelMoneda(e.target.value);
                  }}
                  style={{ width: '16rem' }}
                >
                  <option value="">Seleccione...</option>
                  {monedas.map((m) => (
                    <option key={m.id} value={m.id}>
                      {m.nombre}
                    </option>
                  ))}
                </select>
              </div>
              {selFlag && <img src={selFlag} alt="bandera" height={48} />}
            </div>
          </div>

          {/* select año */}
          <div className="float-right mb-5 ml-5">
            <label htmlFor="anho" className="d-block">
              Año
            </label>
            <select
              type="text"
              className={`form-control`}
              id="anho"
              value={selAnho}
              onChange={(e) => setSelAnho(e.target.value)}
              style={{ width: '8rem' }}
            >
              {[currYear - 1, currYear, currYear + 1].map((y) => (
                <option key={y} value={y}>
                  {y}
                </option>
              ))}
            </select>
          </div>

          {/* select mes */}
          <div className="float-right mb-5 ml-5">
            <label htmlFor="mes" className="d-block">
              Mes
            </label>
            <select
              className={`form-control`}
              id="mes"
              value={selMes}
              onChange={(e) => setSelMes(e.target.value)}
              style={{ width: '12rem' }}
            >
              {meses.map((m) => (
                <option key={m.val} value={m.val}>
                  {m.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="clear-fix"></div>
        </div>

        {/* tabla de cotizaciones */}
        {cotizaciones.length > 0 && (
          <div className="table-responsive mb-5">
            <table className="table-striped">
              <tbody>
                <tr>
                  <th className="text-center">Fecha</th>
                  <th className="text-center">Compra (Gs.)</th>
                  <th className="text-center">Venta (Gs.)</th>
                </tr>
                {cotizaciones.map((cot, i) => (
                  <tr key={cot.fecha}>
                    <td className="text-center">{dispIsoDateString(cot.fecha)}</td>
                    <td className="text-center">
                      <input
                        type="text"
                        className="form-control text-right"
                        value={cot.compra || ''}
                        onChange={(e) =>
                          handleChangeCots('compra', i, formatNumero(e.target.value))
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="text"
                        className="form-control text-right"
                        value={cot.venta || ''}
                        onChange={(e) => handleChangeCots('venta', i, formatNumero(e.target.value))}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {cotizaciones.length > 0 && (
          <div className="mb-5">
            <button className="btn btn-primary" onClick={clickGuardar}>
              <i className="fas fa-save"></i> Guardar
            </button>
            <button className="btn btn-secondary ml-5" onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left"></i> Volver
            </button>
          </div>
        )}
      </div>
    )
  );
}

export default Cotizaciones;
