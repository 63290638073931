import { useState } from 'react';
import { NumericFormat } from 'react-number-format';

/**
 * FormDatosBasicos Component
 */
function FormDatosBasicos({
  form,
  updateForm,
  siguientePagina,
  selectTiposDocumento,
  selectSucursales,
}) {
  const [campos, setCampos] = useState({
    idSucursal: form.idSucursal,
    idTipoDocumento: form.idTipoDocumento,
    numeroDocumento: form.numeroDocumento,
    nombre: form.nombre,
    apellido: form.apellido,
    estadoCivil: form.estadoCivil,
  });
  const [errores, setErrores] = useState({}); // form errores
  const cedula = selectTiposDocumento.find((tipo) => tipo.nombre == 'Cédula de identidad');

  const validarFormDatosBasicos = () => {
    let avanzar = true;
    setErrores({});

    if (campos.idTipoDocumento == '') {
      setErrores((e) => ({ ...e, idTipoDocumento: 'Seleccione un tipo de documento.' }));
      avanzar = false;
    }

    if (!campos.numeroDocumento) {
      setErrores((e) => ({ ...e, numeroDocumento: 'Ingrese un número de documento.' }));
      avanzar = false;
    }

    if (campos.nombre == '') {
      setErrores((e) => ({
        ...e,
        nombre: form.esPersona ? 'Introduzca un nombre.' : 'Introduzca razón social.',
      }));
      avanzar = false;
    }

    if (form.esPersona && campos.apellido == '') {
      setErrores((e) => ({ ...e, apellido: 'Introduzca un apellido.' }));
      avanzar = false;
    }

    if (form.esPersona && campos.estadoCivil == '') {
      setErrores((e) => ({ ...e, estadoCivil: 'Seleccione un estado civil.' }));
      avanzar = false;
    }

    if (!form.esPersona && campos.idSucursal == '') {
      setErrores((e) => ({ ...e, idSucursal: 'Seleccione una sucursal.' }));
      avanzar = false;
    }

    if (avanzar) siguientePagina();
  };

  const updateCampo = (name, value) => {
    if (name == 'idTipoDocumento') setCampos({ ...campos, [name]: value, numeroDocumento: '' });
    else setCampos({ ...campos, [name]: value });
    updateForm([name], value);
  };

  return (
    <>
      <div className="form-group">
        <label htmlFor="tipoDocumento">
          Tipo de documento <span className="text-danger">*</span>
        </label>
        <select
          id="tipoDocumento"
          className={`form-control form-control-100 ${errores.idTipoDocumento ? 'is-invalid' : ''}`}
          value={campos.idTipoDocumento}
          onChange={(e) => {
            updateCampo('idTipoDocumento', e.target.value);
          }}
        >
          <option value="">Selecione...</option>
          {selectTiposDocumento
            .filter((item) => (form.esPersona ? [1, 2, 3] : [3]).includes(item.id))
            .map((tipoDocumento) => (
              <option key={tipoDocumento.id} value={tipoDocumento.id}>
                {tipoDocumento.nombre}
              </option>
            ))}
        </select>
        <span className="text-danger">{errores.idTipoDocumento}</span>
      </div>
      <div className="form-group">
        <label htmlFor="documentNumber">
          {form.esPersona ? 'Número de documento' : 'Ruc'} <span className="text-danger">*</span>
        </label>
        {(cedula != undefined && campos.idTipoDocumento == cedula.id) || (
          <input
            id="documentNumber"
            type="text"
            autoComplete="off"
            className={`form-control form-control-100 ${
              errores.numeroDocumento ? 'is-invalid' : ''
            }`}
            value={campos.numeroDocumento}
            onChange={(e) => {
              updateCampo('numeroDocumento', e.target.value.toUpperCase());
            }}
            maxLength={10}
          />
        )}
        {(cedula != undefined && campos.idTipoDocumento != cedula.id) || (
          <NumericFormat
            id="documentNumber"
            value={campos.numeroDocumento || ''}
            onValueChange={(v, s) => updateCampo('numeroDocumento', v.value)}
            valueIsNumericString={true}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            className={`form-control form-control-100 text-left ${
              errores.numeroDocumento ? 'is-invalid' : ''
            }`}
            autoComplete="off"
            maxLength={10}
          />
        )}
        <span className="text-danger">{errores.numeroDocumento}</span>
      </div>
      <div className="form-group">
        <label htmlFor="name">
          {form.esPersona ? 'Nombre' : 'Razón social'} <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          autoComplete="off"
          className={`form-control form-control-100 ${errores.nombre ? 'is-invalid' : ''}`}
          value={campos.nombre}
          onChange={(e) => {
            updateCampo('nombre', e.target.value);
          }}
          maxLength={100}
        />
        <span className="text-danger">{errores.nombre}</span>
      </div>
      {form.esPersona && (
        <>
          <div className="form-group">
            <label htmlFor="surname">
              Apellido <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              autoComplete="off"
              className={`form-control form-control-100 ${errores.apellido ? 'is-invalid' : ''}`}
              value={campos.apellido}
              onChange={(e) => {
                updateCampo('apellido', e.target.value);
              }}
              maxLength={100}
            />
            <span className="text-danger">{errores.apellido}</span>
          </div>

          <div className="form-group">
            <label htmlFor="estadoCivil">
              Estado Civil <span className="text-danger">*</span>
            </label>
            <select
              id="estadoCivil"
              className={`form-control form-control-100 ${errores.estadoCivil ? 'is-invalid' : ''}`}
              value={campos.estadoCivil}
              onChange={(e) => {
                updateCampo('estadoCivil', e.target.value);
              }}
            >
              <option value="">Selecione...</option>
              <option value="SOLTERO/A">Soltero/a</option>
              <option value="CASADO/A">Casado/a</option>
              <option value="DIVORCIADO/A">Divorciado/a</option>
              <option value="VIUDO/A">Viudo/a</option>
            </select>
            <span className="text-danger">{errores.estadoCivil}</span>
          </div>
        </>
      )}

      {!form.esPersona && (
        <div className="form-group">
          <label htmlFor="sucursal">
            ¿Donde desea operar? <span className="text-danger">*</span>
          </label>
          <select
            id="sucursal"
            className={`form-control form-control-100 ${errores.idSucursal ? 'is-invalid' : ''}`}
            value={campos.idSucursal}
            onChange={(e) => {
              updateCampo('idSucursal', e.target.value);
            }}
          >
            <option value="">Seleccione...</option>
            {selectSucursales.map((sucursales) => (
              <option key={sucursales.id} value={sucursales.id}>
                {sucursales.nombreFormulario}
              </option>
            ))}
          </select>
          <span className="text-danger">{errores.idSucursal}</span>
        </div>
      )}

      <p>
        <span className="text-danger">*</span> Campos obligatorios
      </p>
      <div className="mt-4 mb-4">
        <button type="button" onClick={validarFormDatosBasicos} className="btn btn-primary">
          Siguiente <i className="ml-2 fas fa-arrow-right"></i>
        </button>
      </div>
    </>
  );
}

export default FormDatosBasicos;
