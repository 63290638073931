import PropTypes from 'prop-types';
import { getImageUrl } from '../Util';

/**
 * Website component: PanelFilaCards
 * codigo = cmp_fil_cards
 */
function PanelFilaCards({ muestra }) {
  return (
    <section className="testimonios-sect">
      <div className="container">
        {muestra.titulo && <h2 className="mb-2 text-center">{muestra.titulo}</h2>}
        <div className={`${muestra.estilo == 'square' ? 'tipos-tarjetas' : 'testimonios-list'}`}>
          {muestra.cards.map((c, i) => (
            <div
              className={`${muestra.estilo == 'square' ? 'tipo-tarjeta' : 'testimonio-item'}`}
              key={i}
            >
              <img
                src={getImageUrl(c.srcImgBg)}
                className={`testimonio-item__photo ${
                  muestra.estilo == 'square' ? 'img-fluid' : ''
                }`}
                style={{ height: muestra.estilo == 'square' ? '' : '140px' }}
              />
              <h4 className="mb-2">{c.subtitulo}</h4>
              {c.label && <p>{c.label}</p>}
              <blockquote
                style={{ marginBottom: '1em', textAlign: c.texto_justify ? 'justify' : 'center' }}
              >
                {c.texto}
              </blockquote>
              {c.enlace && (
                <a href={c.enlace} className="btn btn-primary">
                  Solicitar
                </a>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

// Setting default values for the props of component
PanelFilaCards.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelFilaCards.propTypes = {
  muestra: PropTypes.shape({
    titulo: PropTypes.string, // opcional
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        srcImgBg: PropTypes.string.isRequired,
        subtitulo: PropTypes.string.isRequired,
        label: PropTypes.string, // opcional
        texto: PropTypes.string.isRequired,
        enlace: PropTypes.string, // opcional
      })
    ).isRequired,
    estilo: PropTypes.string, // opcional, in {'square', 'circle'}
  }).isRequired,
};

export default PanelFilaCards;
