import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getDocUrl, getImageUrl } from '../Util';

/**
 * Website component: PanelListaDocumentos
 * codigo = cmp_list_docs
 */
function PanelListaDocumentos({ muestra }) {
  const [showing, setShowing] = useState(0);

  // ini showing number
  useEffect(() => {
    setShowing(Math.min(4, muestra.documentos.length));
  }, [muestra]);

  // los documentos se muestran de a 4
  const cargarMas = () => {
    //console.log('cargarMas', showing);
    setShowing((s) => Math.min(s + 4, muestra.documentos.length));
  };

  return (
    <section className="container">
      <h4>{muestra.titulo}</h4>

      <div className="memorias mb-5">
        {muestra.documentos.map((doc, i) => (
          <a
            href={getDocUrl(doc.enlaceDoc)}
            className={`memoria-item ${i} ${i >= showing ? 'd-none' : ''}`}
            target="_blank"
            rel="noopener noreferrer"
            key={i}
          >
            <i className="fas fa-file-pdf"></i>
            <p style={{ textAlign: doc.nombreDoc_justify ? 'justify' : 'left' }}>{doc.nombreDoc}</p>
            {doc.descripcion && (
              <p style={{ textAlign: doc.descripcion_justify ? 'justify' : 'left' }}>
                {doc.descripcion}
              </p>
            )}
          </a>
        ))}
      </div>

      {/* Boton para cargar mas si es necesario */}
      {showing < muestra.documentos.length && (
        <div className="text-center mb-5">
          <button className="btn btn-outline-secondary" onClick={cargarMas}>
            Cargar más...
          </button>
        </div>
      )}
    </section>
  );
}

// Setting default values for the props of component
PanelListaDocumentos.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelListaDocumentos.propTypes = {
  muestra: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    documentos: PropTypes.arrayOf(
      PropTypes.shape({
        enlaceDoc: PropTypes.string.isRequired,
        nombreDoc: PropTypes.string.isRequired,
        descripcion: PropTypes.string, // opcional
      })
    ).isRequired,
  }).isRequired,
};

export default PanelListaDocumentos;
