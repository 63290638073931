import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';

/**
 * PaginaAccion Component
 */
function PaginaAccion({ pagina }) {
  const backend = useSelector((state) => state.env.backend);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setVisible(pagina.visible);
  }, [pagina]);

  // cambiar visibilidad de la pagina
  const chVisible = () => {
    let putVisible = !visible;

    // confirm para inactivar
    if (putVisible == false) {
      if (!window.confirm('Por favor, confirme para inactivar.')) {
        return false;
      }
    }

    dispatch(iniLoading());
    axios
      .put(`${backend}/api/paginas/${pagina.id}`, { visible: putVisible })
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success(`Página ${putVisible ? 'activa' : 'inactiva'}!`);
          setVisible(putVisible); // refresh
        } else {
          toast.error(res.error || 'Error al cambiar la visibilidad.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al cambiar la visibilidad.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  return (
    <div>
      <Link
        to={`/admin/paginas/${pagina.id}`}
        className="btn btn-sm btn-outline-secondary"
        title="Editar"
      >
        <i className="fas fa-fw fa-pencil-alt"></i>
      </Link>
      <button
        className="btn btn-sm btn-outline-secondary ml-3"
        title={`Página ${visible ? 'activa' : 'inactiva'}, presione para ${
          visible ? 'inactivar' : 'activar'
        }.`}
        onClick={() => chVisible()}
      >
        <i className={`fas fa-fw fa-eye${visible ? '' : '-slash'}`}></i>
      </button>
      <Link
        to={`/site?page=${pagina.codigo}`}
        className="btn btn-sm btn-outline-secondary ml-3"
        title="Visitar"
      >
        <i className="fas fa-fw fa-link"></i>
      </Link>
    </div>
  );
}

// Typechecking props of the component
PaginaAccion.propTypes = {
  pagina: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default PaginaAccion;
