import PropTypes from 'prop-types';

/**
 * Website component: PanelPilaTextos
 * codigo = cmp_pila_txts
 */
function PanelPilaTextos({ muestra }) {
  return (
    <>
      {/* el estilo depende de si hay o no hay el titulo */}
      {muestra.titulo ? (
        <section className="container text-center">
          <div className="history-text">
            <h4 className="mb-5">{muestra.titulo}</h4>
            {muestra.parrafos.map((p, i) => (
              <div key={i}>
                {p.subtitulo && (
                  <h4 style={{ textAlign: p.subtitulo_justify ? 'justify' : 'center' }}>
                    {p.subtitulo}
                  </h4>
                )}
                <p style={{ textAlign: p.texto_justify ? 'justify' : 'center' }}>{p.texto}</p>
              </div>
            ))}
          </div>
        </section>
      ) : (
        <section className="misionvision">
          <div className="container">
            {muestra.parrafos.map((p, i) => (
              <div key={i} className="misionvision-item">
                <h2
                  className="misionvision-item__title"
                  style={{ textAlign: p.subtitulo_justify ? 'justify' : 'center' }}
                >
                  {p.subtitulo}
                </h2>
                <p style={{ textAlign: p.texto_justify ? 'justify' : 'center' }}>{p.texto}</p>
              </div>
            ))}
          </div>
        </section>
      )}
    </>
  );
}

// Setting default values for the props of component
PanelPilaTextos.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelPilaTextos.propTypes = {
  muestra: PropTypes.shape({
    titulo: PropTypes.string, // opcional
    parrafos: PropTypes.arrayOf(
      PropTypes.shape({
        subtitulo: PropTypes.string, // opcional
        texto: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default PanelPilaTextos;
