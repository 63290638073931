import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getImageUrl } from '../Util';
import { NumericFormat } from 'react-number-format';

/**
 * Website component: PanelPresentacionSitio
 * codigo = cmp_pres_sit
 */
function PanelPresentacionSitio({ muestra }) {
  let background = 'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 40%), ';
  background += `url(${getImageUrl(muestra.srcImgBg)}) center`;
  const [nroDocumento, setNroDocumento] = useState('');
  const navigate = useNavigate();

  const siguiente = () => {
    if (nroDocumento) {
      navigate('/site/producto?nroDocumento=' + nroDocumento);
    }
  };

  return (
    <div style={{ background, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <section className="hero">
        <div className="hero-text">
          <h1 style={{ textAlign: muestra.titulo1_justify ? 'justify' : 'left' }}>
            {muestra.titulo1}
          </h1>
          {muestra.label && (
            <p style={{ textAlign: muestra.label_justify ? 'justify' : 'left' }}>{muestra.label}</p>
          )}
        </div>
        <div className="hero-form">
          <h3>
            {muestra.titulo2}
            {muestra.titulo3 && (
              <>
                <br />
                {muestra.titulo3}
              </>
            )}
          </h3>
          <NumericFormat
            id="document"
            value={nroDocumento}
            onValueChange={(v, s) => setNroDocumento(v.value)}
            valueIsNumericString={true}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            autoComplete="off"
            placeholder={muestra.placeholder}
            maxLength={10}
          />
          <button type="submit" className="btn-hero-form c-pointer" onClick={siguiente}>
            Siguiente
          </button>
        </div>
      </section>
    </div>
  );
}

// Setting default values for the props of component
PanelPresentacionSitio.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelPresentacionSitio.propTypes = {
  muestra: PropTypes.shape({
    srcImgBg: PropTypes.string.isRequired, // ejemplo: 'http://localhost:8000/img/hero-bg.png'
    titulo1: PropTypes.string.isRequired,
    label: PropTypes.string, // opcional
    titulo2: PropTypes.string.isRequired,
    titulo3: PropTypes.string, // opcional
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
};

export default PanelPresentacionSitio;
