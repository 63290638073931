import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function CategoriaOrden({ idCategoria }) {
  const navigate = useNavigate();
  const backend = useSelector((state) => state.env.backend);
  const dispatch = useDispatch();
  const [categorias, setCategorias] = useState([]); // lista categorias

  // get categorias
  const getCategorias = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/categorias?idCategoriaOrden=${idCategoria}`);
      let res = response.data;
      if (Array.isArray(res)) {
        setCategorias(res);
      } else {
        toast.error('Error al obtener categorías.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener categorías.');
    }
    dispatch(finLoading());
  };

  const updateOrdenCategorias = async (items) => {
    dispatch(iniLoading());

    axios
      .post(`${backend}/api/setOrdenCategorias`, items)
      .then((response) => {
        let res = response.data;
        if (res.ok) {
          toast.success('Orden actualizado.');
        } else {
          toast.error('Error al actualizar el orden de las categorias.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al actualizar el orden de las categorias.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // cambia visiblidad de una categoria
  const chVisible = (idCategoria, visible) => {
    dispatch(iniLoading());
    axios
      .put(`${backend}/api/categorias/${idCategoria}`, { visible })
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success(`Categoria ahora ${visible ? 'visible' : 'oculto'}!`);
          getCategorias(); // refresh
        } else {
          toast.error(res.error || 'Error al cambiar visibilidad.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al cambiar visibilidad.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // elimina una categoria
  const eliminarCategoria = (categoria) => {
    let conf = window.confirm('Por favor, confirme para eliminar: "' + categoria.nombre + '"');
    if (conf) {
      dispatch(iniLoading());
      axios
        .delete(`${backend}/api/categorias/${categoria.id}`)
        .then((response) => {
          let res = response.data;
          if (res.ok) {
            toast.success(`Categoría eliminada.`);
            getCategorias(); // refresh
          } else {
            toast.error(res.error || 'Error al eliminar categoría.');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('Error al eliminar.');
        })
        .finally(() => {
          dispatch(finLoading());
        });
    }
  };

  // use effect get categoria
  useEffect(() => {
    if (idCategoria) {
      getCategorias();
    }
  }, [idCategoria]);

  /*****************************/
  /***** INI DRAG AND DROP *****/
  /*****************************/

  const grid = 8;

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  // on drag end
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(categorias, result.source.index, result.destination.index);
    setCategorias(items);
    updateOrdenCategorias(items);
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'var(--gray-2)' : '',
    // paddingTop: grid,
    // paddingBottom: grid,
    // width: 250,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid * 2}px 0`,
    // change background colour if dragging
    background: isDragging ? 'var(--gray-3)' : 'white',
    border: '1px solid var(--gray-3)',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // log lista componentes
  useEffect(() => {
    //console.log(categorias);
  }, [categorias]);

  /*****************************/
  /***** FIN DRAG AND DROP *****/
  /*****************************/

  return (
    <div className="mb-5">
      <div className="d-flex content-between items-center mb-5">
        <h4 className="font-weight-regular">Orden Categorías</h4>
      </div>

      {/* lista ordenable de categorias */}
      {categorias.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {categorias.map((item, index) => (
                  <Draggable key={`oc${item.id}`} draggableId={`${item.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        className="d-flex content-between items-center"
                      >
                        <div>
                          <span className="mr-3">
                            <i className="fas fa-ellipsis-v"></i>
                            <i className="fas fa-ellipsis-v"></i>
                          </span>
                          <span>
                            {item.nombre} /{' '}
                            {item.navbar ? 'Navbar' : item.footer ? 'Footer' : 'Social'}
                          </span>
                        </div>
                        {item.id != idCategoria && (
                          <>
                            <div>
                              <button
                                className="btn btn-sm btn-outline-secondary"
                                title="Editar"
                                onClick={() => navigate(`/admin/categorias/${item.id}`)}
                              >
                                <i className="fas fa-fw fa-pencil-alt"></i>
                              </button>
                              {item.visible ? (
                                <button
                                  className="btn btn-sm btn-outline-secondary ml-3"
                                  title="Está visible, presione para ocultar."
                                  onClick={() => chVisible(item.id, false)}
                                >
                                  <i className="fas fa-fw fa-eye"></i>
                                </button>
                              ) : (
                                <button
                                  className="btn btn-sm btn-outline-secondary ml-3"
                                  title="Está oculto, presione para mostrar."
                                  onClick={() => chVisible(item.id, true)}
                                >
                                  <i className="fas fa-fw fa-eye-slash"></i>
                                </button>
                              )}
                              <button
                                className="btn btn-sm btn-outline-secondary ml-3"
                                title="Eliminar"
                                onClick={() => eliminarCategoria(item)}
                              >
                                <i className="fas fa-fw fa-trash-alt"></i>
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {/* </div> */}
          {/* ))} */}
        </DragDropContext>
      ) : (
        <p>Aún no se agregó ninguna categoria.</p>
      )}
    </div>
  );
}

// Typechecking props of the component
CategoriaOrden.propTypes = {
  idCategoria: PropTypes.number.isRequired,
};

export default CategoriaOrden;
