import Mapa from './Mapa';

/**
 * MapHome Component
 */
function MapHome() {
  return (
    <div>
      <section className="container text-center header-section">
        <h4 className="font-weight-regular">Sucursales</h4>
      </section>
      <section className="content-sect">
        <div className="container">
          <Mapa />;
        </div>
      </section>
    </div>
  );
}

export default MapHome;
