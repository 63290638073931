import { useSelector, useDispatch } from 'react-redux';
import ReactLoading from 'react-loading';

/**
 * Full screen loading component
 * se muestra cuando el store loading.count > 0
 */
export default function Loading() {
  const loadingCount = useSelector((state) => state.loading.count);
  // const tema = useSelector(state => state.tema.value);
  const tema = 'default'; // default | dark

  const estilo = {
    top: '0',
    left: '0',
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: tema == 'default' ? 'rgba(255, 255, 255, 0.50)' : 'rgba(0, 0, 0, 0.50)',
    zIndex: '100',
  };

  return (
    <>
      {loadingCount > 0 && (
        <div style={estilo}>
          <div className="">
            <div className="">
              <div
                className=""
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100vh',
                }}
              >
                <ReactLoading
                  type={'spinningBubbles'}
                  color={tema == 'default' ? '#000' : '#fff'}
                  height={'64px'}
                  width={'64px'}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
