import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { dispGuarani, formatNumero, getImageUrl } from '../Util';

/**
 * Website component: PanelCotizaciones
 * codigo = cmp_cots
 */
function PanelCotizaciones({ muestra }) {
  const backend = useSelector((state) => state.env.backend);
  const dispatch = useDispatch();
  const [cotizaciones, setCotizaciones] = useState([]);

  // get cotizaciones de la fecha actual
  useEffect(() => {
    let fecha = new Date().toISOString().substring(0, 10);
    dispatch(iniLoading());
    axios
      .get(`${backend}/api/cotizaciones?fecha_ini=${fecha}&fecha_fin=${fecha}`)
      .then((response) => {
        let res = response.data;
        if (Array.isArray(res)) {
          setCotizaciones(res);
        } else {
          // toast.error(res.error || 'Error obteniendo cotizaciones.');
        }
      })
      .catch((error) => {
        console.error(error);
        // toast.error('Error obteniendo cotizaciones.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  }, []);

  // get cotizacion by moneda
  const getCotCompra = (moneda) => {
    let cot = cotizaciones.filter((cot) => cot.moneda.codigo == moneda)[0] || null;
    if (cot && cot.compra) {
      return dispGuarani(parseInt(cot.compra));
    } else {
      return 'N/A';
    }
  };

  // get cotizacion by moneda
  const getCotVenta = (moneda) => {
    let cot = cotizaciones.filter((cot) => cot.moneda.codigo == moneda)[0] || null;
    if (cot && cot.venta) {
      return dispGuarani(parseInt(cot.venta));
    } else {
      return 'N/A';
    }
  };

  return (
    <div className="modal">
      <div className="modal-header"></div>
      <div className="modal-body">
        <div className="cotizaciones-table">
          <div className="cotizacion">
            <div className="cotizacion__header">
              <div className="cotizacion__flag flag__us"></div>
              <h5>Dolar Estadounidense</h5>
            </div>
            <div className="cot-money">
              <div className="cot-money__concept">
                <p>Compra</p>
                <p className="cot-money__ammount">{getCotCompra('USD')}</p>
              </div>
              <div className="cot-money__concept">
                <p>Venta</p>
                <p className="cot-money__ammount">{getCotVenta('USD')}</p>
              </div>
            </div>
          </div>
          <div className="cotizacion">
            <div className="cotizacion__header">
              <div className="cotizacion__flag flag__ue"></div>
              <h5>Euro</h5>
            </div>
            <div className="cot-money">
              <div className="cot-money__concept">
                <p>Compra</p>
                <p className="cot-money__ammount">{getCotCompra('EUR')}</p>
              </div>
              <div className="cot-money__concept">
                <p>Venta</p>
                <p className="cot-money__ammount">{getCotVenta('EUR')}</p>
              </div>
            </div>
          </div>
          <div className="cotizacion">
            <div className="cotizacion__header">
              <div className="cotizacion__flag flag__br"></div>
              <h5>Real Brasileño</h5>
            </div>
            <div className="cot-money">
              <div className="cot-money__concept">
                <p>Compra</p>
                <p className="cot-money__ammount">{getCotCompra('RS')}</p>
              </div>
              <div className="cot-money__concept">
                <p>Venta</p>
                <p className="cot-money__ammount">{getCotVenta('RS')}</p>
              </div>
            </div>
          </div>
          <div className="cotizacion">
            <div className="cotizacion__header">
              <div className="cotizacion__flag flag__ar"></div>
              <h5>Peso Argentino</h5>
            </div>
            <div className="cot-money">
              <div className="cot-money__concept">
                <p>Compra</p>
                <p className="cot-money__ammount">{getCotCompra('ARS')}</p>
              </div>
              <div className="cot-money__concept">
                <p>Venta</p>
                <p className="cot-money__ammount">{getCotVenta('ARS')}</p>
              </div>
            </div>
          </div>
          <div className="cotizacion">
            <div className="cotizacion__header">
              <div className="cotizacion__flag flag__uy"></div>
              <h5>Peso Uruguayo</h5>
            </div>
            <div className="cot-money">
              <div className="cot-money__concept">
                <p>Compra</p>
                <p className="cot-money__ammount">{getCotCompra('UYU')}</p>
              </div>
              <div className="cot-money__concept">
                <p>Venta</p>
                <p className="cot-money__ammount">{getCotVenta('UYU')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// Setting default values for the props of component
PanelCotizaciones.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelCotizaciones.propTypes = {
  muestra: PropTypes.shape({}).isRequired,
};

export default PanelCotizaciones;
