import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// Auth data in local storage
const authIni = JSON.parse(localStorage.getItem('auth'));

// Config Axios
if (authIni) {
  axios.defaults.headers.common.Authorization = `Bearer ${authIni.token}`;
}

/**
 * Variable global que contiene usuario y token del login
 */
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    // Aqui se carga un objeto con los siguientes atributos:
    // user: una instancia del modelo User
    // token: string token de acceso
    // remember from local storage
    value: authIni,
  },
  reducers: {
    authLogin: (state, action) => {
      console.log('authLogin');
      state.value = action.payload;
      localStorage.setItem('auth', JSON.stringify(action.payload));
      axios.defaults.headers.common.Authorization = `Bearer ${action.payload.token}`;
    },
    authLogout: (state) => {
      console.log('authLogout');
      state.value = null;
      localStorage.removeItem('auth');
      axios.defaults.headers.common.Authorization = '';
    },
  },
});

export const { authLogin, authLogout } = authSlice.actions;

export default authSlice.reducer;
