import { useState } from 'react';
import DataTableBase from '../DataTableBase';
import UserAccion from './UserAccion';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function UserLista() {
  const authData = useSelector((state) => state.auth.value);

  const roles = {
    admin: 'Administrador',
    rrhh: 'RRHH',
    calidad: 'Calidad',
    comercial: 'Comercial',
    finanzas: 'Finanzas',
    seguros: 'Seguros',
  };

  const columns = [
    {
      name: 'Usuario',
      selector: (row) => row.user,
      sortable: true,
      sortField: 'user',
    },
    {
      name: 'Nombre',
      selector: (row) => row.name,
      sortable: true,
      sortField: 'name',
    },
    {
      name: 'Correo',
      selector: (row) => row.email,
      sortable: true,
      sortField: 'email',
    },
    {
      name: 'Rol',
      selector: (row) => (row.rol && `${row.rol}` in roles ? roles[row.rol] : '-'),
      sortable: true,
      sortField: 'rol',
    },
    {
      name: 'Acciones',
      cell: (row) => <UserAccion user={row} />,
    },
  ];

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-3">
          <h4 className="font-weight-regular">Gestión de Usuarios</h4>
          <Link to={'/admin/users/new'} className="btn btn-primary">
            <i className="fas fa-fw fa-plus"></i> Nuevo Usuario
          </Link>
        </div>

        <div>
          <DataTableBase url={'/api/users'} columns={columns} />
        </div>
      </div>
    )
  );
}

export default UserLista;
