import { useState, memo, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { useSelector } from 'react-redux';

const containerStyle = {
  width: '100%',
  height: '400px',
};

// distancia para que al inicio muestre todo asunción
const zoomInicial = 11;

function LocationPicker({ setCoordenadas, coordenadaInicial }) {
  const googleMapsApiKey = useSelector((state) => state.env.googleMapsApiKey);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
  });

  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null); // marcador

  const onLoadMap = (map) => {
    setMap(map);
  };

  const onLoadMarker = (marker) => {
    setMarker(marker);
  };

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onCenterChanged = () => {
    if (map && marker) {
      let newMarker = { ...marker, position: map.center };
      setMarker(newMarker); // actualizar marcador
      setCoordenadas(map.center); // enviar coordenadas al padre
    }
  };

  return isLoaded ? (
    <GoogleMap
      clickableIcons={false}
      mapContainerStyle={containerStyle}
      center={coordenadaInicial}
      zoom={zoomInicial}
      onLoad={onLoadMap}
      onCenterChanged={onCenterChanged}
      onUnmount={onUnmount}
    >
      {/* marcador */}
      {map && <Marker onLoad={onLoadMarker} clickable={false} position={map.center} />}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default memo(LocationPicker);
