import PropTypes from 'prop-types';
import { getImageUrl } from '../Util';

/**
 * Website component: PanelImagenTextoHorizontal
 * codigo = cmp_img_txt_hrz
 */
function PanelImagenTextoHorizontal({ muestra }) {
  return (
    <section className="container">
      <div className="section-photo-text">
        {(muestra.direccion === 'left' || !muestra.direccion) && (
          <div className="section-w-photo">
            <img
              src={getImageUrl(muestra.srcImgBg)}
              className="img-fluid"
              alt="imagen encolumnada"
            />
          </div>
        )}
        <div className="section-w-text">
          {muestra.titulo && (
            <h3 className="mb-4" style={{ textAlign: muestra.titulo_justify ? 'justify' : 'left' }}>
              {muestra.titulo}
            </h3>
          )}

          {muestra.parrafos.map((p, i) => (
            <div className="mb-5" key={i}>
              <h4 className="mb-2" style={{ textAlign: p.subtitulo_justify ? 'justify' : 'left' }}>
                {p.subtitulo}
              </h4>
              {p.texto && (
                <p style={{ textAlign: p.texto_justify ? 'justify' : 'left' }}>{p.texto}</p>
              )}
            </div>
          ))}

          {muestra.enlace && muestra.label && <a href={muestra.enlace}>{muestra.label}</a>}
        </div>
        {muestra.direccion === 'right' && (
          <div className="section-w-photo">
            <img
              src={getImageUrl(muestra.srcImgBg)}
              className="img-fluid"
              alt="imagen encolumnada"
            />
          </div>
        )}
      </div>
    </section>
  );
}

// Setting default values for the props of component
PanelImagenTextoHorizontal.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelImagenTextoHorizontal.propTypes = {
  muestra: PropTypes.shape({
    srcImgBg: PropTypes.string.isRequired, // ejemplo: 'https://dummyimage.com/640x640/e6e6e6/f5f5f5'
    titulo: PropTypes.string, // opcional
    // array type should be supported
    parrafos: PropTypes.arrayOf(
      PropTypes.shape({
        subtitulo: PropTypes.string.isRequired,
        texto: PropTypes.string, // opcional
      })
    ).isRequired,
    enlace: PropTypes.string, // opcional
    label: PropTypes.string, // opcional
    direccion: PropTypes.string, // opcional, in ['left', 'right']
  }).isRequired,
};

export default PanelImagenTextoHorizontal;
