import { useState, useEffect } from 'react';
import DataTableBase from '../DataTableBase';
import ProductoAccion from './ProductoAccion';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { dispIsoDateString } from '../Util';

/**
 * ProductoLista Component
 */
function ProductoLista() {
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const dispatch = useDispatch();
  const [seleccionados, setSeleccionados] = useState([]); // filas seleccionadas

  const columns = [
    {
      name: 'FECHA',
      selector: (row) => {
        let fechaHora = row.created_at.split('T');
        let fecha = fechaHora[0].split('-');

        return `${fecha[2]}/${fecha[1]}/${fecha[0]} ${fechaHora[1].substring(0, 5)}`;
      },
      sortable: true,
      sortField: 'created_at',
      wrap: true,
    },
    {
      name: 'APELLIDO',
      selector: (row) => (row.apellido ? row.apellido : '-'),
      sortable: true,
      sortField: 'apellido',
    },
    {
      name: 'NOMBRE / RAZON SOCIAL',
      selector: (row) => row.nombre,
      sortable: true,
      sortField: 'nombre',
    },
    {
      name: 'N° DOCUMENTO',
      selector: (row) => row.numeroDocumento,
      sortable: true,
      sortField: 'numeroDocumento',
    },
    {
      name: 'TIPO',
      selector: (row) => (row.esPersona ? 'PERSONA' : 'EMPRESA'),
      sortable: true,
      sortField: 'esPersona',
    },
    {
      name: 'PRODUCTO',
      selector: (row) => (row.producto ? row.producto.nombre : '-'),
      sortable: true,
      sortField: 'idProducto',
    },
    {
      name: 'ESTADO CIVIL',
      soloExport: true,
      selector: (row) => row.estadoCivil || '-',
    },
    {
      name: 'SUCURSAL DE OPERACIÓN',
      selector: (row) => row.sucursal.nombre,
      wrap: true,
    },
    {
      name: 'ESTADO',
      selector: (row) => (row.estado ? row.estado.nombre : '-'),
      sortable: true,
      sortField: 'idEstado',
    },
    {
      name: 'DIRECCIÓN',
      soloExport: true,
      selector: (row) => row.direccion || '-',
    },
    {
      name: 'N° DOMICILIO',
      soloExport: true,
      selector: (row) => row.numeroDomicilio || '-',
    },
    {
      name: 'REFERENCIA',
      soloExport: true,
      selector: (row) => row.referencia || '-',
    },
    {
      name: 'DEPARTAMENTO',
      soloExport: true,
      selector: (row) =>
        row.ciudad && row.ciudad.departamento ? row.ciudad.departamento.nombre : '-',
    },
    {
      name: 'CIUDAD',
      soloExport: true,
      selector: (row) => (row.ciudad ? row.ciudad.nombre : '-'),
    },
    {
      name: 'BARRIO',
      soloExport: true,
      selector: (row) => row.barrio || '-',
    },
    {
      name: 'TELÉFONO',
      soloExport: true,
      selector: (row) => row.telefono || '-',
    },
    {
      name: 'EMAIL',
      soloExport: true,
      selector: (row) => row.email || '-',
    },
    {
      name: 'LUGAR TRABAJO',
      soloExport: true,
      selector: (row) => row.lugarTrabajo || '-',
    },
    {
      name: 'SALARIO ACTUAL',
      soloExport: true,
      selector: (row) => row.salarioActual || '-',
    },
    {
      name: 'TELÉF. LABORAL',
      soloExport: true,
      selector: (row) => row.telefonoLaboral || '-',
    },
    {
      name: 'MONEDA',
      soloExport: true,
      selector: (row) => row.moneda.nombre,
    },
    {
      name: 'MONTO SOLICITADO',
      soloExport: true,
      selector: (row) =>
        row.montoPrestamo ? Number(row.montoPrestamo).toLocaleString('es-PY') : '-',
    },
    {
      name: 'LÍNEA CRÉDITO',
      soloExport: true,
      selector: (row) =>
        row.lineaCredito ? Number(row.montoPrestamo).toLocaleString('es-PY') : '-',
    },
    {
      name: 'Acciones',
      cell: (row) => <ProductoAccion producto={row} />,
      width: '8%',
    },
  ];

  // FILTROS
  const [filtroBusFechaDesde, setFiltroBusFechaDesde] = useState('');
  const [filtroBusFechaHasta, setFiltroBusFechaHasta] = useState('');
  const [filtroBusNombre, setFiltroBusNombre] = useState('');
  const [filtroBusApellido, setFiltroBusApellido] = useState('');
  const [filtroBusDoc, setFiltroBusDoc] = useState('');
  const [filtroBusProducto, setFiltroBusProducto] = useState('');
  const [filtroBusEstado, setFiltroBusEstado] = useState('');
  const [filtroBusSucursal, setFiltroBusSucursal] = useState('');
  const [selectProductos, setSelectProductos] = useState([]);
  const [selectSucursales, setSelectSucursales] = useState([]);
  const [selectEstados, setSelectEstados] = useState([]);
  const [dtFiltros, setDtFiltros] = useState({}); // data table filtros
  const [ini, setIni] = useState(true);
  const listar = () => {
    setDtFiltros({
      fechaDesde: filtroBusFechaDesde,
      fechaHasta: filtroBusFechaHasta,
      nombre: filtroBusNombre,
      apellido: filtroBusApellido,
      documento: filtroBusDoc,
      idProducto: filtroBusProducto,
      idEstado: filtroBusEstado,
      idSucursal: filtroBusSucursal,
    });
  };
  const limpiar = () => {
    setFiltroBusFechaDesde('');
    setFiltroBusFechaHasta('');
    setFiltroBusNombre('');
    setFiltroBusApellido('');
    setFiltroBusDoc('');
    setFiltroBusProducto('');
    setFiltroBusEstado('');
    setFiltroBusSucursal('');
    setDtFiltros({});
    setSeleccionados([]);
  };

  // use effect get sucursal
  useEffect(() => {
    if (ini) {
      setIni(ini);
      void getSucursales();
      void getEstados();
      if (authData.user.rol === 'admin' || authData.user.rol === 'comercial') void getProductos();
    }
  }, [ini]);

  const getProductos = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/productos`);
      let res = response.data;

      if (authData.user.rol === 'comercial') res = res.filter((prod) => [1, 8].includes(prod.id));

      if (Array.isArray(res)) {
        setSelectProductos(res);
      } else {
        setSelectProductos([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener productos.');
    }
    dispatch(finLoading());
  };

  const getEstados = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/estados`);
      let res = response.data;
      if (Array.isArray(res)) {
        setSelectEstados(res);
      } else {
        setSelectEstados([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener estados.');
    }
    dispatch(finLoading());
  };

  const getSucursales = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/sucursales`);
      let res = response.data.data;
      if (Array.isArray(res)) {
        console.log(res);
        setSelectSucursales(res);
      } else {
        setSelectSucursales([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener sucursales.');
    }
    dispatch(finLoading());
  }

  const fnFiltrosPrint = (dtFiltros) => {
    let res = {};
    if (dtFiltros.fechaDesde) {
      res['Fecha Desde'] = dispIsoDateString(dtFiltros.fechaDesde);
    }
    if (dtFiltros.fechaHasta) {
      res['Fecha Hasta'] = dispIsoDateString(dtFiltros.fechaHasta);
    }
    if (dtFiltros.nombre) {
      res['Búsq. Nombre'] = dtFiltros.nombre;
    }
    if (dtFiltros.apellido) {
      res['Búsq. Apellido'] = dtFiltros.apellido;
    }
    if (dtFiltros.documento) {
      res['Búsq. Documento'] = dtFiltros.documento;
    }
    if (dtFiltros.idEstado) {
      res['Estado'] = selectEstados.filter((e) => e.id === parseInt(dtFiltros.idEstado))[0].nombre;
    }
    if (dtFiltros.idSucursal) {
      res['Sucursal'] = selectSucursales.filter((s) => s.id === parseInt(dtFiltros.idSucursal))[0].nombre;
    }
    return res;
  };

  const handleSeleccionar = ({ selectedRows }) => {
      setSeleccionados(selectedRows);
  };

  return (
    authData &&
    (authData.user.rol === 'admin' ||
      authData.user.rol === 'comercial' ||
      authData.user.rol === 'seguros') && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-3">
          <h4 className="font-weight-regular">Solicitudes de Productos</h4>
        </div>

        {/* FILTROS */}
        <div className="d-flex- items-start-">
          {/* filtro fecha desde */}
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="fechadesde">Buscar / fecha desde</label>
            <input
              id="fechadesde"
              type="date"
              className="form-control"
              value={filtroBusFechaDesde}
              onChange={(e) => setFiltroBusFechaDesde(e.target.value)}
              style={{ width: '12rem' }}
              autoComplete="off"
            />
          </div>

          {/* filtro fecha hasta */}
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="fechahasta">Buscar / fecha hasta</label>
            <input
              id="fechahasta"
              type="date"
              className="form-control"
              value={filtroBusFechaHasta}
              onChange={(e) => setFiltroBusFechaHasta(e.target.value)}
              style={{ width: '12rem' }}
              autoComplete="off"
            />
          </div>

          {/* filtro apellido */}
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="apellido">Buscar / apellido</label>
            <input
              id="apellido"
              type="text"
              className="form-control"
              value={filtroBusApellido}
              onChange={(e) => setFiltroBusApellido(e.target.value)}
              style={{ width: '12rem' }}
              autoComplete="off"
            />
          </div>

          {/* filtro nombre */}
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="nombre">Buscar / nombre</label>
            <input
              id="nombre"
              type="text"
              className="form-control"
              value={filtroBusNombre}
              onChange={(e) => setFiltroBusNombre(e.target.value)}
              style={{ width: '12rem' }}
              autoComplete="off"
            />
          </div>

          {/* filtro documento */}
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="doc">Buscar / n° documento</label>
            <input
              id="doc"
              type="text"
              className="form-control"
              value={filtroBusDoc}
              onChange={(e) => setFiltroBusDoc(e.target.value)}
              style={{ width: '12rem' }}
              autoComplete="off"
            />
          </div>

          {/* filtro producto */}
          {(authData.user.rol === 'admin' || authData.user.rol === 'comercial') && (
            <div className="float-left form-group mb-3 mr-5">
              <label htmlFor="producto">Buscar / producto</label>
              <select
                id="producto"
                className={'form-control'}
                value={filtroBusProducto}
                onChange={(e) => setFiltroBusProducto(e.target.value)}
                style={{ width: '12rem' }}
              >
                <option value="">Todos</option>
                {selectProductos.map((estado) => (
                  <option key={estado.id} value={estado.id}>
                    {estado.nombre}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* filtro sucursal */}
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="sucursal">Buscar / sucursal</label>
            <select
              id="sucursal"
              className={'form-control'}
              value={filtroBusSucursal}
              onChange={(e) => setFiltroBusSucursal(e.target.value)}
              style={{ width: '12rem' }}
            >
              <option value="">Todos</option>
              {selectSucursales.map((sucursal) => (
                <option key={sucursal.id} value={sucursal.id}>
                  {sucursal.nombre}
                </option>
              ))}
            </select>
          </div>

          {/* filtro estado */}
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="estado">Buscar / estado</label>
            <select
              id="estado"
              className={'form-control'}
              value={filtroBusEstado}
              onChange={(e) => setFiltroBusEstado(e.target.value)}
              style={{ width: '12rem' }}
            >
              <option value="">Todos</option>
              {selectEstados.map((estado) => (
                <option key={estado.id} value={estado.id}>
                  {estado.nombre}
                </option>
              ))}
            </select>
          </div>

          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="posicion">&nbsp;</label>
            <button className="btn btn-outline-secondary" onClick={listar}>
              <i className="fas fa-fw fa-filter"></i> Listar
            </button>
          </div>
          <div className="float-left form-group mb-3 mr-5">
            <label htmlFor="posicion">&nbsp;</label>
            <button className="btn btn-outline-secondary" onClick={limpiar}>
              <i className="fas fa-fw fa-broom"></i> Limpiar
            </button>
          </div>
        </div>

        <div className="clear-fix"></div>

        <div>
          <DataTableBase
            url={'/api/formularios_producto'}
            columns={columns}
            filtros={dtFiltros}
            exportar={true}
            fnFiltrosPrint={fnFiltrosPrint}
            horizontal={true}
            titulo={'Solicitudes de Productos'}
            dataSelect={seleccionados}
            chunkLen={8}
            exportarTodasColumnas={false}
            selectableRows
            onSelectedRowsChange={handleSeleccionar}
          />
        </div>
      </div>
    )
  );
}

export default ProductoLista;
