import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * RRHHAccion Component
 */
function RRHHAccion({ rrhh }) {
  return (
    <div>
      <Link to={`/admin/rrhh/${rrhh.id}`} className="btn btn-sm btn-outline-secondary" title="Ver">
        <i className="fas fa-fw fa-eye"></i>
      </Link>
    </div>
  );
}

// Typechecking props of the component
RRHHAccion.propTypes = {
  rrhh: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default RRHHAccion;
