import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

import { validateOnInputNumber } from '../Util';
import { NumericFormat, PatternFormat } from 'react-number-format';

/**
 * FormReclamos Component
 */
function FormReclamos() {
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const captchaSiteKey = useSelector((state) => state.env.captchaSiteKey);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const captchaRef = useRef(null);

  const { id } = useParams(); // id de la pagina, null al crear

  const formInitialState = {
    esCliente: true,
    numeroDocumento: '',
    nombre: '',
    apellido: '',
    idFormatoTelefono: '',
    telefono: '',
    email: '',
    idProducto: '',
    mensaje: '',
  };

  const [form, setForm] = useState(formInitialState);
  const [errores, setErrores] = useState({});
  const [maxLengthTelefono, setMaxLengthTelefono] = useState(undefined);
  const [selectFormatosTelefono, setSelectFormatosTelefono] = useState([]);
  const [selectProductos, setSelectProductos] = useState([]);
  const [formatoTelefonoParaguay, setFormatoTelefonoParaguay] = useState(null);

  if (maxLengthTelefono == undefined && formatoTelefonoParaguay)
    setMaxLengthTelefono(formatoTelefonoParaguay);

  const validarFormReclamos = () => {
    let enviar = true;
    setErrores({});

    if (!form.numeroDocumento.trim()) {
      setErrores((e) => ({ ...e, numeroDocumento: 'Ingrese su número de documento.' }));
      enviar = false;
    }

    if (!form.nombre.trim()) {
      setErrores((e) => ({ ...e, nombre: 'Ingrese su nombre.' }));
      enviar = false;
    }

    if (!form.apellido.trim()) {
      setErrores((e) => ({ ...e, apellido: 'Ingrese su apellido.' }));
      enviar = false;
    }

    if (form.idFormatoTelefono == '') {
      setErrores((e) => ({ ...e, idFormatoTelefono: 'Seleccione un país.' }));
      enviar = false;
    }

    if (form.telefono == '') {
      setErrores((e) => ({ ...e, telefono: 'Introduzca un número de teléfono.' }));
      enviar = false;
    } else if (form.telefono.length > maxLengthTelefono.max_digitos) {
      setErrores((e) => ({
        ...e,
        telefono: 'El nro de teléfono es muy largo para el país seleccionado.',
      }));
      enviar = false;
    }

    if (!form.email.trim()) {
      setErrores((e) => ({ ...e, email: 'Ingrese una dirección de correo electrónico.' }));
      enviar = false;
    } else if (!form.email.toString().includes('@')) {
      setErrores((e) => ({
        ...e,
        email: 'La dirección de correo electrónico debe contener el caracter "@".',
      }));
      enviar = false;
      // } else if (!form.email.toString().includes('.COM')) {
    } else if (
      form.email.toString().match(/.*\@.*\.\w{2,3}/g) === null ||
      form.email.charAt(0) == '@'
    ) {
      setErrores((e) => ({
        ...e,
        // email: 'La dirección de correo electrónico debe contener ".COM".',
        email: 'Ingrese una dirección de correo electrónico válida.',
      }));
      enviar = false;
    }

    if (form.idProducto == '') {
      setErrores((e) => ({ ...e, idProducto: 'Seleccione producto.' }));
      enviar = false;
    }

    if (form.mensaje == '') {
      setErrores((e) => ({ ...e, mensaje: 'Ingrese un mensaje' }));
      enviar = false;
    }

    if (!captchaRef.current.getValue()) {
      setErrores((e) => ({ ...e, captcha: 'Por favor, complete el captcha.' }));
      return;
    }

    if (enviar) enviarFormulario();
  };

  const enviarFormulario = () => {
    dispatch(iniLoading());
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    axios
      .post(`${backend}/api/reclamos`, { ...form, token })
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Formulario enviado!');
          setForm(formInitialState);
          setMaxLengthTelefono(undefined);
          navigate('/site/reclamos');
        } else {
          toast.error(res.error || 'Error al enviar formulario. Verifique los datos cargados.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al enviar el formulario.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  const onChangeForm = (name, value) => {
    if (!id) if (name == 'idFormatoTelefono') setForm({ ...form, [name]: value, telefono: '' });
    if (name == 'esCliente') {
      form.esCliente = value;
      setForm({ ...form, [name]: value });
    } else setForm({ ...form, [name]: value });
  };

  // get user by id
  const getFormReclamos = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/reclamos/${id}`);
      let res = response.data;
      if (res.id) {
        setForm(res);
      } else {
        toast.error('Error al obtener el formulario.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener el formulario.');
    }
    dispatch(finLoading());
  };

  const getFormatosTelefono = async () => {
    // dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/formatosTelefono`);
      let res = response.data;
      setFormatoTelefonoParaguay(
        res.filter((ft) => ft.codigo_pais == 'PY').length > 0
          ? res.filter((ft) => ft.codigo_pais == 'PY')[0]
          : null
      );
      if (Array.isArray(res)) {
        setSelectFormatosTelefono(res);
      } else {
        setSelectFormatosTelefono([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener formatos de telefono.');
    }
    // dispatch(finLoading());
  };

  const getProductos = async () => {
    // dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/productos`);
      let res = response.data;
      if (Array.isArray(res)) {
        setSelectProductos(res);
      } else {
        setSelectProductos([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener productos.');
    }
    // dispatch(finLoading());
  };

  // use effect get formulario rrhh
  useEffect(() => {
    if (id) {
      if (authData && (authData.user.rol == 'admin' || authData.user.rol == 'calidad'))
        getFormReclamos();
    } else {
      getFormatosTelefono();
      getProductos();
    }
  }, [id]);

  useEffect(() => {
    if (formatoTelefonoParaguay != null) {
      setForm({
        ...form,
        idFormatoTelefono: formatoTelefonoParaguay.id,
      });
    }
  }, [formatoTelefonoParaguay]);

  return (
    <div className="modal">
      <div className="modal-header">
        <h1>Reclamos</h1>
      </div>

      <div className="modal-body">
        <div className="form-group">
          <label htmlFor="escliente">
            Sos cliente de Zeta Banco? <span className="text-danger">*</span>
          </label>
          <div style={{ display: 'flex' }}>
            <label htmlFor="escliente" style={{ marginRight: '2rem' }}>
              <input
                type="radio"
                id="escliente"
                name="grupoescliente"
                checked={form.esCliente}
                onChange={(e) => {
                  onChangeForm('esCliente', true);
                }}
                disabled={id}
              />
              Sí
            </label>
            <label htmlFor="noescliente">
              <input
                type="radio"
                id="noescliente"
                name="grupoescliente"
                checked={!form.esCliente}
                onChange={(e) => {
                  onChangeForm('esCliente', false);
                }}
                disabled={id}
              />
              No
            </label>
          </div>
          <span className="text-danger">{errores.esCliente}</span>
        </div>
        <div className="form-group">
          <label htmlFor="Nombre">
            Nombre <span className="text-danger">*</span>
          </label>
          <input
            id="Nombre"
            type="text"
            className={`form-control form-control-100 ${errores.nombre ? 'is-invalid' : ''}`}
            value={form.nombre}
            onChange={(e) => onChangeForm('nombre', e.target.value)}
            autoComplete="off"
            maxLength="100"
            disabled={id}
          />
          <span className="text-danger">{errores.nombre}</span>
        </div>
        <div className="form-group">
          <label htmlFor="Apellido">
            Apellido <span className="text-danger">*</span>
          </label>
          <input
            id="Apellido"
            type="text"
            className={`form-control form-control-100 ${errores.apellido ? 'is-invalid' : ''}`}
            value={form.apellido}
            onChange={(e) => onChangeForm('apellido', e.target.value)}
            autoComplete="off"
            maxLength="100"
            disabled={id}
          />
          <span className="text-danger">{errores.apellido}</span>
        </div>
        <div className="form-group">
          <label htmlFor="document">
            Cédula <span className="text-danger">*</span>
          </label>
          <NumericFormat
            id="document"
            value={form.numeroDocumento || ''}
            onValueChange={(v, s) => onChangeForm('numeroDocumento', v.value)}
            valueIsNumericString={true}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            className={`form-control form-control-100 text-left ${
              errores.numeroDocumento ? 'is-invalid' : ''
            }`}
            disabled={id}
            autoComplete="off"
            maxLength={10}
          />
          <span className="text-danger">{errores.numeroDocumento}</span>
        </div>
        <div className="form-group">
          <label htmlFor="mobilePhone">
            Teléfono móvil <span className="text-danger">*</span>
          </label>
          <div style={{ display: 'flex' }}>
            {id && (
              <>
                <input
                  type="text"
                  autoComplete="off"
                  className={`form-control form-control-100`}
                  value={form.formato_telefono ? form.formato_telefono.pais : ''}
                  onChange={(e) => {
                    onChangeForm('pais', e.target.value);
                  }}
                  onLoad={(e) => console.log('onLoad')}
                  style={{ width: '40%' }}
                  disabled
                />
                <input
                  type="text"
                  autoComplete="off"
                  className={`form-control form-control-100`}
                  value={form.formato_telefono ? form.formato_telefono.prefijo : ''}
                  onChange={(e) => {
                    onChangeForm('prefijo', e.target.value);
                  }}
                  style={{ width: '30%' }}
                  disabled
                />
                <input
                  id="mobilePhone"
                  type="text"
                  autoComplete="off"
                  className={`form-control form-control-100`}
                  value={form.telefono}
                  onChange={(e) => {
                    onChangeForm('telefono', e.target.value);
                  }}
                  style={{ width: '100%' }}
                  disabled
                />
              </>
            )}
            {!id && (
              <>
                <select
                  id="idFormatoTelefono"
                  className={`form-control form-control-100 ${
                    errores.idFormatoTelefono ? 'is-invalid' : ''
                  }`}
                  value={form.idFormatoTelefono}
                  onChange={(e) => {
                    setMaxLengthTelefono(
                      selectFormatosTelefono.find((t) => t.id == e.target.value)
                    );
                    onChangeForm('idFormatoTelefono', e.target.value);
                  }}
                  style={{ width: '10rem' }}
                  disabled={id}
                >
                  <option value="">Seleccione...</option>
                  {selectFormatosTelefono.map((telefono) => (
                    <option key={telefono.id} value={telefono.id}>
                      {`${telefono.pais} ${telefono.prefijo}`}
                    </option>
                  ))}
                </select>
                <PatternFormat
                  id="mobilePhone"
                  value={form.telefono}
                  format={`${
                    maxLengthTelefono !== undefined && maxLengthTelefono
                      ? `${maxLengthTelefono.formato}`
                      : ''
                  }`}
                  className={`form-control form-control-100 ${
                    errores.telefono ? 'is-invalid' : ''
                  }`}
                  autoComplete="off"
                  style={{ width: '100%' }}
                  onValueChange={(v, s) => onChangeForm('telefono', validateOnInputNumber(v.value))}
                  valueIsNumericString={true}
                  placeholder={`${
                    maxLengthTelefono !== undefined && maxLengthTelefono
                      ? maxLengthTelefono.placeholder
                      : ''
                  }`}
                  mask="_"
                  disabled={id}
                />
              </>
            )}
          </div>
          <span className="text-danger">{errores.idFormatoTelefono}</span>
          <span className="text-danger">{errores.telefono}</span>
        </div>
        <div className="form-group">
          <label htmlFor="email">
            Email <span className="text-danger">*</span>
          </label>
          <input
            id="email"
            type="email"
            className={`form-control form-control-100 ${errores.email ? 'is-invalid' : ''}`}
            value={form.email}
            onChange={(e) => onChangeForm('email', e.target.value.toLowerCase())}
            autoComplete="off"
            maxLength="50"
            disabled={id}
          />
          <span className="text-danger">{errores.email}</span>
        </div>
        <div className="form-group">
          <label htmlFor="producto">
            Producto de referencia <span className="text-danger">*</span>
          </label>
          {!id && (
            <select
              id="producto"
              className={`form-control form-control-100 ${errores.idProducto ? 'is-invalid' : ''}`}
              value={form.idProducto}
              onChange={(e) => {
                onChangeForm('idProducto', e.target.value);
              }}
            >
              <option value="">Seleccione...</option>
              {selectProductos.map((producto) => (
                <option key={producto.id} value={producto.id}>
                  {producto.nombre}
                </option>
              ))}
            </select>
          )}
          {id && (
            <input
              id="nombre"
              type="texto"
              className={`form-control form-control-100`}
              value={form.producto ? form.producto.nombre : ''}
              onChange={(e) => onChangeForm('nombre', e.target.value)}
              autoComplete="off"
              maxLength="50"
              disabled={true}
            />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="mensaje">
            Mensaje <span className="text-danger">*</span>
          </label>
          <textarea
            id="mensaje"
            // type="mensaje"
            className={`form-control form-control-100 ${errores.mensaje ? 'is-invalid' : ''}`}
            value={form.mensaje}
            onChange={(e) => onChangeForm('mensaje', e.target.value)}
            autoComplete="off"
            maxLength="2000"
            rows={10}
            style={{ width: '100%', resize: 'none' }}
            disabled={id}
          />
          <span className="text-danger">{errores.mensaje}</span>
        </div>
        {id && (
          <>
            <a
              className="btn btn-primary"
              href={`${backend}/api/export_formularios_reclamo/${form.id}`}
              target="_blank"
            >
              <i className="fas fa-print"></i> Imprimir
            </a>
            <button className="btn btn-secondary ml-5" onClick={() => navigate('/admin/reclamos')}>
              <i className="fas fa-arrow-left"></i> Volver
            </button>
          </>
        )}
        {!id && (
          <>
            {/* captcha */}
            <ReCAPTCHA sitekey={captchaSiteKey} ref={captchaRef} />
            <span className="text-danger">{errores.captcha}</span>
            <p className="mt-5">
              <span className="text-danger">*</span> Campos obligatorios
            </p>
            <div className="form-group" style={{ marginTop: '2rem' }}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => validarFormReclamos()}
              >
                Enviar
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default FormReclamos;
