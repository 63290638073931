import PropTypes from 'prop-types';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';

/**
 * SuscripcionAccion Component
 */
function SuscripcionAccion({ suscripcion, listarSuscripciones }) {
  const backend = useSelector((state) => state.env.backend);
  const dispatch = useDispatch();

  const desuscribir = async (uuid) => {
    dispatch(iniLoading());
    try {
      let response = await axios.delete(`${backend}/api/suscripciones/${uuid}`);
      let res = response.data;
      if (res.ok) {
        toast.success('Correo desuscrito.');
        listarSuscripciones();
      } else {
        toast.error('Error al desuscribir correo.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al desuscribir correo.');
    }
    dispatch(finLoading());
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={(e) => desuscribir(suscripcion.uuid)}
        title="Desuscribir"
      >
        <i className="fas fa-fw fa-trash"></i>
      </button>
    </div>
  );
}

// Typechecking props of the component
SuscripcionAccion.propTypes = {
  suscripcion: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default SuscripcionAccion;
