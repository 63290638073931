import { createSlice } from '@reduxjs/toolkit'

/**
 * Full screen loading count
 * Simplemente usar iniLoading y finLoading que se exportan
 */
export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    count: 0,
  },
  reducers: {
    iniLoading: (state) => {
      state.count += 1
      // valor auxiliar, evitar el uso de variables globales window.x
      window.iniLoadingTime = Date.now()
      // unfocus buttons para evitar dobles clicks
      document.activeElement.blur()
    },
    resetLoading: (state) => {
      state.count = 0
    },
    finLoadingPrivate: (state) => {
      state.count = Math.max(state.count - 1, 0); // no puede ser negativo
    },
  },
})

export const { iniLoading, resetLoading } = loadingSlice.actions

// The function below is called a thunk and allows us to perform async logic.
export const finLoading = () => (dispatch) => {
  const { finLoadingPrivate } = loadingSlice.actions
  const MIN = 1000; // MIN loading time ms
  if (window.iniLoadingTime) {
    let now = Date.now()
    if (now - window.iniLoadingTime > MIN) {
      dispatch(finLoadingPrivate())
    } else {
      setTimeout(() => {
        dispatch(finLoadingPrivate())
      }, MIN - (now - window.iniLoadingTime))
    }
  } else {
    dispatch(finLoadingPrivate())
  }
}

export default loadingSlice.reducer
