import axios from 'axios';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { listaCmp } from '../site/ListaCmp';
import { getDocUrl, getImageUrl } from '../Util';

Modal.setAppElement('#root');
const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '700px',
    maxHeight: '80%',
  },
};

/**
 * PaginaComponenteModal Component
 */
function PaginaComponenteModal({ isOpen, idPagina, idComponente, onSave, onCancel }) {
  const dispatch = useDispatch();
  const backend = useSelector((state) => state.env.backend);
  const [componente, setComponente] = useState({}); // componente instance
  const [errores, setErrores] = useState({}); // form errores
  const [formDin, setFormDin] = useState({}); // form dinamico de los campos de la plantilla
  const [campos, setCampos] = useState([]); // campos del form dinamico

  // get componente by id
  const getComponente = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/componentes/${idComponente}`);
      let res = response.data;
      if (res.id) {
        setComponente(res);
        setFormDin(JSON.parse(res.muestra));
        let cmp = listaCmp.filter((cmp) => cmp.codigo == res.codigo)[0];
        setCampos(cmp.campos);
      } else {
        toast.error('Error al obtener el componente.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener el componente.');
    }
    dispatch(finLoading());
  };

  // use effect modal open-close
  useEffect(() => {
    if (isOpen && idComponente) {
      getComponente();
    } else {
      setComponente({ id_pagina: idPagina, muestra: '{}' });
      setFormDin({});
      setCampos([]);
      setErrores({});
    }
  }, [isOpen, idPagina, idComponente]); // exhaustive deps

  // handle change formulario pagina
  const handleChange = (name, value) => {
    setComponente((comp) => ({ ...comp, [name]: value }));
  };

  // validar form componente
  const validarComponente = () => {
    let res = true;
    setErrores({});

    if (!(componente.nombre || '').trim()) {
      setErrores((e) => ({ ...e, cmpNombre: 'Ingrese el nombre.' }));
      res = false;
    }
    if (!(componente.codigo || '').trim()) {
      setErrores((e) => ({ ...e, cmpCodigo: 'Seleccione la plantilla.' }));
      res = false;
    }

    // validar form din
    campos.forEach((campo) => {
      if (campo.required) {
        if (
          campo.type == 'text' ||
          campo.type == 'select' ||
          campo.type == 'image' ||
          campo.type == 'checkbox' ||
          campo.type == 'doc'
        ) {
          if (!(formDin[campo.prop] || '').trim()) {
            setErrores((e) => ({ ...e, [campo.prop]: 'Complete este campo.' }));
            res = false;
          }
        } else if (campo.type == 'array') {
          formDin[campo.prop].forEach((item, idx) => {
            campo.campos.forEach((subc) => {
              if (subc.required) {
                if (
                  subc.type == 'text' ||
                  subc.type == 'select' ||
                  subc.type == 'image' ||
                  subc.type == 'checkbox' ||
                  subc.type == 'doc'
                ) {
                  if (!(item[subc.prop] || '').trim()) {
                    setErrores((e) => ({ ...e, [subc.prop + idx]: 'Complete este campo.' }));
                    res = false;
                  }
                }
              }
            });
          });
        }
      }
    });

    return res;
  };

  // store new componente
  const storeComponente = () => {
    let body = { ...componente, muestra: JSON.stringify(formDin) };
    dispatch(iniLoading());
    axios
      .post(`${backend}/api/componentes`, body)
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Componente agregado!');
          onSave();
        } else if (res.errores) {
          setErrores(res.errores);
        } else {
          toast.error(res.error || 'Error al guardar el componente.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar el componente.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // update current componente
  const updateComponente = () => {
    let body = { ...componente, muestra: JSON.stringify(formDin) };
    dispatch(iniLoading());
    axios
      .put(`${backend}/api/componentes/${componente.id}`, body)
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Componente actualizado!');
          onSave();
        } else if (res.errores) {
          setErrores(res.errores);
        } else {
          toast.error(res.error || 'Error al guardar el componente.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar el componente.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // click guardar nuevo o update
  const clickGuardar = () => {
    if (validarComponente()) {
      if (componente.id == null) {
        storeComponente();
      } else {
        updateComponente();
      }
    } else {
      toast.error('Por favor, corrija el formulario.');
    }
  };

  // change plantilla set form dinamico
  const changePlantilla = (codigo) => {
    if (codigo) {
      let cmp = listaCmp.filter((cmp) => cmp.codigo == codigo)[0];
      setCampos(cmp.campos);
      cmp.campos.forEach((c) => {
        if (c.type == 'text' || c.type == 'select' || c.type == 'image') {
          setFormDin((f) => ({ ...f, [c.prop]: '' }));
        } else if (c.type == 'array') {
          setFormDin((f) => ({ ...f, [c.prop]: [] }));
        }
        setErrores((e) => ({ ...e, [c.prop]: '' }));
      });
    } else {
      setCampos([]);
      setFormDin({});
    }
  };

  // handle change formulario dinamico
  const handleChangeFdin = (name, value) => {
    setFormDin((f) => ({ ...f, [name]: value }));
  };

  // upload file and set url
  const uploadFile = (name, e, msg) => {
    if (e.target.files.length == 1) {
      let file = e.target.files[0];
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };

      let formData = new FormData();
      formData.append('archivo', file);

      dispatch(iniLoading());
      axios
        .post(`${backend}/api/componentes`, formData, config)
        .then((response) => {
          let res = response.data;
          if (res.ok) {
            toast.success(msg || 'Archivo subido.');
            handleChangeFdin(name, res.url);
          } else {
            toast.error(res.error || 'No se pudo subir el archivo.');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('No se pudo subir el archivo.');
        })
        .finally(() => dispatch(finLoading()));
    } else {
      handleChangeFdin(name, '');
    }
  };

  // add item (subcampos) to array en formulario dinamico
  const addToArray = (campo) => {
    let item = {};
    campo.campos.forEach((subc) => {
      item[subc.prop] = '';
    });
    handleChangeFdin(campo.prop, [...formDin[campo.prop], item]);
  };

  // remove item (subcampos) from array en formulario dinamico
  const rmFromArray = (campo, idx) => {
    let arr = formDin[campo.prop];
    arr.splice(idx, 1);
    handleChangeFdin(campo.prop, arr);
  };

  const moveUp = (campo, idx) => {
    let arr = formDin[campo.prop];
    let elem = arr[idx];

    arr.splice(idx, 1);
    arr.splice(idx - 1, 0, elem);

    handleChangeFdin(campo.prop, arr);
  };

  const moveDown = (campo, idx) => {
    let arr = formDin[campo.prop];
    let elem = arr[idx];

    arr.splice(idx, 1);
    arr.splice(idx + 1, 0, elem);

    handleChangeFdin(campo.prop, arr);
  };

  // handle change en subcampo en array
  const handleChangeArray = (arrayName, index, name, value) => {
    setFormDin((f) => {
      let arr = f[arrayName];
      arr[index][name] = value;
      return { ...f, [arrayName]: arr };
    });
  };

  // upload image and set url en subcampo en array
  const uploadFileArray = (arrayName, index, name, e, msg) => {
    if (e.target.files.length == 1) {
      let file = e.target.files[0];
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };

      let formData = new FormData();
      formData.append('archivo', file);

      dispatch(iniLoading());
      axios
        .post(`${backend}/api/componentes`, formData, config)
        .then((response) => {
          let res = response.data;
          if (res.ok) {
            toast.success(msg || 'Archivo subido.');
            setFormDin((f) => {
              let arr = f[arrayName];
              arr[index][name] = res.url;
              return { ...f, [arrayName]: arr };
            });
          } else {
            toast.error(res.error || 'No se pudo subir el archivo.');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('No se pudo subir el archivo.');
        })
        .finally(() => dispatch(finLoading()));
    } else {
      setFormDin((f) => {
        let arr = f[arrayName];
        arr[index][name] = '';
        return { ...f, [arrayName]: arr };
      });
    }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onAfterOpen={() => {}}
        onRequestClose={onCancel}
        style={modalStyle}
        contentLabel="Example Modal"
      >
        <div className="d-flex content-between items-center mb-3">
          <h4 className="font-weight-regular">
            {idComponente ? 'Editar Componente' : 'Agregar Componente'}
          </h4>
          <button className="btn btn-sm btn-outline-secondary ml-3" onClick={onCancel}>
            <i className="fas fa-fw fa-times"></i>
          </button>
        </div>
        <hr className="mb-5" />

        {/* formulario componente */}
        <div>
          {/* input nombre */}
          <div className="form-group">
            <label htmlFor="nombre-comp">
              Nombre <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control form-control-100 ${errores.cmpNombre ? 'is-invalid' : ''}`}
              id="nombre-comp"
              value={componente.nombre || ''}
              onChange={(e) => handleChange('nombre', e.target.value)}
            />
            <span className="text-danger">{errores.cmpNombre}</span>
          </div>

          {/* select plantilla */}
          <div className="form-group">
            <label htmlFor="sel-plantilla">
              Plantilla <span className="text-danger">*</span>
            </label>
            <select
              className={`form-control form-control-100 ${errores.cmpCodigo ? 'is-invalid' : ''}`}
              id="sel-plantilla"
              value={componente.codigo || ''}
              onChange={(e) => {
                handleChange('codigo', e.target.value);
                changePlantilla(e.target.value);
              }}
            >
              <option value="">Seleccione...</option>
              {listaCmp.map((cmp) => (
                <option value={cmp.codigo} key={cmp.codigo}>
                  {cmp.nombre}
                </option>
              ))}
            </select>
            <span className="text-danger">{errores.cmpCodigo}</span>
          </div>

          {/* form dinamico */}
          {campos.map((campo) => (
            <div key={campo.prop}>
              {/* campo type text */}
              {campo.type == 'text' && (
                <div className="form-group">
                  <label htmlFor={campo.prop}>
                    {campo.name} {campo.required && <span className="text-danger">*</span>}
                  </label>
                  <input
                    type="text"
                    className={`form-control form-control-100 ${
                      errores[campo.prop] ? 'is-invalid' : ''
                    }`}
                    id={campo.prop}
                    value={formDin[campo.prop] || ''}
                    onChange={(e) => handleChangeFdin(campo.prop, e.target.value)}
                  />
                  <span className="text-danger">{errores[campo.prop]}</span>
                </div>
              )}

              {/* campo type checkbox */}
              {campo.type == 'checkbox' && (
                <div className="form-group">
                  <label htmlFor={campo.prop}>
                    <input
                      type="checkbox"
                      className={`${errores[campo.prop] ? 'is-invalid' : ''}`}
                      id={campo.prop}
                      checked={formDin[campo.prop] || false}
                      onChange={(e) => handleChangeFdin(campo.prop, e.target.checked)}
                      style={{ marginRight: '0.7em' }}
                    />
                    {campo.name} {campo.required && <span className="text-danger">*</span>}
                  </label>
                  <span className="text-danger">{errores[campo.prop]}</span>
                </div>
              )}

              {/* campo type select */}
              {campo.type == 'select' && (
                <div className="form-group">
                  <label htmlFor={campo.prop}>
                    {campo.name} {campo.required && <span className="text-danger">*</span>}
                  </label>
                  <select
                    className={`form-control form-control-100 ${
                      errores[campo.prop] ? 'is-invalid' : ''
                    }`}
                    id={campo.prop}
                    value={formDin[campo.prop] || ''}
                    onChange={(e) => handleChangeFdin(campo.prop, e.target.value)}
                  >
                    <option value="">Seleccione...</option>
                    {campo.options.map((op) => (
                      <option key={op.value} value={op.value}>
                        {op.label}
                      </option>
                    ))}
                  </select>
                  <span className="text-danger">{errores[campo.prop]}</span>
                </div>
              )}

              {/* campo type image */}
              {campo.type == 'image' && (
                <div className="form-group">
                  <label htmlFor={campo.prop}>
                    {campo.name} {campo.required && <span className="text-danger">*</span>}
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    className={`form-control form-control-100 ${
                      errores[campo.prop] ? 'is-invalid' : ''
                    }`}
                    id={campo.prop}
                    // value={formDin[campo.prop] || ''}
                    onChange={(e) => uploadFile(campo.prop, e, 'Imagen subida.')}
                  />
                  {formDin[campo.prop] && (
                    <span className="text-muted">
                      <a
                        href={getImageUrl(formDin[campo.prop])}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-muted"
                        style={{ fontFamily: 'Arial, sans-serif' }}
                      >
                        Actual: {getImageUrl(formDin[campo.prop])}
                      </a>
                    </span>
                  )}
                  <span className="text-danger">{errores[campo.prop]}</span>
                </div>
              )}

              {/* campo type array */}
              {campo.type == 'array' && (
                <>
                  {formDin[campo.prop].map((item, idx) => (
                    <div key={campo.prop + idx}>
                      {campo.campos.map((subc) => (
                        <div className="form-group" key={subc.prop + idx}>
                          <label htmlFor={subc.prop + idx}>
                            {subc.name} {idx + 1}{' '}
                            {subc.required && <span className="text-danger">*</span>}
                            {subc.type == 'checkbox' && (
                              <input
                                type="checkbox"
                                className={`${errores[subc.prop + idx] ? 'is-invalid' : ''}`}
                                id={subc.prop + idx}
                                checked={item[subc.prop] || false}
                                onChange={(e) =>
                                  handleChangeArray(campo.prop, idx, subc.prop, e.target.checked)
                                }
                                style={{ marginRight: '0.7em' }}
                              />
                            )}
                          </label>

                          {/* sub campo type text */}
                          {subc.type == 'text' && (
                            <input
                              type="text"
                              className={`form-control form-control-100 ${
                                errores[subc.prop + idx] ? 'is-invalid' : ''
                              }`}
                              id={subc.prop + idx}
                              value={item[subc.prop] || ''}
                              onChange={(e) =>
                                handleChangeArray(campo.prop, idx, subc.prop, e.target.value)
                              }
                            />
                          )}

                          {/* sub campo type image */}
                          {subc.type == 'image' && (
                            <>
                              <input
                                type="file"
                                accept="image/png, image/jpeg"
                                className={`form-control form-control-100 ${
                                  errores[subc.prop + idx] ? 'is-invalid' : ''
                                }`}
                                id={subc.prop + idx}
                                // value={item[subc.prop] || ''}
                                onChange={(e) =>
                                  uploadFileArray(campo.prop, idx, subc.prop, e, 'Imagen subida.')
                                }
                              />
                              {item[subc.prop] && (
                                <span className="text-muted">
                                  <a
                                    href={getImageUrl(item[subc.prop])}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-muted"
                                    style={{ fontFamily: 'Arial, sans-serif' }}
                                  >
                                    Actual: {getImageUrl(item[subc.prop])}
                                  </a>
                                </span>
                              )}
                            </>
                          )}

                          {/* sub campo type doc */}
                          {subc.type == 'doc' && (
                            <>
                              <input
                                type="file"
                                accept="application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                className={`form-control form-control-100 ${
                                  errores[subc.prop + idx] ? 'is-invalid' : ''
                                }`}
                                id={subc.prop + idx}
                                // value={item[subc.prop] || ''}
                                onChange={(e) =>
                                  uploadFileArray(
                                    campo.prop,
                                    idx,
                                    subc.prop,
                                    e,
                                    'Documento subido.'
                                  )
                                }
                              />
                              {item[subc.prop] && (
                                <span className="text-muted">
                                  <a
                                    href={getDocUrl(item[subc.prop])}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-muted"
                                    style={{ fontFamily: 'Arial, sans-serif' }}
                                  >
                                    Actual: {getDocUrl(item[subc.prop])}
                                  </a>
                                </span>
                              )}
                            </>
                          )}

                          <span className="text-danger">{errores[subc.prop + idx]}</span>
                        </div>
                      ))}
                      <div className="mb-5 d-flex content-right">
                        <button className="btn btn-primary" onClick={() => rmFromArray(campo, idx)}>
                          <i className="fas fa-times"></i> Quitar{' '}
                          {campo.itemName.toLocaleLowerCase()} {idx + 1}
                        </button>
                        {idx > 0 && (
                          <button className="btn btn-primary" onClick={() => moveUp(campo, idx)}>
                            <i className="fas fa-arrow-up"></i> Mover arriba
                          </button>
                        )}
                        {idx < formDin[campo.prop].length - 1 && (
                          <button className="btn btn-primary" onClick={() => moveDown(campo, idx)}>
                            <i className="fas fa-arrow-down"></i> Mover abajo
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="mb-5 d-flex content-right">
                    <button className="btn btn-primary" onClick={() => addToArray(campo)}>
                      <i className="fas fa-plus"></i> Agregar un{' '}
                      {campo.itemName.toLocaleLowerCase()}
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}

          {/* btn guardar */}
          <div>
            <button className="btn btn-primary" onClick={clickGuardar}>
              <i className="fas fa-save"></i> Guardar
            </button>
            <button className="btn btn-secondary ml-5" onClick={onCancel}>
              <i className="fas fa-arrow-left"></i> Cancelar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

// Typechecking props of the component
PaginaComponenteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  idPagina: PropTypes.number.isRequired,
  idComponente: PropTypes.number, // null al agregar
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PaginaComponenteModal;
