import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import CategoriaOrden from './CategoriaOrden';

function CategoriaInstance() {
  const [readOnly, setReadOnly] = useState(false);
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams(); // id de la categoria, null al crear
  const [categoria, setCategoria] = useState({
    nombre: '',
    url: '',
    classIcon: '',
    idCategoriaPadre: '',
    navbar: false,
    footer: false,
    social: false,
    visible: true,
    tipoUrlPagina: true,
  }); // categoria instance
  const [selectCategoriaPadre, setSelectCategoriaPadre] = useState([]); // select categorias padre
  const [selectPagina, setSelectPagina] = useState([]); // select paginas
  const [errores, setErrores] = useState({}); // form errores

  // handle change formulario categoria
  const handleChange = (name, value) => {
    if (readOnly) {
      console.log('read only: no change');
    } else {
      setCategoria({ ...categoria, [name]: value });
    }
  };

  // emit event change formulario categoria
  const handleChangeRadio = async (name, value) => {
    if (readOnly) {
      console.log('read only: no change');
    } else {
      categoria.navbar = false;
      categoria.footer = false;
      categoria.social = false;
      categoria.classIcon = '';
      categoria.idCategoriaPadre = '';
      if (name == 'social' && value) {
        await setCategoria({ ...categoria, [name]: value, url: '', tipoUrlPagina: false });
      } else {
        await setCategoria({ ...categoria, [name]: value });
      }
      loadSelectPadre(name);
    }
  };

  // emit event change formulario categoria
  const handleChangeTipoUrl = async (tipo) => {
    // console.log('changeTipoUrl');
    if (readOnly) {
      console.log('read only: no change');
    } else {
      setCategoria({ ...categoria, url: '', tipoUrlPagina: tipo });
    }
  };

  // get categoria by id
  const getCategoria = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/categorias/${id}`);
      let res = response.data;

      if (res.id) {
        setCategoria(res);

        loadSelectPadre(res.navbar ? 'navbar' : res.footer ? 'footer' : 'social');
      } else {
        toast.error('Error al obtener la página.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener la página.');
    }
    dispatch(finLoading());
  };

  const loadSelectPadre = (posicion) => {
    if (posicion == 'social') {
      setSelectCategoriaPadre([]);
      return;
    }

    if (id) getCategoriasSelect(posicion, id);
    else getCategoriasSelect(posicion);
  };

  // get categoria by id
  const getCategoriasSelect = async (posicion, id = -1) => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(
        `${backend}/api/categorias?categoriasPadre=${id}&posicion=${posicion}`
      );
      let res = response.data;

      setSelectCategoriaPadre(res);
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener la página.');
    }
    dispatch(finLoading());
  };

  // get categoria by id
  const getPaginasSelect = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/paginas?selectPaginasVisibles=true`);

      let res = response.data.map((pagina) => {
        return { ...pagina, codigo: `/site?page=${pagina.codigo}` };
      });

      // agregar pagina de mapa de sucursales
      res.push({
        id: -1,
        nombre: 'Mapa Sucursales',
        codigo: '/site/mapa',
      });

      // agregar pagina de calculadora de precios
      res.push({
        id: -2,
        nombre: 'Calculadora de prestamos',
        codigo: '/site/calculadora_prestamos',
      });

      // agregar pagina de solicitud de producto
      res.push({
        id: -3,
        nombre: 'Solicitud de productos',
        codigo: '/site/producto',
      });

      // agregar pagina de rrhh
      res.push({
        id: -4,
        nombre: 'Formulario RRHH',
        codigo: '/site/rrhh',
      });

      setSelectPagina(res);
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener la página.');
    }
    dispatch(finLoading());
  };

  // use effect get categoria
  useEffect(() => {
    if (id && authData && authData.user.rol == 'admin') getCategoria();
    getPaginasSelect();
  }, [id]);

  // store new categoria
  const storeCategoria = () => {
    dispatch(iniLoading());
    axios
      .post(`${backend}/api/categorias`, categoria)
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Nueva categoría guardada!');
          navigate('/admin/categorias/' + res.id);
        } else if (res.errores) {
          setErrores(res.errores);
        } else {
          toast.error(res.error || 'Error al guardar la categoría.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar la categoría.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // update current categoria
  const updateCategoria = () => {
    dispatch(iniLoading());
    axios
      .put(`${backend}/api/categorias/${categoria.id}`, categoria)
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Categoría guardada!');
        } else if (res.errores) {
          setErrores(res.errores);
        } else {
          toast.error(res.error || 'Error al guardar la categoría.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar la categoría.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // validar form categoria
  const validarCategoria = () => {
    let res = true;
    setErrores({});

    if (!categoria.nombre.trim()) {
      setErrores((e) => ({ ...e, nombre: 'Ingrese el nombre de la categoría.' }));
      res = false;
    }

    if (!categoria.navbar && !categoria.footer && !categoria.social) {
      setErrores((e) => ({ ...e, posicionCategoria: 'Seleccione la posición de la categoría.' }));
      res = false;
    }

    if (categoria.social && !categoria.classIcon.trim()) {
      setErrores((e) => ({ ...e, classIcon: 'Ingrese el valor de la clase del icono.' }));
      res = false;
    }

    if (!categoria.idCategoriaPadre && categoria.url && !categoria.social) {
      setErrores((e) => ({
        ...e,
        url: 'Para seleccionar una página, se debe seleccionar una categoría padre.',
      }));
      res = false;
    } else if (categoria.social && !categoria.url.trim()) {
      setErrores((e) => ({
        ...e,
        url: 'Ingrese la url al perfil en la red social.',
      }));
    }

    return res;
  };

  // click guardar
  const clickGuardar = () => {
    if (validarCategoria()) {
      if (id == null) {
        storeCategoria();
      } else {
        updateCategoria();
      }
    }
  };

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-5">
          {id == null ? (
            <h4 className="font-weight-regular">Nueva Categoría</h4>
          ) : (
            <h4 className="font-weight-regular">Editar Categoría</h4>
          )}
        </div>

        {/* formulario categoria */}
        <div className="mb-5">
          {/* input nombre */}
          <div className="form-group">
            <label htmlFor="nombre">Nombre</label>
            <input
              type="text"
              className={`form-control ${errores.nombre ? 'is-invalid' : ''}`}
              id="nombre"
              autoComplete="off"
              value={categoria.nombre}
              onChange={(e) => handleChange('nombre', e.target.value)}
            />
            <span className="text-danger">{errores.nombre}</span>
          </div>

          {/* radio posicion */}
          <div className="form-group">
            <label htmlFor="navbarChecked">
              <input
                type="radio"
                id="navbarChecked"
                name="posicionCategoria"
                checked={categoria.navbar}
                onChange={(e) => {
                  handleChangeRadio('navbar', e.target.checked);
                }}
              />
              Navbar
            </label>
            <label htmlFor="footerChecked">
              <input
                type="radio"
                id="footerChecked"
                name="posicionCategoria"
                checked={categoria.footer}
                onChange={(e) => {
                  handleChangeRadio('footer', e.target.checked);
                }}
              />
              Footer
            </label>
            <label htmlFor="socialChecked">
              <input
                type="radio"
                id="socialChecked"
                name="posicionCategoria"
                checked={categoria.social}
                onChange={(e) => {
                  handleChangeRadio('social', e.target.checked);
                }}
              />
              Social
            </label>
            <span className="text-danger">{errores.posicionCategoria}</span>
          </div>

          {!categoria.social && (
            <>
              {/* select categoria padre */}
              <div className="form-group">
                <label htmlFor="select-padre">Categoría padre</label>
                <select
                  type="text"
                  className={`form-control ${errores.padre ? 'is-invalid' : ''}`}
                  id="select-padre"
                  value={categoria.idCategoriaPadre}
                  onChange={(e) => {
                    handleChange('idCategoriaPadre', e.target.value);
                  }}
                  disabled={categoria.social}
                >
                  <option value="">Seleccionar...</option>
                  {selectCategoriaPadre.map((cat, key) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.nombre}
                    </option>
                  ))}
                </select>
                <span className="text-danger">{errores.padre}</span>
              </div>
            </>
          )}
          {categoria.social && (
            <>
              {/* input classIcon */}
              <div className="form-group">
                <label htmlFor="classIcon">Clase Icono</label>
                <small>
                  Busque el ícono que desea utilizar en la página{' '}
                  <a href="https://fontawesome.com/v5/search" target="_blank">
                    FontAwesome
                  </a>{' '}
                  y copie en este campo sólo el valor del atributo "class" que usa el icono. Ej.:{' '}
                  <span className="text-danger">fab fa-facebook</span>
                </small>

                <input
                  type="text"
                  className={`form-control ${errores.classIcon ? 'is-invalid' : ''}`}
                  id="classIcon"
                  autoComplete="off"
                  value={categoria.classIcon}
                  onChange={(e) => handleChange('classIcon', e.target.value)}
                  disabled={!categoria.social}
                />
                <span className="text-danger">{errores.classIcon}</span>
              </div>
            </>
          )}

          {!categoria.social && (
            <>
              {/* radio tipo url */}
              <div className="form-group">
                <label htmlFor="tipoTextChecked">
                  <input
                    type="radio"
                    id="tipoTextChecked"
                    name="tipoUrlPagina"
                    checked={categoria.tipoUrlPagina}
                    onChange={(e) => handleChangeTipoUrl(true)}
                    disabled={categoria.social}
                  />
                  Página
                </label>
                <label htmlFor="tipoPaginaChecked">
                  <input
                    type="radio"
                    id="tipoPaginaChecked"
                    name="tipoUrlPagina"
                    checked={!categoria.tipoUrlPagina}
                    onChange={(e) => handleChangeTipoUrl(false)}
                  />
                  Texto
                </label>
                <span className="text-danger">{errores.tipoUrlPagina}</span>
              </div>
            </>
          )}

          {categoria.tipoUrlPagina && (
            <>
              {/* select pagina */}
              <div className="form-group">
                <label htmlFor="select-pagina">Página</label>
                <select
                  type="text"
                  className={`form-control ${errores.url ? 'is-invalid' : ''}`}
                  id="select-pagina"
                  value={categoria.url}
                  onChange={(e) => {
                    handleChange('url', e.target.value);
                  }}
                  disabled={!categoria.idCategoriaPadre}
                >
                  <option value="">Seleccionar...</option>
                  {selectPagina.map((pagina, key) => (
                    <option key={pagina.id} value={pagina.codigo}>
                      {pagina.nombre}
                    </option>
                  ))}
                </select>
                <span className="text-danger">{errores.url}</span>
              </div>
            </>
          )}

          {!categoria.tipoUrlPagina && (
            <>
              {/* input url */}
              <div className="form-group">
                <label htmlFor="url">URL página</label>
                <input
                  type="text"
                  className={`form-control ${errores.url ? 'is-invalid' : ''}`}
                  id="url"
                  autoComplete="off"
                  value={categoria.url}
                  onChange={(e) => handleChange('url', e.target.value)}
                  disabled={!categoria.idCategoriaPadre && !categoria.social}
                />
                <span className="text-danger">{errores.url}</span>
              </div>
            </>
          )}

          {/* checkbox visible */}
          {id != null && (
            <>
              <div className="form-group">
                <label htmlFor="visibleCheckbox">
                  <input
                    type="checkbox"
                    id="visibleCheckbox"
                    checked={categoria.visible}
                    onChange={(e) => {
                      handleChange('visible', e.target.checked);
                    }}
                  />
                  Visible
                </label>
              </div>
            </>
          )}

          {/* btn guardar */}
          <div>
            <button className="btn btn-primary" onClick={clickGuardar}>
              <i className="fas fa-save"></i> Guardar
            </button>
            <button
              className="btn btn-secondary ml-5"
              onClick={() => navigate('/admin/categorias')}
            >
              <i className="fas fa-arrow-left"></i> Volver
            </button>
          </div>
        </div>

        {id != null && (
          <>
            <hr className="mb-5" />
            <CategoriaOrden idCategoria={Number.parseInt(id)} />
          </>
        )}
      </div>
    )
  );
}

export default CategoriaInstance;
