import { useState } from 'react';
import DataTableBase from '../DataTableBase';
import { dispIsoDateTimeString, fnNombreDispositivo, fnNombreNavegador, isoFechaActual } from '../Util';

/**
 * Listado de visitas con filtros, por fecha, localidad, por páginas,
 * dispositivo, navegador. Y al filtrar se descarga en PDF y Excel.
 * @returns react functional component
 */
function AdminDashVisitasPaginas() {

  const columns = [
    {
      name: 'Fecha Hora',
      selector: (row) => dispIsoDateTimeString(row.created_at, true),
      sortable: true,
      sortField: 'created_at',
      width: '15%',
    },
    {
      name: 'Página',
      selector: (row) => row.urlPrincipal + (row.urlSecundaria ?? ''),
      cell: row => <span>
        {row.urlPrincipal + (row.urlSecundaria ?? '')}
      </span>
    },
    {
      name: 'Localidad',
      selector: (row) => row.ciudad,
      width: '18%',
    },
    {
      name: 'País',
      selector: (row) => row.pais,
      width: '12%',
    },
    {
      name: 'Dispositivo',
      selector: (row) => fnNombreDispositivo(row.dispositivo),
      width: '12%',
    },
    {
      name: 'Navegador',
      selector: (row) => fnNombreNavegador(row.navegador),
      width: '12%',
    },
    {
      name: 'IP / ID Visitante',
      selector: row => row.ip + ' / ' + row.idUsuarioSesion.slice(-10),
      cell: row => <span>
        {row.ip + ' / ' + row.idUsuarioSesion.slice(-10)}
      </span>,
      width: '12%',
    },
  ];

  // FILTROS
  const [filtroFechaDesde, setFiltroFechaDesde] = useState('');
  const [filtroFechaHasta, setFiltroFechaHasta] = useState('');
  const [filtroLocalidad, setFiltroLocalidad] = useState('');
  const [filtroPagina, setFiltroPagina] = useState('');
  const [filtroDispositivo, setFiltroDispositivo] = useState('');
  const [filtroNavegador, setFiltroNavegador] = useState('');
  const [dtFiltros, setDtFiltros] = useState({}); // data table filtros
  const listar = () => {
    setDtFiltros({
      fechaDesde: filtroFechaDesde,
      fechaHasta: filtroFechaHasta,
      localidad: filtroLocalidad,
      pagina: filtroPagina,
      dispositivo: filtroDispositivo,
      navegador: filtroNavegador,
    });
  };
  const limpiar = () => {
    setFiltroFechaDesde('');
    setFiltroFechaHasta('');
    setFiltroDispositivo('');
    setFiltroNavegador('');
    setFiltroLocalidad('');
    setFiltroPagina('');
    setDtFiltros({});
  };

  const fnFiltrosPrint = (dtFiltros) => {
    let res = {};
    if (dtFiltros.fechaDesde) {
      res['Fecha Desde'] = dispIsoDateTimeString(dtFiltros.fechaDesde);
    }
    if (dtFiltros.fechaHasta) {
      res['Fecha Hasta'] = dispIsoDateTimeString(dtFiltros.fechaHasta);
    }
    if (dtFiltros.localidad) {
      res['Localidad'] = (dtFiltros.localidad);
    }
    if (dtFiltros.pagina) {
      res['Pagina'] = (dtFiltros.pagina);
    }
    if (dtFiltros.dispositivo) {
      res['Dispositivo'] = fnNombreDispositivo(dtFiltros.dispositivo);
    }
    if (dtFiltros.navegador) {
      res['Navegador'] = fnNombreNavegador(dtFiltros.navegador);
    }
    return res;
  }

  return <>
    <div className='mb-3'>
      <div className='mb-4'>
        <h4 className="font-weight-regular">
          Visitas al sitio web
        </h4>
      </div>

      <div className="d-flex items-center--">
        <div className="form-group mb-3 mr-4" style={{ maxWidth: '200px' }}>
          <label htmlFor="filtroFechaDesde">Fecha Hora Desde</label>
          <input
            id="filtroFechaDesde"
            type="datetime-local"
            className="form-control"
            value={filtroFechaDesde}
            onChange={(e) => setFiltroFechaDesde(e.target.value)}
            max={isoFechaActual() + ' 23:59:59'}
          />
        </div>

        <div className="form-group mb-3 mr-4" style={{ maxWidth: '200px' }}>
          <label htmlFor="filtroFechaHasta">Fecha Hora Hasta</label>
          <input
            id="filtroFechaHasta"
            type="datetime-local"
            className="form-control"
            value={filtroFechaHasta}
            onChange={(e) => setFiltroFechaHasta(e.target.value)}
            max={isoFechaActual() + ' 23:59:59'}
          />
        </div>

        <div className="form-group mb-3 mr-4" style={{ maxWidth: '200px' }}>
          <label htmlFor="filtroLocalidad">Localidad</label>
          <input
            id="filtroLocalidad"
            type="text"
            className="form-control"
            value={filtroLocalidad}
            onChange={(e) => setFiltroLocalidad(e.target.value)}
          />
        </div>

        <div className="form-group mb-3 mr-4" style={{ maxWidth: '200px' }}>
          <label htmlFor="filtroPagina">Página</label>
          <input
            id="filtroPagina"
            type="text"
            className="form-control"
            value={filtroPagina}
            onChange={(e) => setFiltroPagina(e.target.value)}
          />
        </div>

        <div className="form-group mb-3 mr-4" style={{ maxWidth: '200px' }}>
          <label htmlFor="filtroDispositivo">Dispositivo</label>
          <select
            id="filtroDispositivo"
            className="form-control"
            value={filtroDispositivo}
            onChange={(e) => setFiltroDispositivo(e.target.value)}
            style={{ minWidth: '150px' }}
          >
            <option value="">Todos</option>
            <option value="M">Mobile</option>
            <option value="D">Desktop</option>
            <option value="T">Tablet</option>
          </select>
        </div>

        <div className="form-group mb-3 mr-4" style={{ maxWidth: '200px' }}>
          <label htmlFor="filtroNavegador">Navegador</label>
          <select
            id="filtroNavegador"
            className="form-control"
            value={filtroNavegador}
            onChange={(e) => setFiltroNavegador(e.target.value)}
            style={{ minWidth: '150px' }}
          >
            <option value="">Todos</option>
            <option value="C">Chrome</option>
            <option value="F">Firefox</option>
            <option value="S">Safari</option>
            <option value="E">Edge</option>
            <option value="D">Desconocido</option>
          </select>
        </div>
      </div>

      <div className="d-flex content-right">
        <div className='mb-3'>
          <button className="btn btn-primary mr-3" onClick={listar} title='Aplicar filtros'>
            <i className="fas fa-fw fa-filter"></i> Listar
          </button>
          <button className="btn btn-outline-secondary" onClick={limpiar}>
            Restablecer
          </button>
        </div>
      </div>

      <div>
        <DataTableBase
          url={'/api/sesiones'}
          columns={columns}
          filtros={dtFiltros}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          exportar={true}
          fnFiltrosPrint={fnFiltrosPrint}
          horizontal={true}
          titulo={'Visitas al sitio web'}
        />
      </div>
    </div>
  </>;
}

export default AdminDashVisitasPaginas;
