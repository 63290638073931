/**
 * Funciones utiles
 */

/**
 * Meses del año
 */
export const meses = [
  { val: '01', nombre: 'Enero' },
  { val: '02', nombre: 'Febrero' },
  { val: '03', nombre: 'Marzo' },
  { val: '04', nombre: 'Abril' },
  { val: '05', nombre: 'Mayo' },
  { val: '06', nombre: 'Junio' },
  { val: '07', nombre: 'Julio' },
  { val: '08', nombre: 'Agosto' },
  { val: '09', nombre: 'Septiembre' },
  { val: '10', nombre: 'Octubre' },
  { val: '11', nombre: 'Noviembre' },
  { val: '12', nombre: 'Diciembre' },
];

/**
 * Esta funcion retorna la url completa a una imagen.
 * Concatena url del backend si se recibe una url relativa.
 * Las imagenes subidas por el usuario en la app se guardan en el BACKEND!
 * @param {string} srcImg enlace a una imagen
 * @returns {string}
 */
export const getImageUrl = (srcImg) => {
  let res;
  if (srcImg.charAt(0) == '/') {
    res = process.env.REACT_APP_BACKEND + srcImg;
  } else {
    res = srcImg;
  }
  return res;
};

/**
 * Hace lo mismo que getImageUrl
 * @param {string} srcImg enlace a una imagen
 * @returns {string}
 */
export const getDocUrl = (enlaceDoc) => {
  let res;
  if (enlaceDoc.charAt(0) == '/') {
    res = process.env.REACT_APP_BACKEND + enlaceDoc;
  } else {
    res = enlaceDoc;
  }
  return res;
};

/**
 * Capitalizar string
 * @param {string} str
 * @returns {string}
 */
export const capitalize = (str) => {
  while (str.length > 0 && /\s/.test(str.charAt(0))) {
    str = str.slice(1);
  }
  if (str.length > 0) {
    str = str.charAt(0).toLocaleUpperCase() + str.slice(1);
  }
  return str;
};

/**
 * Formateo de codigos lowercase & snake_case
 * @param {string} input
 * @returns {string}
 */
export const formatCodigo = (input) => {
  let res = input.toLowerCase();
  res = res.replace(' ', '_');
  res = res.replace(/[^0-9a-z_]/g, ''); // elimina caracteres no validos
  return res;
};

/**
 * Formateo de digitos numericos
 * @param {string} input
 * @returns {string}
 */
export const formatNumero = (input) => {
  return input.replace(/[^0-9]/g, '');
};

/**
 * Muestra fecha
 * @param {string} dateStr, ejemplo: "2022-01-30"
 * @returns {string}, ejemplo: "30/01/2022"
 */
export const dispIsoDateString = (dateStr) => {
  let [año, mes, dia] = dateStr.split('-');
  return `${dia}/${mes}/${año}`;
};

/**
 * Formato de entrada de ejemplo => "2021-01-30 14:15:19"
 * Formato de salida de ejemplo => "30/01/2021 14:15:19"
 * @param String dateTimeStr
 */
export function dispIsoDateTimeString(dateTimeStr, sec = false) {
  if (dateTimeStr) {
    let [fecha, time] = dateTimeStr.split(' ');
    if (dateTimeStr.indexOf('T') != -1) {
      [fecha, time] = dateTimeStr.split('T');
    }
    let [año, mes, dia] = fecha.split('-');
    let [hora, minuto, segundo] = time.split(':');
    return `${dia}/${mes}/${año} ${hora}:${minuto}${sec ? (':' + segundo.substring(0, 2)) : ''}`;
  } else return '';
}

export const igualdadEps = (a, b, eps) => {
  return Math.abs(a - b) <= eps;
};

// from https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-string
export const formatMoney = (amount, decimalCount = 2, decimal = ',', thousands = '.') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substring(0, j) + thousands : '') +
      i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
        : '')
    );
  } catch (e) {
    console.error(e);
  }
};

/**
 * Mostrar suma de dinero en guaranies
 * @param {number} x Suma de dinero en guaranies
 * @returns {string} Suma de dinero en guaranies para mostrar
 */
export const dispGuarani = (x) => {
  if (!x && x !== 0) {
    // null
    return '';
  } else if (igualdadEps(x, 0, 0.01)) {
    return '0';
  } else {
    return formatMoney(x, 0); // mostrar solo guarani por ahora
  }
};

export const isoFechaActual = (hora = false) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hour = String(currentDate.getHours()).padStart(2, "0");
  const minute = String(currentDate.getMinutes()).padStart(2, "0");
  const second = String(currentDate.getSeconds()).padStart(2, "0");
  let formattedDate = `${year}-${month}-${day}`;
  if (hora) formattedDate = `${formattedDate} ${hour}:${minute}:${second}`;
  return formattedDate;
}

export const segundosAPalabras = (tiempoEnSegundos) => {
  if (typeof tiempoEnSegundos !== 'number' || tiempoEnSegundos < 0) {
    return "Tiempo inválido";
  }

  const minutos = Math.floor(tiempoEnSegundos / 60);
  const segundos = tiempoEnSegundos % 60;

  const minutosStr = "min"; // minutos === 1 ? "minuto" : "minutos";
  const segundosStr = "seg"; // segundos === 1 ? "segundo" : "segundos";

  if (minutos === 0) {
    return `${segundos} ${segundosStr}`;
  } else if (segundos === 0) {
    return `${minutos} ${minutosStr}`;
  } else {
    return `${minutos} ${minutosStr} ${segundos} ${segundosStr}`;
  }
}

/**
 * Para usar Link de react router q es instantaneo
 */
export const isSitePage = (url) => {
  return typeof url === 'string' && url.substring(0, 11) === '/site?page=';
};

/**
 * Para que en un input solo se puedan ingresar numeros, con opcion de letra inicial y final para nro de documetos
 * posicionLetra debe ser 'ini' o 'fin'
 */
export const validateOnInputNumber = (numero) => {

  let lastChar = numero.charAt(numero.length - 1);

  if (lastChar !== null && !'0123456789'.includes(lastChar))
    numero = numero.substring(0, numero.length - 1);

  numero = numero.replace(/[^0-9]/g, ''); // dejar solo numeros

  return numero;
};

export const imprimirById = (elem) => {
  let mywindow = window.open('', 'PRINT', 'height=400,width=600');

  mywindow.document.write('<html><head><title>' + document.title + '</title>');
  mywindow.document.write('</head><body >');
  mywindow.document.write('<h1>' + document.title + '</h1>');
  mywindow.document.write(document.getElementById(elem).innerHTML);
  mywindow.document.write('</body></html>');

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/

  mywindow.print();
  mywindow.close();

  return true;
};

/**
 * Para descargar archivo respondido por el backend
 * @param Blob content
 * @param String file name to download
 */
export function inMemoryFileDownload(content, name) {
  // create file link in browser's memory
  const href = URL.createObjectURL(content);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', name); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export function fnNombreNavegador(codNavegador) {
  return codNavegador == 'C' ? 'Chrome' :
    codNavegador == 'F' ? 'Firefox' :
      codNavegador == 'S' ? 'Safari' :
        codNavegador == 'E' ? 'Edge' :
          'Desconocido';
}

export function fnNombreDispositivo(codDispositivo) {
  return codDispositivo == 'D' ? 'Desktop' :
    codDispositivo == 'M' ? 'Mobile' :
      codDispositivo == 'T' ? 'Tablet' :
        'Desconocido';
}
