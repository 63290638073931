import axios from 'axios';
import { useEffect, useState, useRef, memo, useCallback } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import { finLoading, iniLoading } from '../store/loadingSlice';
import { finLoading, iniLoading } from '../store/loadingSlice';

const containerStyle = {
  width: '100%',
  height: '600px',
};

// centro para que al inicio muestre asunción
const centroInicial = {
  lat: -25.307212,
  lng: -57.521618,
};
// distancia para que al inicio muestre todo asunción
const zoomInicial = 11;

function MapaComponente() {
  const dispatch = useDispatch();
  const backend = useSelector((state) => state.env.backend);
  const googleMapsApiKey = useSelector((state) => state.env.googleMapsApiKey);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
  });

  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState({}); // lista de coordenadas
  const [infoWindow, setInfoWindow] = useState(null); // lista de coordenadas
  const [sucursales, setSucursales] = useState([]);

  useEffect(() => {
    // cargar bounds con coordenadas de las sucursales
    for (let sucursal of sucursales)
      bounds.extend(new window.google.maps.LatLng(Number(sucursal.lat), Number(sucursal.lng)));
  }, [sucursales]);

  useEffect(() => {
    // si hay mapa, ajustar el zoom y los limites segun bounds
    if (map) {
      map.panToBounds(bounds);
      map.fitBounds(bounds);
    }
  }, [map]);

  // get sucursales visibles
  const getSucursales = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/sucursales?sucursalOrden=true`);
      let res = response.data;
      if (Array.isArray(res)) {
        for (let suc of res) suc.showInfoWindow = false;

        setSucursales(res);
      } else {
        toast.error('Error al obtener sucursales.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener sucursales.');
    }
    dispatch(finLoading());
  };

  const onLoad = useCallback(async function callback(map) {
    setBounds(new window.google.maps.LatLngBounds());
    await getSucursales();

    // inicializar mapa
    const boundsInicial = new window.google.maps.LatLngBounds(centroInicial);
    map.fitBounds(boundsInicial);
    map.setZoom(zoomInicial);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      clickableIcons={false}
      mapContainerStyle={containerStyle}
      center={centroInicial}
      zoom={zoomInicial}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* marcadores */}
      {sucursales &&
        sucursales.map((sucursal, index) => (
          <Marker
            key={sucursal.id}
            title={sucursal.nombre}
            position={new window.google.maps.LatLng(sucursal.lat, sucursal.lng)}
            onClick={() => {
              setInfoWindow(sucursal);
            }}
          />
        ))}

      {/* infowindows */}
      {infoWindow ? (
        <InfoWindow
          onCloseClick={() => {
            setInfoWindow(null);
          }}
          position={new window.google.maps.LatLng(Number(infoWindow.lat), infoWindow.lng)}
        >
          <div>
            {infoWindow.urlImagenSucursal && (
              <img
                src={`${backend}${infoWindow.urlImagenSucursal}`}
                style={{ width: '300px', height: 'auto' }}
              />
            )}
            <div>
              <h5 className="mb-2 mt-2">{infoWindow.nombre}</h5>
              <div>
                <p className="mb-1" style={{ fontSize: '1.1rem', textDecoration: 'underline' }}>
                  Dirección
                </p>
                <p className="mb-2" style={{ fontSize: '1rem', maxWidth: '300px' }}>
                  {infoWindow.direccion ? infoWindow.direccion : '-'}
                </p>
                <p className="mb-1" style={{ fontSize: '1.1rem', textDecoration: 'underline' }}>
                  Teléfono
                </p>
                <p className="mb-2" style={{ fontSize: '1rem', maxWidth: '300px' }}>
                  {infoWindow.telefono ? infoWindow.telefono : '-'}
                </p>
                <a
                  href={`https://www.google.com/maps/place/${infoWindow.lat},${infoWindow.lng}`}
                  target="_blank"
                >
                  Abrir en Google maps
                </a>
              </div>
            </div>
          </div>
        </InfoWindow>
      ) : (
        ''
      )}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default memo(MapaComponente);
