import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';

/**
 * ProductoInstance Component
 */
function ProductoInstance() {
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams(); // id del producto, null al crear
  const [selectEstados, setSelectEstados] = useState([]);
  const [flagEstado, setFlagEstado] = useState(false);
  const [producto, setProducto] = useState({
    // formulario producto
    idProducto: '',
    idMoneda: '',
    montoPrestamo: '',
    lineaCredito: '',
    //  formulario datos personales
    idTipoDocumento: '',
    numeroDocumento: '',
    nombre: '',
    apellido: '',
    // formulario contacto
    direccion: '',
    numeroDomicilio: '',
    referencia: '',
    idDepartamento: '',
    idCiudad: '',
    barrio: '',
    telefono: '',
    email: '',
    // formulario datos laborales
    idSucursal: '',
    lugarTrabajo: '',
    salarioActual: '',
    telefonoLaboral: '',
    urlDocumento: '',
    urlComprobanteIngreso: '',
    esPersona: '',
    emailedSucursal: '',
  }); // producto instance
  const [errores, setErrores] = useState({}); // form errores

  // handle change formulario pagina
  const handleChange = (name, value) => {
    if (name == 'idEstado') {
      setProducto({ ...producto, idEstado: value });
      setFlagEstado(true);
    }
  };

  // get producto by id
  const getProducto = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/formularios_producto/${id}`);
      let res = response.data;
      if (res.id) {
        res.idEstado = res.idEstado ? res.idEstado : '';
        setProducto(res);
      } else {
        toast.error('Error al obtener el producto.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener el producto.');
    }
    dispatch(finLoading());
  };

  const getEstados = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/estados`);
      let res = response.data;
      if (Array.isArray(res)) {
        setSelectEstados(res);
      } else {
        setSelectEstados([]);
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener productos.');
    }
    dispatch(finLoading());
  };

  const cambiarEstado = async () => {
    if (producto.idEstado == '') {
      toast.error('Seleccione un estado.');
      return;
    }

    dispatch(iniLoading());
    try {
      let response = await axios.put(`${backend}/api/formularios_producto/${producto.id}`, {
        idEstado: producto.idEstado,
      });
      let res = response.data;
      if (res.ok) {
        toast.success('Estado cambiado.');
        setFlagEstado(false);
      } else {
        toast.error('Error al cambiar estado.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al cambiar estado.');
    }
    dispatch(finLoading());
  };

  // use effect get pagina
  useEffect(() => {
    if (
      id &&
      authData &&
      (authData.user.rol == 'admin' ||
        authData.user.rol == 'comercial' ||
        authData.user.rol == 'seguros')
    ) {
      getProducto();
      getEstados();
    }
  }, [id]);


  const enviarEmail = async (e) => {
    if (!producto.id) {
      toast.error('Sin solicitud.');
      return;
    }

    dispatch(iniLoading());
    try {
      let response = await axios.post(`${backend}/api/formularios_producto/email/${producto.id}`);
      let res = response.data;
      if (res.ok) {
        toast.success('Enviado por email a la sucursal.');
        setProducto(p => ({ ...p, emailedSucursal: true }));
      } else {
        toast.error(res.error || 'No se pudo enviar el email.');
      }
    } catch (error) {
      console.error(error);
      toast.error('No se pudo enviar el email.');
    }
    dispatch(finLoading());
  };

  return (
    authData &&
    (authData.user.rol == 'admin' ||
      authData.user.rol == 'comercial' ||
      authData.user.rol == 'seguros') && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-5">
          <h4 className="font-weight-regular">Ver Producto</h4>
        </div>

        {/* formulario pagina */}
        <div className="mb-5">
          {/* btn guardar */}
          <div>
            {/* producto */}
            <div className="form-group">
              <label htmlFor="producto">Producto</label>
              <input
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.producto ? producto.producto.nombre : '-'}
                onChange={(e) => {
                  handleChange('idProducto', e.target.value);
                }}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="moneda">Moneda</label>
              <input
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.moneda ? producto.moneda.nombre : '-'}
                onChange={(e) => {
                  handleChange('idMoneda', e.target.value);
                }}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="moneda">Monto solicitado</label>
              <input
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.montoPrestamo ? producto.montoPrestamo : '-'}
                onChange={(e) => {
                  handleChange('montoPrestamo', e.target.value);
                }}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="lineaCredito">Línea crédito</label>
              <input
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.lineaCredito ? producto.lineaCredito : '-'}
                onChange={(e) => {
                  handleChange('lineaCredito', e.target.value);
                }}
                disabled={true}
              />
            </div>

            {/* datos basicos */}
            <div className="form-group">
              <label htmlFor="tipoDocumento">Tipo de documento</label>
              <input
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.tipo_documento ? producto.tipo_documento.nombre : '-'}
                onChange={(e) => {
                  handleChange('idTipoDocumento', e.target.value);
                }}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="documentNumber">Número de documento</label>
              <input
                type="number"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.numeroDocumento}
                onChange={(e) => {
                  handleChange('numeroDocumento', e.target.value);
                }}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">{producto.esPersona ? 'Nombre' : 'Razón social'}</label>
              <input
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.nombre}
                onChange={(e) => {
                  handleChange('nombre', e.target.value);
                }}
                disabled={true}
              />
            </div>
            {producto.esPersona && (
              <>
                <div className="form-group">
                  <label htmlFor="surname">Apellido</label>
                  <input
                    type="text"
                    autoComplete="off"
                    className={`form-control form-control-100`}
                    value={producto.apellido}
                    onChange={(e) => {
                      handleChange('apellido', e.target.value);
                    }}
                    disabled={true}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="estadocivil">Estado Civil</label>
                  <input
                    id="estadocivil"
                    type="text"
                    autoComplete="off"
                    className={`form-control form-control-100`}
                    value={producto.estadoCivil}
                    onChange={(e) => {
                      handleChange('estadoCivil', e.target.value);
                    }}
                    disabled={true}
                  />
                </div>
              </>
            )}

            {/* datos contacto */}
            <div className="form-group">
              <label htmlFor="address">Dirección</label>
              <input
                id="address"
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.direccion}
                onChange={(e) => {
                  handleChange('direccion', e.target.value);
                }}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="homenumber">Numero de domicilio</label>
              <input
                id="homenumber"
                type="text"
                autoComplete="off"
                className={
                  `form-control form-control-100 ${errores.numeroDomicilio ? 'is-invalid' : ''}`
                }
                value={producto.numeroDomicilio ? producto.numeroDomicilio : '-'}
                onChange={(e) => {
                  handleChange('numeroDomicilio', e.target.value);
                }}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="reference">Referencia</label>
              <input
                id="reference"
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.referencia}
                onChange={(e) => {
                  handleChange('referencia', e.target.value);
                }}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="department">Departamento</label>
              <input
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={
                  producto.ciudad && producto.ciudad.departamento
                    ? producto.ciudad.departamento.nombre
                    : '-'
                }
                onChange={(e) => {
                  handleChange('idDepartamento', e.target.value);
                }}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">Ciudad</label>
              <input
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.ciudad ? producto.ciudad.nombre : '-'}
                onChange={(e) => {
                  handleChange('idCiudad', e.target.value);
                }}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="barrio">Barrio</label>
              <input
                id="barrio"
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.barrio ? producto.barrio : '-'}
                onChange={(e) => {
                  handleChange('barrio', e.target.value);
                }}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobilePhone">Teléfono móvil</label>
              <div style={{ display: 'flex' }}>
                <input
                  id="mobilePhoneCountry"
                  type="text"
                  autoComplete="off"
                  className={`form-control form-control-100`}
                  value={producto.formato_telefono ? producto.formato_telefono.pais : '-'}
                  onChange={(e) => {
                    handleChange('pais', e.target.value);
                  }}
                  disabled={true}
                />
                <input
                  id="mobilePhonePrefix"
                  type="text"
                  autoComplete="off"
                  className={`form-control form-control-100`}
                  value={producto.formato_telefono ? producto.formato_telefono.prefijo : '-'}
                  onChange={(e) => {
                    handleChange('prefijo', e.target.value);
                  }}
                  disabled={true}
                />
                <input
                  id="mobilePhone"
                  type="text"
                  autoComplete="off"
                  className={`form-control form-control-100`}
                  value={producto.telefono ? producto.telefono : '-'}
                  onChange={(e) => {
                    handleChange('telefono', e.target.value);
                  }}
                  disabled={true}
                  style={{ width: '100%' }}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="email">Correo electrónico</label>
              <input
                id="email"
                type="mail"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.email}
                onChange={(e) => {
                  handleChange('email', e.target.value);
                }}
                disabled={true}
              />
            </div>

            {/* datos laborales */}
            <div className="form-group">
              <label htmlFor="sucursal">Sucursal de operación</label>
              <input
                type="text"
                autoComplete="off"
                className={`form-control form-control-100`}
                value={producto.sucursal ? producto.sucursal.nombreFormulario : '-'}
                onChange={(e) => {
                  handleChange('idSucursal', e.target.value);
                }}
                disabled={true}
              />
            </div>

            {producto.esPersona && (
              <>
                <div className="form-group">
                  <label htmlFor="work">Lugar de trabajo</label>
                  <input
                    type="text"
                    id="work"
                    autoComplete="off"
                    className={`form-control form-control-100`}
                    value={producto.lugarTrabajo}
                    onChange={(e) => {
                      handleChange('lugarTrabajo', e.target.value);
                    }}
                    disabled={true}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="salary">Salario actual</label>
                  <input
                    type="text"
                    id="salary"
                    autoComplete="off"
                    className={`form-control form-control-100`}
                    value={producto.salarioActual ? producto.salarioActual : '-'}
                    onChange={(e) => {
                      handleChange('salarioActual', e.target.value);
                    }}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="workPhone">Teléfono laboral</label>
                  <div style={{ display: 'flex' }}>
                    <input
                      id="workPhoneCountry"
                      type="text"
                      autoComplete="off"
                      className={`form-control form-control-100`}
                      value={
                        producto.formato_telefono_laboral
                          ? producto.formato_telefono_laboral.pais
                          : '-'
                      }
                      onChange={(e) => {
                        handleChange('pais', e.target.value);
                      }}
                      disabled={true}
                    />
                    <input
                      id="workPhonePrefix"
                      type="text"
                      autoComplete="off"
                      className={`form-control form-control-100`}
                      value={
                        producto.formato_telefono_laboral
                          ? producto.formato_telefono_laboral.prefijo
                          : '-'
                      }
                      onChange={(e) => {
                        handleChange('prefijo', e.target.value);
                      }}
                      disabled={true}
                    />
                    <input
                      id="workPhone"
                      type="text"
                      autoComplete="off"
                      className={`form-control form-control-100`}
                      value={producto.telefonoLaboral ? producto.telefonoLaboral : '-'}
                      onChange={(e) => {
                        handleChange('telefonoLaboral', e.target.value);
                      }}
                      disabled={true}
                      style={{ width: '100%' }}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="form-group">
              <label htmlFor="email">Enviado por email a la sucursal</label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <input
                  type='text'
                  className={
                    `form-control form-control-100 ${errores.idEstado ? 'is-invalid' : ''}`
                  }
                  value={producto.emailedSucursal ? 'SÍ' : 'NO'}
                  style={{ width: '100%' }}
                  disabled={true}
                />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={enviarEmail}
                  style={{ width: '10rem' }}
                >
                  {!producto.emailedSucursal ? 'Enviar Email' : 'Reenviar Email'}
                </button>
              </div>
              <span className="text-danger">{errores.emailedSucursal}</span>
            </div>

            <div className="form-group">
              <label htmlFor="email">Estado</label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <select
                  className={
                    `form-control form-control-100 ${errores.idEstado ? 'is-invalid' : ''}`
                  }
                  value={producto.idEstado}
                  onChange={(e) => {
                    handleChange('idEstado', e.target.value);
                  }}
                  style={{ width: '100%' }}
                >
                  <option value="">Seleccione...</option>
                  {selectEstados.map((estado) => (
                    <option key={estado.id} value={estado.id}>
                      {estado.nombre}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => cambiarEstado()}
                  disabled={!flagEstado}
                  style={{ width: '10rem' }}
                >
                  Cambiar Estado
                </button>
              </div>
              <span className="text-danger">{errores.idEstado}</span>
            </div>
            {producto.esPersona && (
              <>
                <a
                  className="btn btn-primary"
                  href={`${backend}/api/archivo?url=${producto.urlDocumento}`}
                  target="_blank"
                >
                  Ver documento
                </a>
                <a
                  className="btn btn-primary ml-5"
                  href={`${backend}/api/archivo?url=${producto.urlComprobanteIngreso}`}
                  target="_blank"
                >
                  Ver comprobante ingresos
                </a>
              </>
            )}
            <a
              className={`btn btn-primary ${producto.esPersona ? 'ml-5' : ''}`}
              href={`${backend}/api/export_formularios_producto/${producto.id}`}
              target="_blank"
            >
              <i className="fas fa-print"></i> Imprimir
            </a>
            <button className="btn btn-secondary ml-5" onClick={() => navigate('/admin/productos')}>
              <i className="fas fa-arrow-left"></i> Volver
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default ProductoInstance;
