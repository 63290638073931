import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isSitePage } from './Util';

/**
 * Website component: CategoriaNavbar
 * codigo = cmp_main_nav // creo que no hace falta
 */
// function PanelImagenTextoHorizontal({ muestra }) {
function CategoriaNavbar(hijos) {
  return (
    <div className="nav-dropdown">
      {hijos.lista.map((hijo, index) => (
        <div key={hijo.id}>
          <h4 className="main-nav-title">{hijo.nombre}</h4>
          <ul className="nav">
            {hijo.categorias_hijo_rec_visible.map((nieto, index) => (
              <li key={nieto.id}>
                {isSitePage(nieto.url) ? (
                  <Link to={nieto.url}>{nieto.nombre}</Link>
                ) : (
                  <a href={nieto.url}>{nieto.nombre}</a>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

// Setting default values for the props of component
CategoriaNavbar.defaultProps = {};

// prop "lista" pasado es una lista de categorias con sus respectivos hijos
CategoriaNavbar.propTypes = {
  lista: PropTypes.arrayOf(PropTypes.object),
};

export default CategoriaNavbar;
