import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import BrandPrimary from '../assets/img/brand-primary.png';
import { authLogout } from '../store/authSlice';

function AdminHeader() {
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const dispatch = useDispatch();

  // logout handler
  const logout = () => {
    let usu = authData.user.name;
    axios.post(`${backend}/api/logout`);
    dispatch(authLogout());
    toast.success('Log out correcto de ' + usu);
  };

  return (
    <header>
      <div className="container nav-container">
        <div className="d-flex items-center">
          <a href="/">
            <img src={BrandPrimary} className="logo" />
          </a>
          <nav className="main-nav" role="menubar">
            {authData && authData.user.rol == 'admin' && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link
                  to={'/admin/dashboard'}
                  className="dropdown-title"
                  style={{ fontSize: '0.8rem' }}
                >
                  Dashboard
                </Link>
              </li>
            )}
            {authData && authData.user.rol == 'admin' && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link
                  to={'/admin/paginas'}
                  className="dropdown-title"
                  style={{ fontSize: '0.8rem' }}
                >
                  Páginas
                </Link>
              </li>
            )}
            {authData && authData.user.rol == 'admin' && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link
                  to={'/admin/categorias'}
                  className="dropdown-title"
                  style={{ fontSize: '0.8rem' }}
                  title="Categorías"
                >
                  Categ.
                </Link>
              </li>
            )}
            {authData && authData.user.rol == 'admin' && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link
                  to={'/admin/sucursales'}
                  className="dropdown-title"
                  style={{ fontSize: '0.8rem' }}
                >
                  Sucursales
                </Link>
              </li>
            )}
            {authData && (authData.user.rol == 'admin' || authData.user.rol == 'rrhh') && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link to={'/admin/rrhh'} className="dropdown-title" style={{ fontSize: '0.8rem' }}>
                  RRHH
                </Link>
              </li>
            )}
            {authData && authData.user.rol == 'admin' && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link
                  to={'/admin/suscripciones'}
                  className="dropdown-title"
                  style={{ fontSize: '0.8rem' }}
                  title="Suscripciones"
                >
                  Suscrip.
                </Link>
              </li>
            )}
            {authData && (authData.user.rol == 'admin' || authData.user.rol == 'calidad') && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link
                  to={'/admin/reclamos'}
                  className="dropdown-title"
                  style={{ fontSize: '0.8rem' }}
                >
                  Reclamos
                </Link>
              </li>
            )}
            {authData &&
              (authData.user.rol == 'admin' ||
                authData.user.rol == 'comercial' ||
                authData.user.rol == 'seguros') && (
                <li
                  className="dropdown-toggle"
                  style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
                >
                  <Link
                    to={'/admin/productos'}
                    className="dropdown-title"
                    style={{ fontSize: '0.8rem' }}
                  >
                    Productos
                  </Link>
                </li>
              )}
            {authData && (authData.user.rol == 'admin' || authData.user.rol == 'finanzas') && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link
                  to={'/admin/cotizaciones'}
                  className="dropdown-title"
                  style={{ fontSize: '0.8rem' }}
                  title="Cotizaciones"
                >
                  Cotizac.
                </Link>
              </li>
            )}
            {authData && authData.user.rol == 'admin' && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link
                  to={'/admin/prestamos'}
                  className="dropdown-title"
                  style={{ fontSize: '0.8rem' }}
                >
                  Préstamos
                </Link>
              </li>
            )}
            {authData && authData.user.rol == 'admin' && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link
                  to={'/admin/terminos_condiciones'}
                  className="dropdown-title"
                  style={{ fontSize: '0.8rem' }}
                >
                  TyC
                </Link>
              </li>
            )}
            {authData && authData.user.rol == 'admin' && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link to={'/admin/users'} className="dropdown-title" style={{ fontSize: '0.8rem' }}>
                  Usuarios
                </Link>
              </li>
            )}
            {authData && authData.user.rol == 'admin' && (
              <li
                className="dropdown-toggle"
                style={{ paddingLeft: '0.7rem', paddingRight: '0.7rem' }}
              >
                <Link to={'/admin/servidor_email'} className="dropdown-title" style={{ fontSize: '0.8rem' }}>
                  Email
                </Link>
              </li>
            )}
          </nav>
        </div>
        <div className="online">
          <div className="current-clients">
            {!authData ? (
              <Link to="/login" className="text-primary">
                Ingresar <i className="fas fa-fw fa-sign-in-alt"></i>
              </Link>
            ) : (
              <Link to="/" className="text-primary" onClick={logout}>
                Log out <i className="fas fa-fw fa-sign-out-alt"></i>
              </Link>
            )}
          </div>
        </div>
        <div id="mobile-toggle">
          <a href="#">
            <i className="fas fa-bars"></i>
          </a>
        </div>
      </div>
    </header>
  );
}

export default AdminHeader;
