import PropTypes from 'prop-types';
import { memo } from 'react';
import { Link } from 'react-router-dom';

/**
 * CategoriaAccion Component
 */
function CategoriaAccion({ categoria }) {
  return (
    <div>
      <Link
        to={`/admin/categorias/${categoria.id}`}
        className="btn btn-sm btn-outline-secondary"
        title="Editar"
      >
        <i className="fas fa-fw fa-pencil-alt"></i>
      </Link>
    </div>
  );
}

// Typechecking props of the component
CategoriaAccion.propTypes = {
  categoria: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default memo(CategoriaAccion);
