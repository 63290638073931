import { useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

/**
 * FormProducto Component
 */
function FormProducto({ form, updateForm, siguientePagina, selectProductos, selectMonedas }) {
  const [campos, setCampos] = useState({
    idProducto: form.idProducto,
    idMoneda: form.idMoneda,
    montoPrestamo: form.montoPrestamo,
    lineaCredito: form.lineaCredito,
  });
  const [errores, setErrores] = useState({}); // form errores

  const validarFormProducto = () => {
    let avanzar = true;
    setErrores({});

    if (campos.idProducto == '') {
      setErrores((e) => ({ ...e, idProducto: 'Seleccione un producto.' }));
      avanzar = false;
    }

    if (campos.idProducto != 2) {
      // si no es tarjeta
      if (campos.idMoneda == '') {
        setErrores((e) => ({ ...e, idMoneda: 'Seleccione una moneda.' }));
        avanzar = false;
      }
    }

    if (campos.idProducto == 1) {
      // prestamos
      if (campos.montoPrestamo == '') {
        setErrores((e) => ({ ...e, montoPrestamo: 'Ingrese el monto del préstamo.' }));
        avanzar = false;
      }
    }

    if (campos.idProducto == 2) {
      // tarjetas
      if (campos.lineaCredito == '') {
        setErrores((e) => ({ ...e, lineaCredito: 'Ingrese la línea de crédito solicitada.' }));
        avanzar = false;
      }
    }

    if (avanzar) siguientePagina();
  };

  const updateCampo = (name, value) => {
    if (name == 'idProducto')
      setCampos({ ...campos, [name]: value, idMoneda: '', montoPrestamo: '', lineaCredito: '' });
    else setCampos({ ...campos, [name]: value });
    updateForm([name], value);
  };

  return (
    <>
      <div className="form-group">
        <label htmlFor="product">
          Producto <span className="text-danger">*</span>
        </label>
        <select
          className={`form-control form-control-100 ${errores.idProducto ? 'is-invalid' : ''}`}
          value={campos.idProducto}
          onChange={(e) => {
            updateCampo('idProducto', e.target.value);
          }}
        >
          <option value="">Seleccione...</option>
          {selectProductos.map((producto) => (
            <option key={producto.id} value={producto.id}>
              {producto.nombre}
            </option>
          ))}
        </select>
        <span className="text-danger">{errores.idProducto}</span>
      </div>
      {campos.idProducto != 2 && (
        <div className="form-group">
          <label htmlFor="money">
            Moneda <span className="text-danger">*</span>
          </label>
          <select
            className={`form-control form-control-100 ${errores.idMoneda ? 'is-invalid' : ''}`}
            value={campos.idMoneda}
            onChange={(e) => {
              updateCampo('idMoneda', e.target.value);
            }}
          >
            <option value="">Seleccione...</option>
            {selectMonedas.map((moneda) => (
              <option key={moneda.id} value={moneda.id}>
                {moneda.nombre}
              </option>
            ))}
          </select>
          <span className="text-danger">{errores.idMoneda}</span>
        </div>
      )}
      {campos.idProducto == 1 && (
        <div className="form-group">
          <label htmlFor="money">
            Monto solicitado <span className="text-danger">*</span>
          </label>
          <NumericFormat
            value={campos.montoPrestamo || ''}
            onValueChange={(v, s) => updateCampo('montoPrestamo', v.value)}
            valueIsNumericString={true}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            className={`form-control form-control-100 text-left ${
              errores.montoPrestamo ? 'is-invalid' : ''
            }`}
            maxLength={14}
          />
          <span className="text-danger">{errores.montoPrestamo}</span>
        </div>
      )}
      {campos.idProducto == 2 && (
        <div className="form-group">
          <label htmlFor="money">
            Línea de crédito a solicitar <span className="text-danger">*</span>
          </label>
          <NumericFormat
            value={campos.lineaCredito || ''}
            onValueChange={(v, s) => updateCampo('lineaCredito', v.value)}
            valueIsNumericString={true}
            thousandSeparator="."
            decimalSeparator=","
            allowNegative={false}
            decimalScale={0}
            className={`form-control form-control-100 text-left ${
              errores.lineaCredito ? 'is-invalid' : ''
            }`}
            maxLength={14}
          />
          <span className="text-danger">{errores.lineaCredito}</span>
        </div>
      )}
      <p>
        <span className="text-danger">*</span> Campos obligatorios
      </p>
      <div className="mt-4 mb-4">
        <button type="button" onClick={validarFormProducto} className="btn btn-primary">
          Siguiente <i className="ml-2 fas fa-arrow-right"></i>
        </button>
      </div>
    </>
  );
}

export default FormProducto;
