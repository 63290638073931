import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isSitePage } from './Util';
import { useSelector } from 'react-redux';

/**
 * Website component: CategoriaFooter
 * codigo = cmp_main_nav // creo que no hace falta
 */
// function PanelImagenTextoHorizontal({ muestra }) {
function CategoriaFooter(categorias) {
  const backend = useSelector((state) => state.env.backend);
  // console.log('categorias footer');
  // console.log(categorias);
  return (
    <div className="footer-links">
      {categorias.lista &&
        categorias.lista.map((padre, index) => (
          <div key={padre.id}>
            <h5>{padre.nombre}</h5>
            <div className="nav">
              {padre.categorias_hijo_rec_visible.map((hijo, index) => (
                <li key={hijo.id}>
                  {hijo.nombreImg ? (
                    <a href={hijo.url} target="_blank" className="d-block mb-1">
                      <img src={`${backend}/img/${hijo.nombreImg}`} className="store-link" />
                    </a>
                  ) : isSitePage(hijo.url) ? (
                    <Link to={hijo.url}>{hijo.nombre}</Link>
                  ) : (
                    <a href={hijo.url}>{hijo.nombre}</a>
                  )}
                </li>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
}

// Setting default values for the props of component
CategoriaFooter.defaultProps = {};

// Typechecking props of the component
// PanelImagenTextoHorizontal.propTypes = {
//   muestra: PropTypes.shape({
//     srcImgBg: PropTypes.string.isRequired, // ejemplo: 'https://dummyimage.com/640x640/e6e6e6/f5f5f5'
//     titulo: PropTypes.string.isRequired,
//     parrafos: PropTypes.arrayOf(
//       PropTypes.shape({
//         subtitulo: PropTypes.string.isRequired,
//         texto: PropTypes.string.isRequired,
//       })
//     ).isRequired,
//     enlace: PropTypes.string, // opcional
//     label: PropTypes.string, // opcional
//   }).isRequired,
// };

// prop "lista" pasado es una lista de categorias con sus respectivos hijos
CategoriaFooter.propTypes = {
  lista: PropTypes.arrayOf(PropTypes.object),
  // lista: PropTypes.arrayOf(PropTypes.shape({
  //   id: PropTypes.number,
  //   nombre: PropTypes.string,
  //   // idCategoriaPadre: PropTypes.string,
  //   categoria_hijo_rec:PropTypes.object,
  //   IconSRC: PropTypes.string,
  //   inlineSyle: PropTypes.string,
  // })),
};

export default CategoriaFooter;
