import { useState } from 'react';
import DataTableBase from '../DataTableBase';
import SucursalAccion from './SucursalAccion';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

function SucursalLista() {
  const authData = useSelector((state) => state.auth.value);

  const columns = [
    {
      name: 'Nombre',
      selector: (row) => row.nombre,
      sortable: true,
      sortField: 'nombre',
    },
    {
      name: 'Dirección',
      selector: (row) => (row.direccion ? row.direccion : '-'),
      sortable: true,
      sortField: 'direccion',
    },
    {
      name: 'Estado',
      selector: (row) => (row.visible ? 'Visible' : 'Oculto'),
      sortable: true,
      sortField: 'visible',
    },
    {
      name: 'Orden',
      selector: (row) => row.orden,
      sortable: true,
      sortField: 'orden',
    },
    {
      name: 'Acciones',
      cell: (row) => <SucursalAccion sucursal={row} />,
    },
  ];

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-3">
          <h4 className="font-weight-regular">Gestión de Sucursales</h4>
          <Link to={'/admin/sucursales/new'} className="btn btn-primary">
            <i className="fas fa-fw fa-plus"></i> Nueva Sucursal
          </Link>
        </div>

        <div>
          <DataTableBase url={'/api/sucursales'} columns={columns} />
        </div>
      </div>
    )
  );
}

export default SucursalLista;
