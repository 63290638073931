import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isSitePage } from '../Util';

/**
 * Website component: PanelTabsEnlaces
 * codigo = cmp_tabs_els
 */
function PanelTabsEnlaces({ muestra }) {
  const isActive = (enlace) => {
    return window.location.href.includes(enlace);
  };

  return (
    <div className="container">
      <div className="tabs-group mb-5">
        {muestra.tabs.map((t, i) =>
          isSitePage(t.enlace) ? (
            <Link
              to={t.enlace}
              className={`tabs text-center ${isActive(t.enlace) ? 'tabs--active' : ''}`}
              key={i}
            >
              <span>{t.label}</span>
            </Link>
          ) : (
            <a
              href={t.enlace}
              className={`tabs text-center ${isActive(t.enlace) ? 'tabs--active' : ''}`}
              key={i}
            >
              <span>{t.label}</span>
            </a>
          )
        )}
      </div>
    </div>
  );
}

// Setting default values for the props of component
PanelTabsEnlaces.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelTabsEnlaces.propTypes = {
  muestra: PropTypes.shape({
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        enlace: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default PanelTabsEnlaces;
