import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';

import './index.css';
import 'chartist/dist/index.css';
import store from './store/store';
import App from './App';
import SiteLayout from './layouts/SiteLayout';
import SitePage from './layouts/SitePage';
import AdminHome from './components/admin/AdminHome';
import AdminLayout from './layouts/AdminLayout';
import PaginaLista from './components/admin/PaginaLista';
import PaginaInstance from './components/admin/PaginaInstance';
import CategoriaLista from './components/admin/CategoriaLista';
import CategoriaInstance from './components/admin/CategoriaInstance';
import SucursalLista from './components/admin/SucursalLista';
import SucursalInstance from './components/admin/SucursalInstance';
import FormTerminosCondiciones from './components/admin/FormTerminosCondiciones';

import SiteMapa from './components/MapaSucursales';
import Login from './components/Login';
import UserLista from './components/admin/UserLista';
import UserInstance from './components/admin/UserInstance';
import CalculadoraPrestamos from './components/site/CalculadoraPrestamos';
import SolicitudProducto from './components/site/SolicitudProducto';
import SolicitudProductoConfirmacion from './components/site/SolicitudProductoConfirmacion';
import FormRRHH from './components/site/FormRRHH';
import RRHHLista from './components/admin/RRHHLista';
import ProductoLista from './components/admin/ProductoLista';
import ProductoInstance from './components/admin/ProductoInstance';
import Cotizaciones from './components/admin/Cotizaciones';
import Prestamos from './components/admin/Prestamos';
import SuscripcionLista from './components/admin/SuscripcionLista';
import FormReclamos from './components/site/FormReclamos';
import ReclamoLista from './components/admin/ReclamoLista';
import AdminDashboard from './components/admin/AdminDashboard';
import ConfigEmailServer from './components/admin/ConfigEmailServer';
// import PanelTerminosCondiciones from './components/site/PanelTerminosCondiciones';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="site" element={<SiteLayout />}>
            <Route index element={<SitePage />} />
            <Route path="mapa" element={<SiteMapa />} />
            <Route path="calculadora_prestamos" element={<CalculadoraPrestamos />} />
            <Route path="producto" element={<SolicitudProducto />} />
            <Route path="rrhh" element={<FormRRHH />} />
            <Route path="confirmacion" element={<SolicitudProductoConfirmacion />} />
            <Route path="reclamos" element={<FormReclamos />} />
            {/* <Route path="terminos_condiciones" element={<PanelTerminosCondiciones />} /> */}
          </Route>
          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<AdminHome />} />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="paginas" element={<PaginaLista />} />
            <Route path="paginas/new" element={<PaginaInstance />} />
            <Route path="paginas/:id" element={<PaginaInstance />} />
            <Route path="categorias" element={<CategoriaLista />} />
            <Route path="categorias/new" element={<CategoriaInstance />} />
            <Route path="categorias/:id" element={<CategoriaInstance />} />
            <Route path="sucursales" element={<SucursalLista />} />
            <Route path="sucursales/new" element={<SucursalInstance />} />
            <Route path="sucursales/:id" element={<SucursalInstance />} />
            <Route path="users" element={<UserLista />} />
            <Route path="users/new" element={<UserInstance />} />
            <Route path="users/:id" element={<UserInstance />} />
            <Route path="cotizaciones" element={<Cotizaciones />} />
            <Route path="prestamos" element={<Prestamos />} />
            <Route path="rrhh" element={<RRHHLista />} />
            <Route path="rrhh/:id" element={<FormRRHH />} />
            <Route path="productos" element={<ProductoLista />} />
            <Route path="productos/:id" element={<ProductoInstance />} />
            <Route path="suscripciones" element={<SuscripcionLista />} />
            <Route path="reclamos" element={<ReclamoLista />} />
            <Route path="reclamos/:id" element={<FormReclamos />} />
            <Route path="terminos_condiciones" element={<FormTerminosCondiciones />} />
            <Route path="servidor_email" element={<ConfigEmailServer />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="*" element={<SiteLayout noPage={true} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
