import axios from 'axios';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BrandPrimary from '../assets/img/brand-primary.png';
import { authLogin } from '../store/authSlice';
import { finLoading, iniLoading } from '../store/loadingSlice';
import ReCAPTCHA from 'react-google-recaptcha';

/**
 * Pantalla de login
 */
function Login() {
  const dispatch = useDispatch();
  const backend = useSelector((state) => state.env.backend);
  const captchaSiteKey = useSelector((state) => state.env.captchaSiteKey);
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [login, setLogin] = useState({}); // form data
  const [errores, setErrores] = useState({}); // form errores

  // handle change formulario login
  const handleChange = (name, value) => {
    setLogin((log) => ({ ...log, [name]: value }));
  };

  const validarLogin = () => {
    let res = true;
    setErrores(() => ({}));

    if (!login.user) {
      res = false;
      setErrores((e) => ({ ...e, user: 'Por favor, complete este campo.' }));
    }

    if (!login.password) {
      res = false;
      setErrores((e) => ({ ...e, password: 'Por favor, complete este campo.' }));
    }

    if (!captchaRef.current.getValue()) {
      setErrores((e) => ({ ...e, captcha: 'Por favor, complete el captcha.' }));
      return;
    }

    return res;
  };

  const clickLogin = () => {
    if (validarLogin()) {
      dispatch(iniLoading());

      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      axios
        .post(`${backend}/api/login`, { ...login, token })
        .then((response) => {
          let res = response.data;
          if (res.token) {
            dispatch(authLogin(res));
            toast.success('Ingreso correcto de ' + res.user.name);
            navigate('/admin');
          } else {
            toast.error(res.error || 'Error de login.');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('Error de login.');
        })
        .finally(() => {
          dispatch(finLoading());
        });
    }
  };

  return (
    <>
      <header>
        <div className="container nav-container">
          <div className="d-flex items-center">
            <a href="/">
              <img src={BrandPrimary} className="logo" />
            </a>
          </div>
        </div>
      </header>

      <div className="container">
        <div className="d-flex content-center mt-5 mb-5">
          <div style={{ width: '90%', maxWidth: '360px' }}>
            <div className="mb-5">
              <h4 className="font-weight-regular text-center">Log in</h4>
            </div>

            {/* formulario login */}
            <div className="mb-5">
              {/* input user */}
              <div className="form-group">
                <label htmlFor="user">
                  Usuario o correo <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control form-control-100 ${errores.user ? 'is-invalid' : ''}`}
                  id="user"
                  value={login.user || ''}
                  onChange={(e) => handleChange('user', e.target.value)}
                />
                <span className="text-danger">{errores.user}</span>
              </div>

              {/* input password */}
              <div className="form-group">
                <label htmlFor="password">
                  Contraseña <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className={`form-control form-control-100 ${
                    errores.password ? 'is-invalid' : ''
                  }`}
                  id="password"
                  value={login.password || ''}
                  onChange={(e) => handleChange('password', e.target.value)}
                  onKeyDown={(e) => (e.key == 'Enter' ? clickLogin() : null)}
                />
                <span className="text-danger">{errores.password}</span>
              </div>

              {/* captcha */}
              <ReCAPTCHA sitekey={captchaSiteKey} ref={captchaRef} style={{ marginLeft: '30px' }} />
              <span className="text-danger" style={{ marginLeft: '30px' }}>
                {errores.captcha}
              </span>

              {/* btn login */}
              <div className="text-center" style={{ marginTop: '30px' }}>
                <button className="btn btn-primary" onClick={clickLogin}>
                  <i className="fas fa-fw fa-sign-in-alt"></i> Log in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
