import axios from 'axios';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';

/**
 * UserAccion Component
 */
function UserAccion({ user }) {
  const backend = useSelector((state) => state.env.backend);
  const dispatch = useDispatch();
  const [elim, setElim] = useState(false);

  const eliminar = () => {
    if (window.confirm('Por favor, confirme para eliminar.')) {
      dispatch(iniLoading());
      axios
        .delete(`${backend}/api/users/${user.id}`)
        .then((response) => {
          let res = response.data;
          if (res.ok) {
            toast.success('Usuario eliminado!');
            setElim(true);
          } else {
            toast.error(res.error || 'Error al eliminar el usuario.');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('Error al eliminar el usuario.');
        })
        .finally(() => {
          dispatch(finLoading());
        });
    }
  };

  return !elim ? (
    <div>
      <Link
        to={`/admin/users/${user.id}`}
        className="btn btn-sm btn-outline-secondary"
        title="Editar"
      >
        <i className="fas fa-fw fa-pencil-alt"></i>
      </Link>
      <button onClick={eliminar} className="btn btn-sm btn-outline-secondary ml-3" title="Eliminar">
        <i className="fas fa-fw fa-trash-alt"></i>
      </button>
    </div>
  ) : (
    <div>
      <span className="text-danger font-italic">Eliminado!</span>
    </div>
  );
}

// Typechecking props of the component
UserAccion.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default UserAccion;
