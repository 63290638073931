import PropTypes from 'prop-types';
import { useState } from 'react';

/**
 * Website component: PanelListaPreguntas
 * codigo = cmp_list_pregs
 */
function PanelListaPreguntas({ muestra }) {
  const [open, setOpen] = useState({});

  const openFaq = (i) => {
    setOpen((o) => ({ ...o, ['preg' + i]: !o['preg' + i] }));
  };

  return (
    <section className="container">
      <div className="faq">
        <h2>{muestra.titulo}</h2>
        {muestra.preguntas.map((preg, i) => (
          <div key={i} className="faq-item" onClick={() => openFaq(i)}>
            <div className="faq-item-title">
              <p>{preg.pregunta}</p>
              <i
                className={`fas fa-plus ${open['preg' + i] ? 'fa-rotate-45' : ''}`}
                aria-hidden="true"
              ></i>
            </div>
            <div
              className={`faq-answer ${open['preg' + i] ? 'active' : ''}`}
              style={{ textAlign: preg.respuesta_justify ? 'justify' : 'left' }}
            >
              {preg.respuesta}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

// Setting default values for the props of component
PanelListaPreguntas.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelListaPreguntas.propTypes = {
  muestra: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    preguntas: PropTypes.arrayOf(
      PropTypes.shape({
        pregunta: PropTypes.string.isRequired,
        respuesta: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default PanelListaPreguntas;
