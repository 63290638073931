import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { capitalize, formatCodigo } from '../Util';
import PaginaComponentes from './PaginaComponentes';

/**
 * PaginaInstance Component
 */
function PaginaInstance() {
  const [readOnly, setReadOnly] = useState(false);
  const backend = useSelector((state) => state.env.backend);
  const authData = useSelector((state) => state.auth.value);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams(); // id de la pagina, null al crear
  const [pagina, setPagina] = useState({
    codigo: '',
    nombre: '',
  }); // pagina instance
  const [errores, setErrores] = useState({}); // form errores

  // handle change formulario pagina
  const handleChange = (name, value) => {
    if (readOnly) {
      console.log('read only: no change');
    } else {
      setPagina((pag) => ({ ...pag, [name]: value }));
    }
  };

  // get pagina by id
  const getPagina = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/paginas/${id}`);
      let res = response.data;
      if (res.id) {
        setPagina(res);
      } else {
        toast.error('Error al obtener la página.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener la página.');
    }
    dispatch(finLoading());
  };

  // use effect get pagina
  useEffect(() => {
    if (id && authData && authData.user.rol == 'admin') {
      getPagina();
    }
  }, [id]);

  // store new pagina
  const storePagina = () => {
    dispatch(iniLoading());
    axios
      .post(`${backend}/api/paginas`, pagina)
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Nueva página guardada!');
          navigate('/admin/paginas/' + res.id);
        } else if (res.errores) {
          setErrores(res.errores);
        } else {
          toast.error(res.error || 'Error al guardar la página.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar la página.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // update current pagina
  const updatePagina = () => {
    dispatch(iniLoading());
    axios
      .put(`${backend}/api/paginas/${pagina.id}`, pagina)
      .then((response) => {
        let res = response.data;
        if (res.id) {
          toast.success('Página guardada!');
        } else if (res.errores) {
          setErrores(res.errores);
        } else {
          toast.error(res.error || 'Error al guardar la página.');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Error al guardar la página.');
      })
      .finally(() => {
        dispatch(finLoading());
      });
  };

  // validar form pagina
  const validarPagina = () => {
    let res = true;
    setErrores({});
    if (!pagina.codigo.trim()) {
      setErrores((e) => ({ ...e, codigo: 'Ingrese el código/enlace de la página.' }));
      res = false;
    }
    if (!pagina.nombre.trim()) {
      setErrores((e) => ({ ...e, nombre: 'Ingrese el nombre de la página.' }));
      res = false;
    }
    return res;
  };

  // click guardar
  const clickGuardar = () => {
    if (validarPagina()) {
      if (id == null) {
        storePagina();
      } else {
        updatePagina();
      }
    }
  };

  return (
    authData &&
    authData.user.rol == 'admin' && (
      <div className="container mt-5">
        <div className="d-flex content-between items-center mb-5">
          {id == null ? (
            <h4 className="font-weight-regular">Nueva Página</h4>
          ) : (
            <h4 className="font-weight-regular">Editar Página</h4>
          )}
        </div>

        {/* formulario pagina */}
        <div className="mb-5">
          {/* input codigo */}
          <div className="form-group">
            <label htmlFor="codigo">
              Código/Enlace <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errores.codigo ? 'is-invalid' : ''}`}
              id="codigo"
              value={pagina.codigo}
              onChange={(e) => handleChange('codigo', formatCodigo(e.target.value))}
            />
            <span className="text-danger">{errores.codigo}</span>
          </div>

          {/* input nombre */}
          <div className="form-group">
            <label htmlFor="nombre">
              Nombre <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className={`form-control ${errores.nombre ? 'is-invalid' : ''}`}
              id="nombre"
              value={pagina.nombre}
              onChange={(e) => handleChange('nombre', capitalize(e.target.value))}
            />
            <span className="text-danger">{errores.nombre}</span>
          </div>

          {/* btn guardar */}
          <div>
            <button className="btn btn-primary" onClick={clickGuardar}>
              <i className="fas fa-save"></i> Guardar
            </button>
            <button className="btn btn-secondary ml-5" onClick={() => navigate('/admin/paginas')}>
              <i className="fas fa-arrow-left"></i> Volver
            </button>
          </div>
        </div>

        {id != null && (
          <>
            <hr className="mb-5" />
            <PaginaComponentes idPagina={Number.parseInt(id)} />
          </>
        )}
      </div>
    )
  );
}

export default PaginaInstance;
