import PropTypes from 'prop-types';
import { getImageUrl } from '../Util';

/**
 * Website component: PanelFilaResumenes
 * codigo = cmp_fil_ress
 */
function PanelFilaResumenes({ muestra }) {
  return (
    <>
      {(!muestra.estilo || muestra.estilo == 'normal') && (
        <section className="our-numbers">
          <div className="container">
            <h2 className="text-center">{muestra.titulo}</h2>
            <div className="numbers-items">
              {muestra.resumenes.map((res, i) => (
                <div className="text-center" key={i}>
                  <h1
                    className="text-primary"
                    style={{ textAlign: res.subtitulo_justify ? 'justify' : 'center' }}
                  >
                    {res.subtitulo}
                  </h1>
                  <p style={{ textAlign: res.texto_justify ? 'justify' : 'center' }}>{res.texto}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
      {muestra.estilo == 'descriptivo' && (
        <section className="values-sect">
          <div className="container">
            <h2 className="values-sect__title">{muestra.titulo}</h2>
            <div className="our-values">
              {muestra.resumenes.map((res, i) => (
                <div key={i} style={{ flexBasis: '100%' }}>
                  <h3
                    className="our-values__title"
                    style={{ textAlign: res.subtitulo_justify ? 'justify' : 'left' }}
                  >
                    {res.subtitulo}
                  </h3>
                  <p style={{ textAlign: res.texto_justify ? 'justify' : 'left' }}>{res.texto}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </>
  );
}

// Setting default values for the props of component
PanelFilaResumenes.defaultProps = {};

// Typechecking props of the component
// prop "muestra" pasado es objeto parseado de un json guardado en BD
PanelFilaResumenes.propTypes = {
  muestra: PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    resumenes: PropTypes.arrayOf(
      PropTypes.shape({
        subtitulo: PropTypes.string.isRequired,
        texto: PropTypes.string.isRequired,
      })
    ).isRequired,
    estilo: PropTypes.string, // opcional, in ['normal', 'descriptivo']
  }).isRequired,
};

export default PanelFilaResumenes;
