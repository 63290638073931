import BrandPrimary from '../../assets/img/brand-primary.png';
import { Link, useNavigate, useParams } from 'react-router-dom';

/**
 * SolicitudProductoConfirmacion Component
 */
function SolicitudProductoConfirmacion() {
  return (
    <div className="modal">
      <div className="modal-header">
        <a href="/">
          <img src={BrandPrimary} className="modal-logo" />{' '}
        </a>

        <h4 className="font-weight-regular mb-2">Solicitud realizada</h4>
        <h1>La solicitud fue enviada correctamente.</h1>
      </div>

      <div className="modal-body">
        <p>Revisa tu bandeja de correo electrónico</p>
        <div className="mt-4 mb-4">
          <a href="/" className="btn btn-primary">
            Ir al inicio
          </a>
        </div>
      </div>
    </div>
  );
}

export default SolicitudProductoConfirmacion;
