// import MenuParaVos from '../components/tmp/MenuParaVos';
import BrandPrimary from '../assets/img/brand-primary.png';
import CategoriaNavbar from '../components/CategoriaNavbar';

import { useSearchParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { finLoading, iniLoading } from '../store/loadingSlice';
import { authLogout } from '../store/authSlice';
import { toast } from 'react-toastify';

function SiteHeader() {
  const dispatch = useDispatch();
  const backend = useSelector((state) => state.env.backend);
  const navigate = useNavigate();
  const [categorias, setCategorias] = useState(null);
  const authData = useSelector((state) => state.auth.value);
  const rol = authData ? authData.user.rol : '';
  const urlLogo24hs = process.env.REACT_APP_LOGO_24HS;

  const roles = {
    admin: 'Administrador',
    rrhh: 'RRHH',
    calidad: 'Calidad',
    comercial: 'Comercial',
  };

  // fetch categoria
  useEffect(() => {
    dispatch(iniLoading());
    axios
      .get(`${backend}/api/categorias?posicion=navbar`)
      .then((response) => {
        let res = response.data;

        if (Array.isArray(res)) {
          setCategorias(res);
        } else {
          navigate('/404');
        }
      })
      .catch((error) => {
        // no hacer toast xq es publico
        console.error(error);
      })
      .finally(() => {
        dispatch(finLoading());
      });
  }, []);

  // logout handler
  const logout = () => {
    let usu = authData.user.name;
    axios.post(`${backend}/api/logout`);
    dispatch(authLogout());
    toast.success('Log out correcto de ' + usu);
  };

  const showMenu = () => {
    document.querySelector('.main-nav').classList.toggle('show');
  };

  return (
    <header>
      <div className="container nav-container">
        <div className="d-flex items-center">
          <a href="/">
            <img src={BrandPrimary} className="logo" />
          </a>
          <nav className="main-nav" role="menubar">
            {categorias &&
              categorias.map((cat, index) => (
                <li key={cat.id} className="dropdown-toggle">
                  <div href="#" className="dropdown-title">
                    {cat.nombre} <i className="fas fa-angle-down"></i>
                  </div>
                  <CategoriaNavbar key={cat.id} lista={cat.categorias_hijo_rec_visible} />
                </li>
              ))}
          </nav>
        </div>
        <div className="online">
          <div className="current-clients text-right">
            {!authData ? (
              <>
                {/*<Link to="/login" className="text-primary">
                  Ingresar <i className="fas fa-fw fa-sign-in-alt"></i>
                </Link>i*/}
                {/* <a id="solicitud-clave" href="#" className="text-muted">
                  Solicitar acceso
                </a> */}
                {<a
                  href="https://secure.zbanco.com.py/homebanking/index.php/site/login"
                  target="_blank"
                >
                  <img src={urlLogo24hs} />
                </a>}
              </>
            ) : (
              <>
                <Link to="/" className="text-primary" onClick={logout}>
                  Log out <i className="fas fa-fw fa-sign-out-alt"></i>
                </Link>
                <Link to="/admin" className="text-muted">
                  {rol in roles ? roles[rol] : '-'} <i className="fas fa-fw fa-cog"></i>
                </Link>
              </>
            )}
          </div>
          <div id="new-client">
            <a href="/site/producto" className="btn btn-primary">
              Quiero ser cliente
            </a>
          </div>
        </div>
        <div id="mobile-toggle" onClick={showMenu}>
          <span>
            <i className="fas fa-bars"></i>
          </span>
        </div>
      </div>
    </header>
  );
}

export default SiteHeader;
