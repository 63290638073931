import SiteFooter from './SiteFooter';
import SiteHeader from './SiteHeader';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import PanelPresentacionPagina from '../components/site/PanelPresentacionPagina';

function SiteLayout({ noPage }) {
  return (
    <>
      <SiteHeader />
      {!noPage ? (
        <div>
          <Outlet />
        </div>
      ) : (
        <PanelPresentacionPagina muestra={{ titulo: 'Página no encontrada!' }} />
      )}
      <SiteFooter />
    </>
  );
}

SiteLayout.propTypes = {
  noPage: PropTypes.bool,
};

export default SiteLayout;
