import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { finLoading, iniLoading } from '../../store/loadingSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { formatMoney } from '../Util';

/**
 * CalculadoraPrestamos Component
 */
function CalculadoraPrestamos() {
  const backend = useSelector((state) => state.env.backend);
  const dispatch = useDispatch();
  const location = useLocation();

  const tipoPrestamo = new URLSearchParams(location.search).get('tipoPrestamo');

  const [prestamo, setPrestamo] = useState({
    idTipoPrestamo: tipoPrestamo ? tipoPrestamo : '',
    monto: '',
    idMoneda: '',
    plazo: '',
    calculoMonto: '',
    calculoPlazo: '',
    calculoMoneda: '',
  });
  const [errores, setErrores] = useState({}); // form errores
  const [tiposPrestamo, setTiposPrestamo] = useState([]);
  const [init, setInit] = useState(false);
  const [idGuarani, setIdGuarani] = useState(0);
  const [codigosMoneda, setCodigosMoneda] = useState([]);
  const [monedas, setMonedas] = useState([]);
  const [valorDolar, setValorDolar] = useState(0);
  const [arrDatosPrestamo, setArrDatosPrestamo] = useState([]);
  const [mensajeCalculadora, setMensajeCalculadora] = useState('');

  const [datosCalculo, setDatosCalculo] = useState({
    interes: -1,
    gastosAdministrativos: -1,
    tasaImpuesto: -1,
    tasaSeguro: -1,
    extraGastos: 0,
  });

  const updatePrestamo = (name, value) => {
    if (name == 'monto' && value.toString().length > 10) return; // para que no se introduzca montos de mas de 10 digitos

    if (name == 'idTipoPrestamo') setPrestamo({ ...prestamo, [name]: value, idMoneda: '' });
    else setPrestamo({ ...prestamo, [name]: value });
  };

  const validarPrestamo = () => {
    let calcular = true;
    setErrores({});

    if (prestamo.idTipoPrestamo == '') {
      setErrores((e) => ({ ...e, idTipoPrestamo: 'Seleccione un tipo de préstamo.' }));
      calcular = false;
      return; // para poder cargar monedas disponibles
    }

    if (!parseFloat(prestamo.monto)) {
      setErrores((e) => ({ ...e, monto: 'Ingrese un monto.' }));
      calcular = false;
    }

    if (parseFloat(prestamo.monto) <= 0) {
      setErrores((e) => ({ ...e, monto: 'Ingrese un monto válido.' }));
      calcular = false;
    }

    if (!prestamo.idMoneda) {
      setErrores((e) => ({ ...e, idMoneda: 'Seleccione una moneda.' }));
      calcular = false;
    }

    if (
      prestamo.idMoneda &&
      prestamo.idMoneda == idGuarani &&
      parseInt(prestamo.monto) < arrDatosPrestamo[prestamo.idTipoPrestamo].monto_min
    ) {
      setErrores((e) => ({
        ...e,
        monto: `El monto mínimo a solicitar es de Gs. ${arrDatosPrestamo[
          prestamo.idTipoPrestamo
        ].monto_min.toLocaleString()}.`,
      }));
      calcular = false;
    }

    if (
      prestamo.idMoneda &&
      prestamo.idMoneda == idGuarani &&
      parseInt(prestamo.monto) > arrDatosPrestamo[prestamo.idTipoPrestamo].monto_max
    ) {
      setErrores((e) => ({
        ...e,
        monto: `Para solicitar montos mayores a Gs. ${arrDatosPrestamo[
          prestamo.idTipoPrestamo
        ].monto_max.toLocaleString()} puede comunicarse al 021 218 7000.`,
      }));
      calcular = false;
    }

    if (
      prestamo.idMoneda &&
      prestamo.idMoneda != idGuarani &&
      parseInt(prestamo.monto) * valorDolar < arrDatosPrestamo[prestamo.idTipoPrestamo].monto_min
    ) {
      setErrores((e) => ({
        ...e,
        monto: `El monto mínimo a solicitar es el equivalente a Gs. ${arrDatosPrestamo[
          prestamo.idTipoPrestamo
        ].monto_min.toLocaleString()}.`,
      }));
      calcular = false;
    }

    if (
      prestamo.idMoneda &&
      prestamo.idMoneda != idGuarani &&
      parseInt(prestamo.monto) * valorDolar > arrDatosPrestamo[prestamo.idTipoPrestamo].monto_max
    ) {
      setErrores((e) => ({
        ...e,
        monto: `Para solicitar montos mayores al equivalente a Gs. ${arrDatosPrestamo[
          prestamo.idTipoPrestamo
        ].monto_max.toLocaleString()} puede comunicarse al 021 218 7000.`,
      }));
      calcular = false;
    }

    if (prestamo.plazo == '') {
      setErrores((e) => ({ ...e, plazo: 'Seleccione un plazo.' }));
      calcular = false;
    }

    if (calcular) getDatosPrestamo();
    else setPrestamo({ ...prestamo, calculoMonto: '', calculoPlazo: '' });
  };

  const montoCuotaMetodoFrances = (monto, interes, plazo) => {
    // formula = (monto_prestamo * interes) / 1 - (1 + interes) ^ -cantidad_cuotas

    // convertir interes a notacion decimal
    // interes = interes / 100;

    let numerador = monto * interes;
    let denominador = 1 - Math.pow(1 + interes, -1 * plazo);

    return numerador / denominador;
  };

  const calcularInteresTotal = (monto, cuota, interes, plazo) => {
    let aux_capital_pendiente = monto;
    let total_interes = 0;
    for (let i = 0; i < plazo; i++) {
      let aux_interes = interes * aux_capital_pendiente;
      let aux_capital = cuota - aux_interes;
      aux_capital_pendiente -= aux_capital;

      total_interes += aux_interes;
    }

    return total_interes;
  };

  const calcularMontoSolicitado = async (interes) => {
    dispatch(iniLoading());

    let monto = parseInt(prestamo.monto);
    let plazo = parseInt(prestamo.plazo);

    let cuota = await montoCuotaMetodoFrances(monto, interes, plazo);

    let totalInteres = await calcularInteresTotal(monto, cuota, interes, plazo);

    let auxTasaImpuesto = datosCalculo.tasaImpuesto / 100;
    let auxTasaSeguro = datosCalculo.tasaSeguro / 100;

    if (prestamo.idMoneda == idGuarani) totalInteres = Math.round(totalInteres);

    // Primero se le suman los extras al monto inicial (gastos administrativos, impuesto sobre interes y seguro)
    // let montoIni = Math.round(monto + 220000 + 0.1 * Math.round(totalInteres) + 0.0032 * monto);
    let montoIni =
      monto +
      datosCalculo.extraGastos +
      datosCalculo.gastosAdministrativos +
      auxTasaImpuesto * totalInteres +
      auxTasaSeguro * monto;

    if (prestamo.idMoneda == idGuarani) montoIni = Math.round(montoIni);

    let montoSolicitado = 0;
    let exit_flag = false;
    let incremento = prestamo.idMoneda == idGuarani ? 1 : 0.01;

    do {
      montoIni += incremento;

      let cuota = await montoCuotaMetodoFrances(montoIni, interes, plazo);

      let totalInteres = await calcularInteresTotal(montoIni, cuota, interes, plazo);

      if (prestamo.idMoneda == idGuarani) totalInteres = Math.round(totalInteres);

      montoSolicitado =
        (monto +
          datosCalculo.extraGastos +
          datosCalculo.gastosAdministrativos +
          auxTasaImpuesto * totalInteres) /
        (1 - auxTasaSeguro);

      if (prestamo.idMoneda == idGuarani) montoSolicitado = Math.round(montoSolicitado);

      if (montoIni == montoSolicitado) exit_flag = true;
    } while (!exit_flag && montoSolicitado > montoIni);

    dispatch(finLoading());
    return montoSolicitado;
  };

  const getDatosPrestamo = async () => {
    for (let t of arrDatosPrestamo[prestamo.idTipoPrestamo].tasas[prestamo.idMoneda]) {
      if (parseFloat(prestamo.monto) <= parseFloat(t.monto_max)) {
        let datos = {
          interes: parseFloat(t.tasa) / 12 / 100, // obs.: Dividir el interes entre 12 meses porque es interes anual y las cuotas son mensuales!!!!! y entre 100 para que sea en decimal
          tasaImpuesto: parseFloat(t.tasa_impuesto),
          tasaSeguro: parseFloat(t.tasa_seguro),
        };

        if (prestamo.idMoneda == idGuarani) {
          datos.gastosAdministrativos = parseFloat(t.monto_gastos_adm);
        } else {
          // está en guaranies o dolares?
          datos.gastosAdministrativos =
            t.monto_gastos_adm > 25000 // si el gasto es mayor a 25000 se asume que esta en guaranies, sino en dolares
              ? parseFloat(t.monto_gastos_adm / valorDolar)
              : parseFloat(t.monto_gastos_adm);
        }
        datos.extraGastos = parseFloat(
          (t.porcentaje_gastos_administrativos / 100) * prestamo.monto
        );
        setDatosCalculo(datos);

        break;
      }
    }
  };

  const calcularPrestamo = async () => {
    let montoCalculado = await calcularMontoSolicitado(datosCalculo.interes);

    let cuota = await montoCuotaMetodoFrances(montoCalculado, datosCalculo.interes, prestamo.plazo);

    if (prestamo.idMoneda == idGuarani) cuota = formatMoney(Math.round(cuota), 0, ',', '.');
    else cuota = formatMoney(cuota, 2, ',', '.');
    setPrestamo({
      ...prestamo,
      calculoMonto: cuota,
      calculoPlazo: prestamo.plazo,
      calculoMoneda: codigosMoneda[prestamo.idMoneda] ? codigosMoneda[prestamo.idMoneda] : '',
    });
  };

  useEffect(() => {
    if (datosCalculo.interes > 0) {
      calcularPrestamo();
    }
  }, [datosCalculo]);

  const getCotizacion = async () => {
    let fecha = new Date();
    fecha = fecha.toISOString().substring(0, 10);
    dispatch(iniLoading());
    try {
      let response = await axios.get(
        `${backend}/api/cotizaciones?fecha_ini=${fecha}&fecha_fin=${fecha}`
      );
      let res = response.data;
      if (Array.isArray(res)) {
        for (let c of res) {
          if (c.id_moneda && c.moneda.codigo == 'USD') {
            if (c.venta == null) toast.error('No se pudo obtener la cotización actual del dólar.');
            else setValorDolar(parseFloat(c.venta));
          }
        }
      } else {
        toast.error('Error al obtener las cotizaciones.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener las cotizaciones.');
    }
    dispatch(finLoading());
  };

  const getMensajeCalculadora = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(
        `${backend}/api/configuraciones?key=MENSAJE_CALCULADORA_PRESTAMOS`
      );
      let res = response.data;
      if (res.ok) {
        setMensajeCalculadora(res.value);
      } else {
        toast.error('Error al obtener mensaje de calculadora.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener mensaje de calculadora.');
    }
    dispatch(finLoading());
  };

  const getTiposPrestamo = async () => {
    dispatch(iniLoading());
    try {
      let response = await axios.get(`${backend}/api/prestamos`);
      let res = response.data;
      if (Array.isArray(res)) {
        setTiposPrestamo(res);

        let uniqueCodigoMonedas = [];
        let distinctCodigoMonedas = [];

        let setGuarani = false; // buscar guarani para saber cuando redondear
        // crear array de datos de los distintos prestamos para que sea dinamico
        let arr = [];
        for (let p of res) {
          let uniqueMonedas = [];
          let distinctMonedas = [];

          let arrTasas = [];

          for (let t of p.tasas) {
            if (!uniqueCodigoMonedas[t.id_moneda]) {
              // agregar moneda
              distinctCodigoMonedas[t.moneda.id] = t.moneda.codigo;
              uniqueCodigoMonedas[t.id_moneda] = 1;
            }

            if (!uniqueMonedas[t.id_moneda]) {
              // agregar moneda
              distinctMonedas.push({ id: t.moneda.id, codigo: t.moneda.codigo });
              uniqueMonedas[t.id_moneda] = 1;

              // agregar tasa para esa moneda
              arrTasas[t.id_moneda] = [];
            }
            // agregar tasa
            arrTasas[t.id_moneda].push({
              monto_max: t.monto_max,
              tasa: t.tasa,
              tasa_impuesto: t.tasa_impuesto_interes,
              tasa_seguro: t.tasa_seguro_prestamo,
              monto_gastos_adm: t.monto_gastos_administrativos,
              porcentaje_gastos_administrativos: t.porcentaje_gastos_administrativos,
            });

            if (!setGuarani && t.moneda.codigo == 'GS') {
              setIdGuarani(t.id_moneda);
              setGuarani = true;
            }
          }

          arr[p.id] = {
            monedas: distinctMonedas,
            tasas: arrTasas,
            monto_min: p.monto_min,
            monto_max: p.monto_max,
          };
        }

        setCodigosMoneda(distinctCodigoMonedas); // guardar codigos de las distintas monedas

        if (tipoPrestamo)
          setMonedas(arr[parseInt(tipoPrestamo)] ? arr[parseInt(tipoPrestamo)].monedas : []);

        setArrDatosPrestamo(arr);
      } else {
        toast.error('Error al obtener los tipos de prestamo.');
      }
    } catch (error) {
      console.error(error);
      toast.error('Error al obtener los tipos de prestamo.');
    }
    dispatch(finLoading());
  };

  useEffect(() => {
    if (!init) {
      getCotizacion();
      getTiposPrestamo();
      getMensajeCalculadora();
      setInit(true);
    }
  }, [prestamo]);

  return (
    <section className="calculadora">
      <div className="calculadora__info">
        <h2 className="mb-2">Calculadora básica de préstamos</h2>
        <div className="mb-3 w-100">
          <div className="form-group">
            <label>Tipo de préstamo</label>
            <div className="tipo-prestamo-select">
              {tiposPrestamo.map((p) => (
                <button
                  key={p.id}
                  type="button"
                  className={`btn btn-outline-secondary ${
                    prestamo.idTipoPrestamo == p.id ? 'active' : ''
                  }`}
                  onClick={(e) => {
                    setMonedas(arrDatosPrestamo[p.id] ? arrDatosPrestamo[p.id].monedas : []);
                    updatePrestamo('idTipoPrestamo', p.id);
                  }}
                >
                  {p.nombre}
                </button>
              ))}
            </div>
            <span className="text-danger">{errores.idTipoPrestamo}</span>
          </div>
        </div>
        <div className="mb-3 w-100">
          <div className="form-group">
            <label>Monto</label>
            <div className="combo-form">
              <NumericFormat
                value={prestamo.monto || ''}
                onValueChange={(v, s) => updatePrestamo('monto', v.value)}
                valueIsNumericString={true}
                thousandSeparator="."
                decimalSeparator=","
                allowNegative={false}
                decimalScale={0}
                className={`form-control form-control-100 w-60 ${
                  errores.monto ? 'is-invalid' : ''
                }`}
                placeholder="Ingresá un monto"
              />
              <select
                value={prestamo.idMoneda}
                onChange={(e) => {
                  updatePrestamo('idMoneda', e.target.value);
                }}
                className={`form-control w-40 ${errores.idMoneda ? 'is-invalid' : ''}`}
              >
                <option value="">Seleccione...</option>
                {monedas.map((m) => (
                  <option key={m.id} value={m.id}>
                    {m.codigo}
                  </option>
                ))}
              </select>
            </div>
            <span className="text-danger">{errores.monto}</span>
            <span className="text-danger">{errores.idMoneda}</span>
          </div>
        </div>
        <div className="mb-3 w-100">
          <div className="form-group">
            <label>Plazo en meses</label>
            <select
              value={prestamo.plazo}
              onChange={(e) => updatePrestamo('plazo', e.target.value)}
              className={`form-control form-control-100 ${errores.plazo ? 'is-invalid' : ''}`}
            >
              <option value="">Seleccione...</option>
              <option value="6">6</option>
              <option value="12">12</option>
              <option value="24">24</option>
              <option value="36">36</option>
              <option value="48">48</option>
            </select>
            <span className="text-danger">{errores.plazo}</span>
          </div>
        </div>
        {/* <a className="btn btn-primary" onClick={(e) => validarPrestamo()}>
          Calcular
        </a> */}
        <button type="button" onClick={(e) => validarPrestamo()} className="btn btn-primary">
          Calcular
        </button>
        <p className="mt-3">{`${mensajeCalculadora}`}</p>
      </div>
      <div className={`calculadora__result ${prestamo.calculoMonto ? 'show' : ''}`}>
        <h4 className="font-weight-regular">Resultado</h4>
        <div className="mb-3">
          <h1>{`${prestamo.calculoMonto} ${prestamo.calculoMoneda}.`}</h1>
          <p>Cuota a pagar</p>
        </div>
        <div className="mb-3">
          <h2>{prestamo.calculoPlazo}</h2>
          <p>meses</p>
        </div>
        <a
          href={`/site/producto?idProducto=1&idMonedaP=${prestamo.idMoneda}&montoP=${prestamo.monto}`}
          className="btn btn-light"
        >
          Solicitar préstamo
        </a>
      </div>
    </section>
  );
}

export default CalculadoraPrestamos;
