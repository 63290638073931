import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * SucursalAccion Component
 */
function SucursalAccion({ sucursal }) {
  return (
    <div>
      <Link
        to={`/admin/sucursales/${sucursal.id}`}
        className="btn btn-sm btn-outline-secondary"
        title="Editar"
      >
        <i className="fas fa-fw fa-pencil-alt"></i>
      </Link>
    </div>
  );
}

// Typechecking props of the component
SucursalAccion.propTypes = {
  sucursal: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default SucursalAccion;
