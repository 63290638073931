import { useSelector } from 'react-redux';
import { BarChart } from 'chartist';
import { useEffect } from 'react';
import AdminDashResumenPaginas from './AdminDashResumenPaginas';
import AdminDashVisitasPaginas from './AdminDashVisitasPaginas';

/**
 * AdminDashboard Component
 */
function AdminDashboard() {
  const authData = useSelector((state) => state.auth.value);

  // const graficar = () => {
  //   new BarChart('#chart', {
  //     labels: ['W1', 'W2', 'W3', 'W4', 'W5', 'W6', 'W7', 'W8', 'W9', 'W10'],
  //     series: [
  //       [1, 2, 4, 1, 8, -2, -1, -4, -6, -2]
  //     ]
  //   }, {
  //     high: 10,
  //     low: -10,
  //     axisX: {
  //       labelInterpolationFnc: (value, index) => (index % 2 === 0 ? value : null)
  //     }
  //   });
  // }

  return (
    authData &&
    (authData.user.rol == 'admin' /*|| authData.user.rol == 'comercial'*/) && (
      <div className="container mt-5">

        <AdminDashResumenPaginas />

        <AdminDashVisitasPaginas />

      </div>
    )
  );
}

export default AdminDashboard;
