import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './counterSlice';
import envReducer from './envSlice';
import loadingReducer from './loadingSlice';
import authReducer from './authSlice';
import titleReducer from './titleSlice';

/**
 * Store global app data with redux
 */
export default configureStore({
  reducer: {
    counter: counterReducer,
    env: envReducer,
    loading: loadingReducer,
    auth: authReducer,
    title: titleReducer,
  },
});
